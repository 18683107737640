import { Card, Stack, Typography, Box, CircularProgress } from '@mui/material';
import { ImageOperation, Query } from '../../../contexts/MediaContext';
import { useContext } from 'react';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { Color } from '../../../Color';

interface Props {
    imageOperations: ImageOperation<Query>[];
}

export default function Adding({ imageOperations }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    if (imageOperations.length === 0) {
        return null;
    } else {
        return (
            <Card
                elevation={0}
                sx={{
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                }}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    // alignItems="flex-start"
                    // justifyContent="flex-start"
                >
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            // alignContent: 'center',
                            // justifyItems: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <i className="fa-solid fa-check fa-2xl" style={{ marginRight: '16px', color: '#B0DB48' }}></i>
                        <Typography
                            sx={{
                                fontFamily: 'Nocturne Serif',
                                fontSize: '24px',
                                fontWeight: 400,
                                lineHeight: '26px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                            }}
                        >
                            Adding
                        </Typography>
                    </Stack>
                    {imageOperations.map(imageOperation => {
                        const imgSrc = imageOperation.cropped?.preview ?? imageOperation.original?.preview;
                        const isUploaded = imageOperation.operation.Completed; //  === false;

                        return (
                            <Stack
                                key={imageOperation.id}
                                direction="row"
                                spacing={0}
                                sx={{
                                    borderRadius: 2,
                                    backgroundColor:
                                        darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        flexBasis: 140,
                                        width: 140,
                                        height: 140,
                                        borderTopLeftRadius: { xs: '8px', sm: '8px' },
                                        borderTopRightRadius: { xs: '8px', sm: 0 },
                                        borderBottomLeftRadius: { xs: 0, sm: '8px' },
                                        borderBottomRightRadius: { xs: 0, sm: 0 },
                                        // objectFit: 'cover',
                                        objectFit: 'contain',
                                        overflow: 'hidden',
                                    }}
                                    src={imgSrc}
                                    alt=""
                                />
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{
                                        flexGrow: 1,
                                        alignItems: 'center',
                                        p: 2,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nocturne Serif',
                                            fontSize: '24px',
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                            letterSpacing: '-0.03em',
                                            textAlign: 'left',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {imageOperation.operation.status}
                                    </Typography>
                                    <Box sx={{ flexGrow: 1 }} />
                                    {isUploaded ?
                                        <i
                                            className="fa-solid fa-check fa-2xl"
                                            style={{ marginRight: '16px', color: '#B0DB48' }}
                                        ></i>
                                    :   <CircularProgress />}
                                    {/* <IconButton
                                        onClick={ () => {
                                            // removeImageOperation(pendingIrcode);
                                        } }
                                    >
                                        <i className="fa-regular fa-xmark fa-xs"></i>
                                    </IconButton> */}
                                </Stack>
                            </Stack>
                        );
                    })}
                </Stack>
            </Card>
        );
    }
}
