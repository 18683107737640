import { Chip as MuiChip, Avatar } from '@mui/material';
import { ReactElement, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../Color';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';

export enum ChipType {
    Default,
    Warning,
    ActivityUser,
    NotificationImage,
    New,
}

// TODO: Color.ts
// Returns a combination of background color and text color for the given type
const colorForType = (type: ChipType, darkMode: boolean): [string, string] => {
    switch (type) {
        case ChipType.Default:
            return [
                darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                darkMode ? Color.White : Color.Black,
            ];
        case ChipType.Warning:
            return ['#D60067', '#FFF'];
        case ChipType.ActivityUser:
            return [
                darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                darkMode ? Color.White : Color.Black,
            ];
        case ChipType.NotificationImage:
            return [
                darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                darkMode ? Color.White : Color.Black,
            ];
        case ChipType.New:
            return [Color.WebNotificationGreen, darkMode ? Color.White : Color.Black];
    }
};

interface Props {
    label: string;
    type?: ChipType;
    avatarUrl?: string;
    icon?: ReactElement;
    url?: string;
}

export const NotificationChip = ({ label, type = ChipType.Default, avatarUrl, icon, url }: Props) => {
    const navigate = useNavigate();
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const [backgroundColor, color] = colorForType(type, darkMode);

    const avatar =
        type !== ChipType.New && avatarUrl ?
            <Avatar
                src={avatarUrl}
                sx={{
                    ...(type === ChipType.ActivityUser ?
                        {
                            borderRadius: '50%',
                            height: '20px',
                            width: '20px',
                            verticalAlign: 'middle',
                        }
                    :   {}),
                    ...(type === ChipType.NotificationImage ?
                        {
                            borderRadius: '4px',
                            objectFit: 'cover',
                            marginRight: 4,
                        }
                    :   {}),
                }}
            />
        : avatarUrl === '' && type !== ChipType.New ? undefined
        : <Avatar />;

    return (
        <MuiChip
            avatar={avatar}
            label={label}
            sx={{
                borderRadius: '4px',
                backgroundColor,
                height: '28px',
                color,
                fontFamily: 'Nunito Sans',
                fontSize: '12px',
                lineHeight: '16px',
                letterSpacing: '1.8px',
                textTransform: 'uppercase',
                ...(type === ChipType.ActivityUser ?
                    {
                        p: '4px',
                        m: '4px',
                        '& .MuiChip-label': {
                            display: 'block',
                        },
                    }
                :   {}),
                ...(type === ChipType.NotificationImage ?
                    {
                        '& .MuiChip-avatar': {
                            marginLeft: 0,
                            height: '28px',
                            width: '28px',
                        },
                    }
                :   {}),
            }}
            onClick={() => {
                if (url) {
                    navigate(url);
                }
            }}
        />
    );
};
