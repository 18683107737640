import { useEffect, useRef, useState } from 'react';
import useWebcamStream from './useWebcamStream';
import useCameraPermissions from './useCameraPermissions';

const SUPPORTS_MEDIA_DEVICES =
    'mediaDevices' in navigator &&
    typeof navigator.mediaDevices?.enumerateDevices === 'function' &&
    'ImageCapture' in window;

export interface CameraZoomOptions {
    minZoom?: number;
    maxZoom?: number;
}

const useCameraZoom = ({ minZoom, maxZoom }: CameraZoomOptions = {}) => {
    const zoomLevel = useRef<number>(0);
    const [supported, setSupported] = useState<boolean>(false);
    const { stream } = useWebcamStream();
    const { hasPermission } = useCameraPermissions();

    const setZoom = (zoom: number) => {
        if (!supported) return;
        const track = stream?.getVideoTracks()[0];
        if (!track) return;
        if (minZoom === undefined || minZoom < 0) {
            minZoom = 0;
        }
        if (maxZoom !== undefined) {
            zoom = Math.min(maxZoom, zoom);
        }
        zoom = Math.max(minZoom, zoom);
        zoomLevel.current = zoom;
        track.applyConstraints({
            zoom: { ideal: zoom },
        });
    };

    useEffect(() => {
        if (!SUPPORTS_MEDIA_DEVICES || !hasPermission || !stream) return;
        try {
            const track = stream.getVideoTracks()[0];
            if (!track || !track.enabled || track.readyState !== 'live' || track.muted) return;
            // Only way that I could find that seems like it's telling if zoom is supported - tested on my pc and phone
            const supported = track.getSettings().zoom !== undefined;
            setSupported(supported);
        } catch (error) {
            console.error(error);
        }
    }, [hasPermission, stream]);

    return {
        isZoomSupported: supported,
        // typed as immutable ref object
        zoomLevel: zoomLevel as Readonly<{ current: number }>,
        setZoom,
    };
};

export default useCameraZoom;
