import { Card, Stack, Typography, Box, IconButton, Button, Checkbox, Dialog } from '@mui/material';
import { ImageOperation, Query } from '../../../contexts/MediaContext';
import { Select } from '../../general/Select';
import { useContext, useMemo, useState } from 'react';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { Color } from '../../../Color';
import { MetaContent, MetaType, CardType, TCardType } from '../../../types/MetaTypes';
import { TitleForm } from '../pages/MetaData/Title';
import { ArtistNameForm } from '../pages/MetaData/ArtistName';
import { LinkForm } from '../pages/MetaData/Link';
import { MetaField, indexForMetaType, metaFieldForMetaType, newMetaField } from '../../../contexts/MetaContext';
import { CardTypeForm } from '../pages/MetaData/CardType';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import Cropper from 'src/components/image/CropperExtended';

interface Props {
    imageOperations: ImageOperation<Query>[];
    replaceImageOperation: (imageOperation: ImageOperation<any>) => void;
    removeImageOperation: (imageOperation: ImageOperation<any>) => void;
    registerSelectedImages: () => void;
    registerDisabled?: boolean;
}

export default function Available({
    imageOperations,
    replaceImageOperation,
    removeImageOperation,
    registerSelectedImages,
    registerDisabled,
}: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { confirm } = useContext(FeedbackContext) as TFeedback;

    const [cropperImageOperation, setCropperImageOperation] = useState<ImageOperation<any> | null>(null);

    const copyOnConfirm = async (metaType: MetaType, metaContent: MetaContent) => {
        if (
            await confirm({ title: 'Confirm Copy', message: 'Copy this field to other uploads?', yes: 'Yes', no: 'No' })
        ) {
            for (let i = 0; i < imageOperations.length; i++) {
                const newAvailableIrcode = structuredClone(imageOperations[i]);
                const index = indexForMetaType(newAvailableIrcode.bulkOperation!.meta, metaType);
                if (index !== -1) {
                    newAvailableIrcode.bulkOperation!.meta![index].metaContent = metaContent;
                } else {
                    newAvailableIrcode.bulkOperation!.meta!.push(newMetaField(metaType, metaContent));
                }
                replaceImageOperation(newAvailableIrcode);
            }
        }
    };

    const hasSelectedImages = useMemo(
        () => imageOperations.some(imageOperation => !!imageOperation.bulkOperation?.selected),
        [imageOperations],
    );

    const isRegisterDisabled = registerDisabled || !hasSelectedImages;

    const replace = (srcImageOperation: ImageOperation<any>) => {
        for (let i = 0; i < imageOperations.length; i++) {
            const newAvailableIrcode = structuredClone(imageOperations[i]);
            const oldMetaArray = structuredClone(newAvailableIrcode.bulkOperation!.meta);
            newAvailableIrcode.bulkOperation!.meta = structuredClone(srcImageOperation.bulkOperation!.meta).map(
                (metaField: MetaField) => {
                    const originalMetaField = oldMetaArray.find((mF: MetaField) => mF.metaType === metaField.metaType);
                    if (originalMetaField) {
                        return {
                            ...metaField,
                            metaID: originalMetaField.metaID,
                        };
                    } else {
                        // If new
                        return {
                            ...metaField,
                            metaID: 0,
                        };
                    }
                },
            );

            replaceImageOperation(newAvailableIrcode);
        }
    };

    if (imageOperations.length === 0) {
        return null;
    } else {
        return (
            <Card
                elevation={0}
                sx={{
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                }}
            >
                <Stack direction="column" spacing={2}>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <i className="fa-solid fa-check fa-2xl" style={{ marginRight: '16px', color: '#B0DB48' }}></i>
                        <Typography
                            sx={{
                                fontFamily: 'Nocturne Serif',
                                fontSize: '24px',
                                fontWeight: 400,
                                lineHeight: '26px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                            }}
                        >
                            Available for registration
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28px',
                                letterSpacing: '0.01em',
                                textAlign: 'left',
                            }}
                        >
                            Enter a title to register your IRCODE
                        </Typography>
                    </Stack>
                    {imageOperations.map(imageOperation => {
                        const imgSrc = imageOperation.cropped?.preview ?? imageOperation.original?.preview;
                        const meta = imageOperation.bulkOperation?.meta ?? [];

                        const onChange = (metaType: MetaType, metaContent: MetaContent) => {
                            const newAvailableIrcode = structuredClone(imageOperation);
                            const index = indexForMetaType(meta, metaType);
                            if (index !== -1) {
                                newAvailableIrcode.bulkOperation!.meta![index].metaContent = metaContent;
                            } else {
                                newAvailableIrcode.bulkOperation!.meta!.push(newMetaField(metaType, metaContent));
                            }
                            replaceImageOperation(newAvailableIrcode);
                        };

                        const cardType =
                            (metaFieldForMetaType(meta, MetaType.CardType)?.metaContent as TCardType | undefined)
                                ?.cardType ?? CardType.ArtCard;

                        return (
                            <Stack
                                key={imageOperation.id}
                                direction="row"
                                spacing={2}
                                sx={{
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    checked={imageOperation.bulkOperation?.selected ?? true}
                                    onChange={event => {
                                        const newAvailableIrcode = structuredClone(imageOperation);
                                        newAvailableIrcode.bulkOperation!.selected = event.target.checked;
                                        replaceImageOperation(newAvailableIrcode);
                                    }}
                                />
                                <Stack
                                    direction="row"
                                    spacing={0}
                                    sx={{
                                        flexGrow: 1,
                                        alignItems: 'center',
                                        borderRadius: 2,
                                        backgroundColor:
                                            darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                    }}
                                >
                                    <Stack direction="column" spacing={1}>
                                        <Box
                                            component="img"
                                            src={imgSrc}
                                            alt=""
                                            sx={{
                                                flexBasis: 140,
                                                width: 140,
                                                height: 140,
                                                borderTopLeftRadius: { xs: '8px', sm: '8px' },
                                                borderTopRightRadius: { xs: '8px', sm: 0 },
                                                borderBottomLeftRadius: { xs: 0, sm: '8px' },
                                                borderBottomRightRadius: { xs: 0, sm: 0 },
                                                // objectFit: 'cover',
                                                objectFit: 'contain',
                                                overflow: 'hidden',
                                            }}
                                            loading="lazy"
                                        />
                                        <Button
                                            sx={{
                                                color: darkMode ? Color.White : Color.Black,
                                                opacity: 0.8,
                                                justifyContent: 'flex-start',
                                            }}
                                            onClick={async () => {
                                                setCropperImageOperation(imageOperation);
                                            }}
                                        >
                                            <i
                                                className="fa-regular fa-crop"
                                                style={{
                                                    marginRight: '0.5em',
                                                    marginLeft: '0.4em',
                                                }}
                                            ></i>{' '}
                                            Crop
                                        </Button>
                                        <Button
                                            sx={{
                                                color: darkMode ? Color.White : Color.Black,
                                                opacity: 0.8,
                                                justifyContent: 'flex-start',
                                            }}
                                            onClick={async () => {
                                                if (
                                                    await confirm({
                                                        title: 'Confirm Replace',
                                                        message: 'Replace all metadata across other uploads?',
                                                    })
                                                ) {
                                                    replace(imageOperation);
                                                }
                                            }}
                                        >
                                            <i
                                                className="fa-regular fa-copy"
                                                style={{
                                                    marginRight: '0.5em',
                                                    marginLeft: '0.4em',
                                                }}
                                            ></i>{' '}
                                            Copy to all
                                        </Button>
                                    </Stack>

                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            flexGrow: 1,
                                            alignItems: 'center',
                                            p: 2,
                                        }}
                                    >
                                        <Stack
                                            direction="column"
                                            spacing={1}
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <CardTypeForm
                                                metaField={metaFieldForMetaType(meta, MetaType.CardType)}
                                                onCopy={async metaContent => {
                                                    copyOnConfirm(MetaType.CardType, metaContent);
                                                }}
                                                onChange={(metaContent: MetaContent) => {
                                                    onChange(MetaType.CardType, metaContent);
                                                }}
                                            />
                                            <TitleForm
                                                metaField={metaFieldForMetaType(meta, MetaType.Title)}
                                                onCopy={async metaContent => {
                                                    copyOnConfirm(MetaType.Title, metaContent);
                                                }}
                                                onChange={(metaContent: MetaContent) => {
                                                    onChange(MetaType.Title, metaContent);
                                                }}
                                            />
                                            {cardType === CardType.ArtCard && (
                                                <ArtistNameForm
                                                    metaField={metaFieldForMetaType(meta, MetaType.ArtistName)}
                                                    onCopy={async metaContent => {
                                                        copyOnConfirm(MetaType.ArtistName, metaContent);
                                                    }}
                                                    onChange={(metaContent: MetaContent) => {
                                                        onChange(MetaType.ArtistName, metaContent);
                                                    }}
                                                />
                                            )}
                                            <LinkForm
                                                metaField={metaFieldForMetaType(meta, MetaType.Link)}
                                                onChange={(metaContent: MetaContent) => {
                                                    onChange(MetaType.Link, metaContent);
                                                }}
                                            />
                                        </Stack>
                                        <Select
                                            options={[
                                                { value: 'publish', label: 'Publish' },
                                                { value: 'draft', label: 'Draft' },
                                            ]}
                                            value={imageOperation.bulkOperation!.status}
                                            onChange={value => {
                                                const newAvailableIrcode = structuredClone(imageOperation);
                                                newAvailableIrcode.bulkOperation!.status = value as 'publish' | 'draft';
                                                replaceImageOperation(newAvailableIrcode);
                                            }}
                                            sx={{
                                                width: 150,
                                            }}
                                        />

                                        <IconButton
                                            onClick={() => {
                                                removeImageOperation(imageOperation);
                                            }}
                                        >
                                            <i className="fa-regular fa-trash fa-xs"></i>
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Stack>
                        );
                    })}
                    <Button
                        sx={{
                            opacity: isRegisterDisabled ? 0.5 : 1,
                        }}
                        disabled={isRegisterDisabled}
                        variant="irdbGradient"
                        onClick={() => {
                            registerSelectedImages();
                        }}
                    >
                        Register selected images
                    </Button>
                </Stack>
                <Dialog
                    open={!!cropperImageOperation}
                    sx={{
                        '& .MuiDialog-paper': {
                            minWidth: '80vw',
                        },
                    }}
                >
                    <Cropper
                        imageOperation={cropperImageOperation}
                        onProgress={(progress: ImageOperation<any>) => {}}
                        onSuccess={async i => {
                            replaceImageOperation(i);
                            setCropperImageOperation(null);
                        }}
                        onRetake={() => {
                            setCropperImageOperation(null);
                        }}
                        onCancel={() => {
                            setCropperImageOperation(null);
                        }}
                    />
                </Dialog>
            </Card>
        );
    }
}
