import { FC, createContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useMediaQuery, useTheme } from '@mui/material';
import { User } from './UserContext';

enum LocalStorageKey {
    AutoFillMode = 'AutoFillMode',
    CaptureMode = 'CaptureMode',
    TestMode = 'TestMode',
}

interface AutoFill {
    signIn?: { email: string; password: string };
    signUp?: { fullName: string; userName: string; email: string; password: string; confirmPassword: string };
    transferIrcode?: {
        recipient?: string;
        recipientConfirm?: string;
        password?: string;
    };
}

export interface TEnv {
    isDev: boolean;

    testMode: string | undefined;
    setTestMode: (testMode: string | undefined) => void;

    autoFillMode: boolean;
    setAutoFillMode: (autoFillMode: boolean) => void;
    autoFill?: AutoFill;

    impersonated?: User;
    impersonate: (user: User | undefined) => void;

    showWebApp?: boolean;
    setShowWebApp: (show: boolean) => void;
    showAdmin?: boolean;
    setShowAdmin: (show: boolean) => void;
    showDashboard?: boolean;
    setShowDashboard: (show: boolean) => void;
}

interface Props {
    children: React.ReactNode;
}

export const EnvProvider: FC<Props> = ({ children }) => {
    const [testMode, setTestMode] = useLocalStorage<string | undefined>(LocalStorageKey.TestMode, undefined);
    const [autoFillMode, setAutoFillMode] = useLocalStorage(LocalStorageKey.AutoFillMode, false);
    const [autoFill, setAutoFill] = useState<AutoFill>();

    const [impersonated, impersonate] = useState<User>();

    const [showWebApp, setShowWebApp] = useState(true);
    const [showAdmin, setShowAdmin] = useState(true);
    const [showDashboard, setShowDashboard] = useState(true);

    useEffect(() => {
        if (autoFillMode) {
            const random = Math.floor(Math.random() * 100000);
            setAutoFill({
                signIn: {
                    email: 'rj@irdb.com',
                    password: 'thx1138',
                },
                signUp: {
                    fullName: `Rob Johnson`,
                    userName: `rob${random}`,
                    email: `rj+${random}@gmail.com`,
                    password: 'thx1138',
                    confirmPassword: 'thx1138',
                },
                transferIrcode: {
                    recipient: 'rob@unled.com',
                    recipientConfirm: 'rob@unled.com',
                    password: '@1ReeseDrive!',
                },
            });
        } else {
            setAutoFill(undefined);
        }
    }, [autoFillMode]);

    // Testing, remove when done
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const matchesSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const matchesMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const matchesLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

    let currentBreakpoint = 'unknown';
    if (matchesXs) currentBreakpoint = 'xs';
    else if (matchesSm) currentBreakpoint = 'sm';
    else if (matchesMd) currentBreakpoint = 'md';
    else if (matchesLg) currentBreakpoint = 'lg';
    else if (matchesXl) currentBreakpoint = 'xl';
    console.log('currentBreakpoint', currentBreakpoint);
    // Testing, remove when done

    return (
        <EnvContext.Provider
            value={{
                isDev: process.env.REACT_APP_ENV === 'development',
                testMode,
                setTestMode,
                autoFillMode,
                setAutoFillMode,
                autoFill,
                impersonated,
                impersonate,
                showWebApp,
                setShowWebApp,
                showAdmin,
                setShowAdmin,
                showDashboard,
                setShowDashboard,
            }}
        >
            {children}
        </EnvContext.Provider>
    );
};

const EnvContext = createContext<TEnv | undefined>(undefined);

export default EnvContext;
