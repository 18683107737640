import { useContext, useEffect, useState } from 'react';
import { Stack, Typography, Dialog, Button } from '@mui/material';
import MediaContext, { TMedia } from '../../../../contexts/MediaContext';
import FeedbackContext, { TFeedback } from '../../../../contexts/FeedbackContext';
import { nullUndefinedOrEmpty } from '../../../../util/string';
import UserContext, { TUser } from '../../../../contexts/UserContext';
import TextField from '../../../general/TextField';
import { useNavigate } from 'react-router-dom';

export interface ITransferIrcode {
    recipient?: string;
    recipientConfirm?: string;
    password?: string;
}

interface TransferIrcodeTopProps {
    imageID: string;
    transferIrcode: ITransferIrcode | undefined;
    setTransferIrcode: (transferIrcode: ITransferIrcode) => void;
    setTabInformation: () => void;
}

export function TransferIrcodeTop({
    imageID,
    transferIrcode,
    setTransferIrcode,
    setTabInformation,
}: TransferIrcodeTopProps) {
    const { user } = useContext(UserContext) as TUser;

    const [showTwoFactorAuthWarning, setShowTwoFactorAuthWarning] = useState(false);
    const [showEnableTwoFactorDialog, setShowEnableTwoFactorDialog] = useState(false);

    const navigate = useNavigate();

    // TODO: Refactor out
    // const [phoneNumber, setPhoneNumber] = useState('');

    // const setAllToIsEditing = (meta: IMetaData, isEditing: boolean): IMetaData => {
    //     const newMeta = structuredClone(meta) as IMetaData;
    //     newMeta.Meta.forEach(m => m.metaIsEditing = isEditing);
    //     return newMeta;
    // }

    useEffect(() => {
        // try {
        //     user!.phone = undefined;
        // } catch (error) {
        // }

        // has a phone number?
        // is email verified?
        // has 2FA?

        if (nullUndefinedOrEmpty(user?.phone)) {
            setShowTwoFactorAuthWarning(true);
        }

        // const image = imageFromImageOperation(imageOperation);
        // if (image?.imageID) {
        //     setImageId(image.imageID);
        //     setMeta(setAllToIsEditing(meta, true));
        //     // TODO: Set all to isEditing
        // }
    }, [navigate]);

    // const showCropper = imageOperation !== undefined
    //     && imageOperation.operation.type === Type.Crop;

    const image = undefined; // imageFromImageOperation(imageOperation);
    // console.log('imageOperation', imageOperation);

    return (
        <>
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    // flexGrow: 1,
                    // flexShrink: 0,
                    p: 3,
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Nocturne Serif',
                        fontSize: '24px',
                        fontWeight: 400,
                        lineHeight: '26px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    Securely transfer your IRCODE
                </Typography>

                <Typography
                    component="div"
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    <p>
                        The email address you transfer to must be associated with an IRCODE account and have two-factor
                        authentication (2FA) set up.
                    </p>
                    <p>
                        Any permanent add-ons will be transferred while subscriptions connected to this IRCODE will be
                        cancelled.
                    </p>
                    <p>The transfer must be accepted within 10 days, or it will be automatically cancelled.</p>
                </Typography>

                <TextField
                    label="Recipient email address"
                    value={transferIrcode?.recipient ?? ''}
                    onChange={value => setTransferIrcode({ ...structuredClone(transferIrcode), recipient: value })}
                />
                <TextField
                    label="Confirm recipient email address"
                    value={transferIrcode?.recipientConfirm ?? ''}
                    onChange={value =>
                        setTransferIrcode({ ...structuredClone(transferIrcode), recipientConfirm: value })
                    }
                />
                <TextField
                    label="Enter your password to verify"
                    value={transferIrcode?.password ?? ''}
                    onChange={value => setTransferIrcode({ ...structuredClone(transferIrcode), password: value })}
                />
            </Stack>

            <Dialog
                // anchor="bottom"
                open={showTwoFactorAuthWarning}
                // open={true}
                // disableSwipeToOpen={true}
                // ModalProps={{
                //     // This allows the changing of the image to be observed
                //     keepMounted: false,
                // }}
                // onClose={function (event: SyntheticEvent<{}, Event>): void {
                //     setImageOperation(undefined);
                // }}
                // onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                // sx={{
                //     '& .MuiDrawer-paper': {
                //         // transition: 'height 500ms',
                //         // height: availableDrawerHeight,
                //         // maxWidth: '100px',
                //     },
                // }}
                // style={{
                //     zIndex: ZIndex.BottomSheet,
                // }}
            >
                <Stack p={4}>
                    <Typography variant="proVerifyHeader" sx={{ marginBottom: 2 }}>
                        Two-factor authentication
                    </Typography>
                    <Typography variant="notificationSettingInfo" sx={{ marginBottom: 2 }}>
                        A phone number is required to transfer this IRCODE. Would you like add one to your profile now?
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="irdbGradient"
                            sx={{ flex: 1 }}
                            onClick={async () => {
                                // onSave();
                                // await saveMeta(image.imageID, meta.Meta);
                                // refresh();

                                setShowTwoFactorAuthWarning(false);
                                // setShowEnableTwoFactorDialog(true);
                                navigate('/dashboard/settings#account');
                            }}
                        >
                            Add Phone
                        </Button>
                        <Button
                            variant="irdbGray"
                            sx={{ flex: 1 }}
                            onClick={async () => {
                                setShowTwoFactorAuthWarning(false);
                                setTabInformation();
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </Dialog>
            <Dialog
                // anchor="bottom"
                open={false}
                // open={true}
                // disableSwipeToOpen={true}
                // ModalProps={{
                //     // This allows the changing of the image to be observed
                //     keepMounted: false,
                // }}
                // onClose={function (event: SyntheticEvent<{}, Event>): void {
                //     setImageOperation(undefined);
                // }}
                // onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                // sx={{
                //     '& .MuiDrawer-paper': {
                //         // transition: 'height 500ms',
                //         // height: availableDrawerHeight,
                //         // maxWidth: '100px',
                //     },
                // }}
                // style={{
                //     zIndex: ZIndex.BottomSheet,
                // }}
            >
                <Stack>
                    Two-factor authentication Enter your phone number to activate two-factor authentication
                    <Button
                        variant="irdbGradient"
                        onClick={async () => {
                            // onSave();
                            // await saveMeta(image.imageID, meta.Meta);
                            // refresh();
                            // setShowTwoFactorAuthWarning(false);
                        }}
                    >
                        Activate
                    </Button>
                    <Button
                        variant="irdbGray"
                        onClick={async () => {
                            // setShowTwoFactorAuthWarning(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Dialog>
        </>
    );
}

interface TransferIrcodeBottomProps {
    imageID: string;
    transferIrcode: ITransferIrcode | undefined;
}

export function TransferIrcodeBottom({ imageID, transferIrcode }: TransferIrcodeBottomProps) {
    const { user, requestTwoFactorAuth } = useContext(UserContext) as TUser;
    const { initiateTransfer } = useContext(MediaContext) as TMedia;
    const { notify, setShowLoading } = useContext(FeedbackContext) as TFeedback;

    const handleInitiateTransfer = async (code: string) => {
        try {
            if (transferIrcode) {
                await initiateTransfer(imageID, transferIrcode.recipient!, transferIrcode.password!, code);
            } else {
                throw new Error('Please fill out all fields');
            }
        } catch (error: any) {
            notify('Error', error.message);
        }
    };

    return (
        <>
            <Button
                variant="irdbGradient"
                sx={{
                    width: 250,
                    height: 56,
                }}
                onClick={async () => {
                    try {
                        if (transferIrcode) {
                            setShowLoading(true);

                            const code = await requestTwoFactorAuth(
                                'Verify to transfer IRCODE',
                                user?.phone ?? '',
                                'Initiate transfer',
                            );
                            await handleInitiateTransfer(code);

                            setShowLoading(false);
                        } else {
                            throw new Error('Please fill out all fields');
                        }
                    } catch (error: any) {
                        notify('Error', error.message);
                    }
                }}
            >
                <i className="fa-regular fa-lock"></i>
                Verify transfer
            </Button>
        </>
    );
}
