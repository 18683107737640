import { useState } from 'react';
import { Button, Dialog, Stack, Typography } from '@mui/material';

export type NotifyParams = (title: string, message: string, ok?: string) => Promise<void>;

const useNotify = (): {
    notify: NotifyParams;
    NotifyDialog: () => JSX.Element;
} => {
    // TODO: Type this
    const [promise, setPromise] = useState<any>();
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [ok, setOk] = useState('OK');

    const notify: NotifyParams = (title: string, message: string, ok: string = 'OK') =>
        new Promise<void>((resolve, reject) => {
            setTitle(title);
            setMessage(message);
            setOk(ok);
            setPromise({ resolve });
        });

    const handleClose = () => {
        setPromise(undefined);
    };

    const handleOk = () => {
        promise?.resolve(true);
        handleClose();
    };

    const NotifyDialog = (): JSX.Element => (
        <Dialog
            open={promise !== undefined}
            sx={{
                zIndex: Number.MAX_SAFE_INTEGER,
                '& .MuiDialog-paper': {
                    minWidth: '30vw',
                    p: 4,
                    borderRadius: 2,
                },
            }}
        >
            <Stack direction="column" spacing={2}>
                <Typography
                    sx={{
                        fontFamily: 'Nocturne Serif',
                        fontSize: '32px',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.03em',
                        textAlign: 'left',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    {message}
                </Typography>
                <Button
                    variant="irdbGradient"
                    sx={{
                        height: 56,
                    }}
                    onClick={handleOk}
                >
                    {ok}
                </Button>
            </Stack>
        </Dialog>
    );

    return { notify, NotifyDialog };
};

export default useNotify;
