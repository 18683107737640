import { Box, Button, IconButton, Stack, SwipeableDrawer } from '@mui/material';
import { useContext, useLayoutEffect, useRef, useState, SyntheticEvent } from 'react';
import MediaContext, { TMedia, ImageOperation, Query, Add, Image } from '../../contexts/MediaContext';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import { Operation } from '../../contexts/Operation';
import Drawer from '../general/Drawer';
import Info from '../image/Info';
import SimilarImages from './SimilarImages';
import { Color } from '../../Color';
import { ZIndex } from '../../App';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';

interface Props {
    imageOperation?: ImageOperation<Query>;
    pushToStack: (component: JSX.Element) => void;
    popFromStack: () => void;
    onLayout: (height: number) => void;
    onCropper: () => void;
    onAdd: (image: ImageOperation<Query>) => void;
    onFaq: () => void;
    onCancel: () => void;
}

export default function Available({
    imageOperation: initialImageOperation,
    pushToStack,
    popFromStack,
    onLayout,
    onCropper,
    onAdd,
    onFaq,
    onCancel,
}: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { notify, setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { upload, add } = useContext(MediaContext) as TMedia;

    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageOperation, setImageOperation] = useState(initialImageOperation);
    const [selectedImage, setSelectedImage] = useState<Image>();
    const [now, setNow] = useState(Date.now());

    const ref = useRef(null);

    const getImageId = (image: ImageOperation<any>) => {
        const imageId = (image.operation as Operation<Add>)?.Results?.Image?.imageID;
        if (imageId) {
            return imageId;
        }
        return undefined;
    };

    // useEffect(() => {
    //     console.log("imageOperation", imageOperation);
    //     // if (imageOperation) {
    //     //     const imageId = getImageId(imageOperation);
    //     //     if (imageId) {
    //     //         setImageId(imageId);
    //     //     }
    //     // }
    // }, [imageOperation]);

    useLayoutEffect(() => {
        if (imageLoaded) {
            setTimeout(() => {
                // This is never correct the first time, so we need to wait a bit
                if (ref.current) {
                    const { height } = (ref.current as HTMLElement).getBoundingClientRect();
                    // console.log("height", height);
                    onLayout(height);
                }
            }, 100);
        }
    }, [imageLoaded]);

    if (!imageOperation) {
        return null;
    }

    const similarImages = imageOperation.operation?.Results?.Images || [];

    return (
        <Stack
            ref={ref}
            sx={{
                height: '100%',

                // ROB: This broke the layout updating
                // overflow: "scroll",
                // overflow: "hidden",
            }}
        >
            <IconButton
                onClick={() => onCancel()}
                sx={{
                    fontSize: '2em',
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 3,
                }}
            >
                <i className="fa-solid fa-circle-xmark" style={{ color: 'white' }} />
            </IconButton>
            <Box
                sx={{
                    backgroundColor: Color.PrimaryDarkGrayBlue,
                    flex: 1,
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                >
                    <img
                        src={imageOperation.cropped?.preview ?? imageOperation.original?.preview}
                        style={{
                            objectFit: 'cover',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            filter: 'blur(.7em) brightness(.8)',
                            zIndex: 1,
                        }}
                        alt=""
                    />
                    <img
                        src={imageOperation.cropped?.preview ?? imageOperation.original?.preview}
                        style={{
                            objectFit: 'contain',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 2,
                        }}
                        onLoad={() => {
                            // console.log("loaded");
                            // URL.revokeObjectURL(image.preview)
                            setImageLoaded(true);
                            setNow(Date.now());
                        }}
                        alt=""
                    />

                    <IconButton
                        sx={{
                            position: 'absolute',
                            left: 8,
                            top: 8,
                            zIndex: 3,
                        }}
                        onClick={() => {
                            onCropper();
                        }}
                    >
                        <i className="fa-solid fa-crop" style={{ color: '#fff' }}></i>
                    </IconButton>
                </div>
                <Stack
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 2,
                        p: 3,
                    }}
                >
                    <Button
                        type="submit"
                        variant="irdbGradient"
                        // autoCapitalize={false}
                        sx={{
                            flexGrow: 1,
                            height: '56px',

                            textTransform: 'none',
                        }}
                        onClick={async () => {
                            try {
                                // onCropper();

                                // await new Promise(resolve => setTimeout(resolve, 2500));

                                onAdd(imageOperation);
                            } catch (error: any) {
                                // setErrorMessage(error.message);
                            }
                        }}
                    >
                        CREATE NEW IRCODE
                    </Button>
                    {/*
                    <Button
                        sx={{
                            backgroundColor: '#E8E8F2',
                            width: '56px',
                            height: '56px',
                            borderRadius: '28px',
                            ml: 2,
                        }}
                        onClick={onFaq}
                    >?</Button>
                    */}
                </Stack>
            </Box>

            {/* Similar Images */}
            <Box
                sx={{
                    backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                    pt: 2,
                    zIndex: 3,
                }}
            >
                <SimilarImages
                    isSimilarImagesLoading={false}
                    similarImages={similarImages}
                    setImage={setSelectedImage}
                />
            </Box>

            {/* Image Info */}
            <SwipeableDrawer
                anchor="bottom"
                open={selectedImage !== undefined}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: false,
                }}
                onClose={function (event: SyntheticEvent<{}, Event>): void {
                    setSelectedImage(undefined);
                }}
                onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                style={{
                    zIndex: ZIndex.ProfileSheet,
                }}
            >
                <Drawer>
                    <Info image={selectedImage} onClose={() => setSelectedImage(undefined)} />
                </Drawer>
            </SwipeableDrawer>
        </Stack>
    );
}
