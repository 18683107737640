import React, { useState, useContext, useEffect } from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    Menu,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material';
import AdminContext, { Ircode, IrcodeSearchResponse, TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import { IrcodeUserSearch } from './IrcodeUserSearch';
import { Color } from '../../Color';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import { SelectChangeEvent } from '@mui/material/Select';
import IrcodeSearch from './IrcodeSearch';

type SortField = keyof Ircode;

export default function MaintainIrcode() {
    const [searchTerms, setSearchTerms] = useState('');
    const [searchResults, setSearchResults] = useState<IrcodeSearchResponse | null>(null);
    const [activeButtons, setActiveButtons] = useState<{ [key: number]: boolean }>({});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [activeMenuItemIndex, setActiveMenuItemIndex] = useState<number | null>(null);
    const [toUser, setToUser] = useState<UserSearchResults | null>(null);
    const [currentOwner, setCurrentOwner] = useState<UserSearchResults | null>(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [ircodeConfirmDialog, setIrcodeConfirmDialog] = useState(false);
    const [selectedIrcode, setSelectedIrcode] = useState<Ircode | null>(null);
    const [ircodeRemovalDialog, setIrcodeRemovalDialog] = useState(false);
    const [bulkRemovalDialog, setBulkRemovalDialog] = useState(false);
    const [removeNow, setRemoveNow] = useState(false);
    const [sortField, setSortField] = useState<SortField>('imageCreated');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
    const [excludeDialog, setExcludeDialog] = useState(false);
    const [searchType, setSearchType] = useState<'registered' | 'scanned'>('registered');
    const { setShowLoading, setErrorMessage } = useContext(FeedbackContext) as TFeedback;
    const [selectedIrcodes, setSelectedIrcodes] = useState<Ircode[]>([]);
    const [selectedAction, setSelectedAction] = useState('');
    const [isLocalLoading, setIsLocalLoading] = useState(false);
    const [twoFACode, setTwoFACode] = useState<string | null>(null);
    const [expiresAt, setExpiresAt] = useState<number | null>(null);
    const [openTransferDialog, setOpenTransferDialog] = useState(false);
    const navigate = useNavigate();

    const { excludeIrcode, fetchIrcode, removeIrcode, requestAdminTwoFactorAuth, transferIrcode } = useContext(
        AdminContext,
    ) as TAdmin;

    useAuth(() => {
        const loadIrcode = async () => {
            try {
                setIsLocalLoading(true);
                const response = await fetchIrcode(searchType, searchTerms);
                if (response?.Results?.Ircodes) {
                    const ircodes = [...response.Results.Ircodes];
                    ircodes.sort((a, b) => a.imageCreated - b.imageCreated);
                    setSearchResults({
                        ...response,
                        Results: {
                            ...response.Results,
                            Ircodes: ircodes,
                        },
                    });
                }
            } finally {
                setIsLocalLoading(false);
            }
        };
        loadIrcode();
    }, [searchTerms, searchType]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (expiresAt && Date.now() > expiresAt) {
                setTwoFACode(null);
                setExpiresAt(null);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [expiresAt]);

    const handleSelectIrcode = (ircode: Ircode, checked: boolean) => {
        setSelectedIrcodes(prev =>
            checked ? [...prev, ircode] : prev.filter(item => item.imageID !== ircode.imageID),
        );
    };

    const handleSet2FACode = (code: string) => {
        setTwoFACode(code);
        const tenMinutesFromNow = Date.now() + 10 * 60 * 1000;
        setExpiresAt(tenMinutesFromNow);
    };

    const handleSubmitAction = async () => {
        if (selectedAction === 'delete') {
            console.log('Open bulk delete dialog');
            setBulkRemovalDialog(true);
        } else if (selectedAction === 'transfer') {
            setOpenTransferDialog(true);
        }
    };

    const handleActionChange = (event: SelectChangeEvent<string>) => {
        setSelectedAction(event.target.value);
    };

    const confirmBulkDelete = async (removeNow: boolean) => {
        try {
            for (let index = 0; index < selectedIrcodes.length; index++) {
                const ircode = selectedIrcodes[index];
                const success = await removeIrcode(ircode.imageID, removeNow);
                if (success) {
                    console.log(`Deletion finished for ${ircode.imageID} (removeNow: ${removeNow})`);
                }
            }
        } catch (error) {
            console.error('Unexpected error during bulk delete:', error);
            setErrorMessage('Failed to delete IRCODES due to an unexpected error.');
        } finally {
            alert('Bulk deletion completed successfully');
            setBulkRemovalDialog(false);
            setSearchTerms('');
            setSelectedIrcodes([]);
            setSelectedAction('');
        }
    };

    const handle2FARequest = async () => {
        if (!toUser) {
            console.error('No target user selected for transfer.');
            setErrorMessage('Please select a user to transfer the IRCODES to.');
            return;
        }

        let currentTwoFACode = twoFACode;

        if (!currentTwoFACode || Date.now() >= expiresAt!) {
            console.log('2FA code missing or expired, requesting a new one...');
            const newCode = await requestAdminTwoFactorAuth('2FA Verification', phoneNumber, 'Enter the 2FA code:');
            if (!newCode) {
                console.error('No 2FA code received, please request a new code.');
                return;
            }
            handleSet2FACode(newCode);
            currentTwoFACode = newCode;
        }
        setShowLoading(true);

        if (selectedIrcodes.length > 0) {
            for (const ircode of selectedIrcodes) {
                const imageUser = parseImageUser(ircode.ImageUser as string);
                const success = await transferIrcode(
                    currentTwoFACode,
                    ircode.imageID,
                    imageUser?.userID || 0,
                    imageUser?.email || '',
                    imageUser?.userName || '',
                    toUser.userID,
                    toUser.email,
                    toUser.userName,
                );
                if (success) {
                    console.log(`Transfer successful for IRCODE ${ircode.imageID}`);
                } else {
                    console.error(`Transfer failed for IRCODE ${ircode.imageID}`);
                }
            }
        } else if (selectedIrcode) {
            const imageUser = parseImageUser(selectedIrcode.ImageUser as string);
            const success = await transferIrcode(
                currentTwoFACode,
                selectedIrcode.imageID,
                imageUser?.userID || 0,
                imageUser?.email || '',
                imageUser?.userName || '',
                toUser.userID,
                toUser.email,
                toUser.userName,
            );
            if (success) {
                console.log('Transfer successful');
                setSearchTerms('');
            } else {
                console.error('Transfer failed with provided 2FA code.');
            }
        } else {
            console.error('No IRCODE selected for transfer.');
            setErrorMessage('Please select at least one IRCODE to transfer.');
        }
        setShowLoading(false);
        setOpenTransferDialog(false);
        setSelectedIrcodes([]);
        setSelectedAction('');
    };

    const handleExcludeDialogOpen = (ircode: Ircode) => {
        setExcludeDialog(true);
    };

    const handleExclude = async (exclude: number) => {
        const success = await excludeIrcode(exclude, currentOwner?.userID || 0, selectedIrcode?.imageID || '');
        const action = exclude === 1 ? 'Exclude' : 'Include';
        if (success) {
            alert(`${action} IRCODE from list was successful`);
            setExcludeDialog(false);
            setActiveMenuItemIndex(null);
        } else {
            alert(`${action} IRCODE from list failed`);
            setExcludeDialog(false);
            setActiveMenuItemIndex(null);
        }
    };

    const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!searchTerms.trim()) {
            setSearchResults(null);
            return;
        }
        setShowLoading(true);
        try {
            const results = await fetchIrcode(searchType, searchTerms);
            setSearchResults(results);
            console.log('Search Results:', results);
        } catch (error) {
            console.error('Search error:', error);
            setErrorMessage('Failed to fetch search results.');
        } finally {
            setShowLoading(false);
        }
    };

    const parseImageUser = (imageUserString: string) => {
        try {
            return JSON.parse(imageUserString);
        } catch (error) {
            console.error('Error parsing ImageUser JSON:', error);
            return null;
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        setAnchorEl(event.currentTarget);
        setActiveMenuItemIndex(index);
        setActiveButtons(prevState => ({
            ...prevState,
            [index]: true,
        }));
    };

    const handleMenuClose = (index: number) => {
        setActiveMenuItemIndex(null);
        setAnchorEl(null);
        setActiveButtons(prevState => ({
            ...Object.keys(prevState).reduce(
                (acc, key) => {
                    const numKey = Number(key);
                    acc[numKey] = false;
                    return acc;
                },
                {} as { [key: number]: boolean },
            ),
            [index]: false,
        }));
    };

    const handleUserSelect = (user: UserSearchResults) => {
        setToUser(user);
        setIrcodeConfirmDialog(true);
        setOpenTransferDialog(false);
    };

    const handleRemoveIrcodeConfirmation = async (removeNow: boolean) => {
        setRemoveNow(removeNow);
        try {
            const success = await removeIrcode(selectedIrcode?.imageID || '', removeNow);
            if (success) {
                console.log('IRCODE removed successfully');
            } else {
                console.error('Failed to remove IRCODE');
            }
        } finally {
            alert('IRCODE removed successfully');
            setSearchTerms('');
            setSearchResults(null);
            setIrcodeRemovalDialog(false);
        }
    };

    const handleSort = (field: SortField) => {
        const isAsc = sortField === field && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortField(field);
    };

    searchResults?.Results.Ircodes.sort((a, b) => {
        let aValue: string | number = '';
        let bValue: string | number = '';

        aValue = (a[sortField] as string | number) ?? '';
        bValue = (b[sortField] as string | number) ?? '';
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        } else {
            return (sortDirection === 'asc' ? 1 : -1) * aValue.toString().localeCompare(bValue.toString());
        }
    });

    const confirmDialogLabels: { label: string; value: string | undefined }[] = [
        { label: 'IRCODE Title:', value: selectedIrcode?.title },
        { label: 'Current Owner UserName:', value: currentOwner?.userName },
        { label: 'Current Owner Email:', value: currentOwner?.email },
        { label: 'Receiving UserName:', value: toUser?.userName },
        { label: 'Receiving Email:', value: toUser?.email },
    ];

    const renderSearchResult = (ircode: Ircode, index: number) => {
        const imageUser = parseImageUser(ircode.ImageUser as string);
        const handleTransferClick = (imageUser: UserSearchResults) => {
            setSelectedIrcode(ircode);
            setCurrentOwner(imageUser);
        };

        return (
            <TableRow key={ircode.imageID}>
                <TableCell align="center">
                    <Stack direction="row">
                        <Checkbox
                            sx={{ display: selectedAction ? 'block' : 'none' }}
                            checked={selectedIrcodes.some(item => item.imageID === ircode.imageID)}
                            onChange={e => handleSelectIrcode(ircode, e.target.checked)}
                        />
                        <img
                            src={ircode.imageUrl}
                            alt={ircode.title || 'Image'}
                            style={{ height: '100px', maxWidth: '250px' }}
                            onClick={() => window.open(`/dashboard/ircodes/${ircode.imageID}`)}
                        />
                    </Stack>
                </TableCell>
                <TableCell
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`/dashboard/myircodes/${ircode.imageID}`)}
                >
                    {ircode.title || 'Untitled IRCODE'}
                </TableCell>
                <TableCell>
                    {new Date(ircode.imageCreated * 1000).toLocaleString(undefined, { dateStyle: 'medium' })}
                    <br />
                    {new Date(ircode.imageCreated * 1000).toLocaleString(undefined, {
                        hour: 'numeric',
                        minute: 'numeric',
                        hourCycle: 'h12',
                    })}
                </TableCell>
                <TableCell
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`/dashboard/profile/${imageUser?.userID}`)}
                >
                    {imageUser?.userName}
                </TableCell>
                <TableCell>
                    <Button
                        variant="contained"
                        onClick={e => {
                            handleMenuClick(e, index);
                            handleTransferClick(imageUser);
                        }}
                    >
                        Action
                        <i
                            style={{ marginLeft: '8px' }}
                            className={`fa-sharp fa-light ${activeButtons[index] ? 'fa-caret-down' : 'fa-caret-up'}`}
                        ></i>
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && activeMenuItemIndex === index}
                        onClose={() => handleMenuClose(index)}
                        elevation={0}
                        sx={{
                            transition: 'none',
                            animation: 'none',
                            boxShadow: 'none',
                            '& .MuiPaper-root': {
                                border: '1px solid purple',
                                borderRadius: '8px',
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleMenuClose(index);
                                setOpenTransferDialog(true);
                            }}
                        >
                            Transfer IRCODE
                        </MenuItem>
                        <MenuItem
                            onClick={() => navigate(`/dashboard/myircodes/${selectedIrcode?.imageID}#information`)}
                        >
                            Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleExcludeDialogOpen(ircode)}>Exclude / Include</MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleMenuClose(index);
                                setSelectedIrcode(ircode);
                                setIrcodeRemovalDialog(true);
                            }}
                        >
                            Remove
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flex: 1 }}>
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    m: 'auto',
                    p: 4,
                    borderRadius: '8px',
                    width: { sm: '100%', md: '90%' },
                    backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
                    boxShadow: 1,
                }}
            >
                <IrcodeSearch
                    searchTerms={searchTerms}
                    setSearchTerms={setSearchTerms}
                    searchType={searchType}
                    setSearchType={setSearchType}
                    handleSearch={handleSearch}
                />
                <Stack direction="row" spacing={2}>
                    <Box sx={{ width: '100px' }}>
                        <Select value={selectedAction} onChange={handleActionChange} displayEmpty>
                            <MenuItem value="">Bulk Operations</MenuItem>
                            <MenuItem value="delete">Delete</MenuItem>
                            <MenuItem value="transfer">Transfer</MenuItem>
                        </Select>
                    </Box>
                    <Button
                        sx={{ display: selectedAction ? 'block' : 'none' }}
                        onClick={handleSubmitAction}
                        disabled={selectedIrcodes.length === 0}
                    >
                        submit {selectedAction}
                    </Button>
                </Stack>
                {isLocalLoading && (
                    <CircularProgress
                        sx={{
                            alignSelf: 'center',
                        }}
                    />
                )}
                {searchResults && searchResults.Results.Ircodes.length > 0 ?
                    <TableContainer>
                        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{/* empty for spacing */}</TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'title'}
                                            direction={sortField === 'title' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('title')}
                                        >
                                            Title
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'imageCreated'}
                                            direction={sortField === 'imageCreated' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('imageCreated')}
                                        >
                                            {searchType === 'registered' ? 'Registered On' : 'Scanned On'}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'userName'}
                                            direction={sortField === 'userName' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('userName')}
                                        >
                                            Owner
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{/* empty for spacing */}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{searchResults.Results.Ircodes.map(renderSearchResult)}</TableBody>
                        </Table>
                    </TableContainer>
                :   searchResults && (
                        <Typography variant="subtitle1" sx={{ textAlign: 'center', marginTop: '20px' }}>
                            Result Empty
                        </Typography>
                    )
                }

                <Dialog open={ircodeConfirmDialog} onClose={() => setIrcodeConfirmDialog(false)}>
                    <Box sx={{ p: 2, border: '1px solid', borderColor: Color.PrimaryLavender, borderRadius: '8px' }}>
                        <Typography variant="webHistoryTitle" sx={{ paddingBottom: 4 }}>
                            Confirm Transfer Information
                        </Typography>
                        <Stack direction="column" spacing={1}>
                            {confirmDialogLabels.map(({ label, value }, index) => (
                                <Stack key={index} direction="row" spacing={3}>
                                    <Typography>{label}</Typography>
                                    <Typography variant="lineDataLabel">{value}</Typography>
                                </Stack>
                            ))}
                        </Stack>
                        <Typography variant="webHistoryTitle" sx={{ paddingTop: 4 }}>
                            Press Confirm & Receive 2FA Code To Finish Transfer
                        </Typography>
                        <DialogActions>
                            <Button sx={{ color: 'red' }} onClick={() => setIrcodeConfirmDialog(false)}>
                                Close
                            </Button>
                            <Button onClick={handle2FARequest}>Confirm</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
                <Dialog open={ircodeRemovalDialog} onClose={() => setIrcodeRemovalDialog(false)}>
                    <Box sx={{ p: 2, border: '1px solid', borderColor: Color.PrimaryLavender, borderRadius: '8px' }}>
                        <Typography sx={{ paddingBottom: 4 }} variant="webTimeNotification">
                            Remove IRCODE
                        </Typography>
                        <Typography>Permanently delete IRCODE or mark it to be removed later?</Typography>
                        <DialogActions>
                            <Button onClick={() => handleRemoveIrcodeConfirmation(true)}>Permanently Delete</Button>
                            <Button onClick={() => handleRemoveIrcodeConfirmation(false)}>Mark for Later</Button>
                            <Button onClick={() => setIrcodeRemovalDialog(false)}>Cancel</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
                <Dialog open={openTransferDialog} onClose={() => setOpenTransferDialog(false)}>
                    <IrcodeUserSearch
                        closeDialog={() => setOpenTransferDialog(false)}
                        onUserSelect={handleUserSelect}
                    />
                </Dialog>
                <Dialog open={excludeDialog} onClose={() => setExcludeDialog(false)}>
                    <Box sx={{ p: 2, borderRadius: '8px' }}>
                        <Typography sx={{ paddingBottom: 4 }} variant="webLabel2">
                            Confirm Exclusion
                        </Typography>
                        <Typography sx={{ paddingBottom: 2 }}>
                            Are you sure you want to exclude this IRCODE in contest results?
                        </Typography>
                        <DialogActions>
                            <Button onClick={() => setExcludeDialog(false)}>Cancel</Button>
                            <Button onClick={() => handleExclude(1)}>Exclude IRCODE</Button>
                            <Button onClick={() => handleExclude(0)}>Include IRCODE</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
                <Dialog open={bulkRemovalDialog} onClose={() => setBulkRemovalDialog(false)}>
                    <DialogActions>
                        <Button onClick={() => confirmBulkDelete(true)}>Permanently Delete</Button>
                        <Button onClick={() => confirmBulkDelete(false)}>Mark for Later</Button>
                        <Button onClick={() => setIrcodeRemovalDialog(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        </Box>
    );
}
