import { useContext, useEffect, useRef, useState } from 'react';
import { Stack, Button, Grid } from '@mui/material';
import { Color } from '../../../../Color';
import CategoriesChart from './charts/CategoriesChart';
import TopMediaChart from './charts/TopMediaChart';
import ClickChart from './charts/ClickChart';
import ViewsHistoryChart from './charts/ViewsHistoryChart';
import { Interval, Timeframe } from 'src/hooks/useAnalytics';
import ScanLog from './charts/ScanLog';
import LocationChart from './charts/LocationChart';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';

const timeframeAndIntervals = [
    {
        label: '1D',
        timeframe: Timeframe.This1Days,
        interval: Interval.Hourly,
    },
    {
        label: '3D',
        timeframe: Timeframe.This3Days,
        interval: Interval.Daily,
    },
    {
        label: '5D',
        timeframe: Timeframe.This5Days,
        interval: Interval.Daily,
    },
    {
        label: '1W',
        timeframe: Timeframe.This7Days,
        interval: Interval.Daily,
    },
    {
        label: '1M',
        timeframe: Timeframe.This30Days,
        interval: Interval.Daily,
    },
    {
        label: '3M',
        timeframe: Timeframe.This90Days,
        interval: Interval.Weekly,
    },
    {
        label: '6M',
        timeframe: Timeframe.This180Days,
        interval: Interval.Weekly,
    },
    {
        label: 'YTD',
        timeframe: Timeframe.ThisYear,
        interval: Interval.Monthly,
    },
    // {
    //     label: 'ALL',
    //     value: Timeframe.All,
    // },
];

interface AnalyticsTopProps {
    campaignID: number;
    campaignCreated: number | undefined;
}

export function AnalyticsTop({ campaignID, campaignCreated }: AnalyticsTopProps) {
    const { darkMode } = useContext(ThemeContext) as TTheme;

    const [currentTimeframeAndInterval, setCurrentTimeframeAndInterval] = useState(timeframeAndIntervals[3]);

    const image = undefined; // imageFromImageOperation(imageOperation);
    // console.log('imageOperation', imageOperation);

    const [chartHeight, setChartHeight] = useState(200);
    useEffect(() => {}, []);

    const now = new Date().getTime();
    const campaignCreatedTimestamp = (campaignCreated || 0) * 1000;
    const diffInDays = Math.ceil((now - campaignCreatedTimestamp) / (1000 * 60 * 60 * 24));

    const isDisabledTimeframe = (value: Timeframe) => {
        if (!campaignCreated) return false;
        switch (value) {
            case Timeframe.This30Days:
                return diffInDays < 7;
            case Timeframe.This90Days:
                return diffInDays < 30;
            case Timeframe.This180Days:
                return diffInDays < 90;
            case Timeframe.ThisYear:
                return diffInDays < 180;
            default:
                return false;
        }
    };

    const timeOptionsRef = useRef<HTMLDivElement>(null!);

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                // flexGrow: 1,
                // flexShrink: 0,
                p: 3,
            }}
            ref={timeOptionsRef}
        >
            <Stack direction="row">
                <Stack direction="row" spacing={1}>
                    {timeframeAndIntervals.map(item => {
                        return (
                            <Button
                                key={item.label}
                                disabled={isDisabledTimeframe(item.timeframe)}
                                sx={{
                                    backgroundColor:
                                        currentTimeframeAndInterval === item ?
                                            Color.PrimaryLavender
                                        :   Color.LightLavenderLightMode,
                                    color:
                                        currentTimeframeAndInterval === item ? Color.White
                                        : darkMode ? Color.White
                                        : Color.Black,
                                    borderRadius: 1,
                                }}
                                onClick={() => {
                                    setCurrentTimeframeAndInterval(item);
                                }}
                            >
                                {item.label}
                            </Button>
                        );
                    })}
                </Stack>
            </Stack>

            <Grid container>
                <Grid
                    item
                    xs={12}
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                    }}
                >
                    <TopMediaChart
                        campaignID={campaignID}
                        timeframe={currentTimeframeAndInterval.timeframe}
                        interval={currentTimeframeAndInterval.interval}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                gap={2}
                sx={{
                    flexWrap: { md: 'nowrap' },
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                    }}
                >
                    <LocationChart campaignID={campaignID} timeframe={currentTimeframeAndInterval.timeframe} />
                </Grid>
                <Grid item container xs={12} md={8} gap={2}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            // m: 2,
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                        }}
                    >
                        <ViewsHistoryChart
                            campaignID={campaignID}
                            timeframe={currentTimeframeAndInterval.timeframe}
                            interval={currentTimeframeAndInterval.interval}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            // m: 2,
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                        }}
                    >
                        <CategoriesChart campaignID={campaignID} timeframe={currentTimeframeAndInterval.timeframe} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md
                        sx={{
                            // m: 2,
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                        }}
                    >
                        <ClickChart campaignID={campaignID} timeframe={currentTimeframeAndInterval.timeframe} />
                    </Grid>
                </Grid>
            </Grid>

            <ScanLog
                campaignID={campaignID}
                timeframe={currentTimeframeAndInterval.timeframe}
                timeOptionsRef={timeOptionsRef}
            />
        </Stack>
    );
}

interface AnalyticsBottomProps {
    imageID: string;
}

export function AnalyticsBottom({ imageID }: AnalyticsBottomProps) {
    return null;
}
