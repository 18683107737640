import {
    CardType,
    MetaContent,
    MetaType,
    TArtistName,
    TCardType,
    TDescription,
    TDisplayLocation,
    TEmail,
    TGalleryName,
    TMedium,
    TPhone,
    TPrice,
    TProvenance,
    TSize,
    TTags,
    TTitle,
    TYear,
} from 'src/types/MetaTypes';
import { ILink, TLink } from 'src/types/Link';
import { nullUndefinedOrEmpty } from './string';
import Papa from 'papaparse';
import { Product } from 'src/types/Product';

export const createFileFromURL = async (url: string, filename: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename);
};

const readFile = (file: File, asBuffer = false): Promise<string | Buffer> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            try {
                if (reader.result === null) {
                    throw new Error('reader.result is null');
                } else {
                    // TODO: Deal with ArrayBuffer possibility
                    // @ts-ignore
                    resolve(reader.result);
                }
            } catch (error) {
                reject(error);
            }
        };

        reader.onerror = () => {
            reject(reader.error);
        };

        if (asBuffer) {
            reader.readAsArrayBuffer(file);
        } else {
            reader.readAsText(file);
        }
    });
};

export const readJsonFromFile = async (file: File): Promise<any> => {
    const contents = (await readFile(file)) as string;
    const obj = JSON.parse(contents);
    return obj;
};

export const readCsvFromFile = async (file: File): Promise<any> => {
    // We need the keys, row and type because the Link is broken into Link and Link Name 🙃
    const metaContentForType = (keys: Record<string, number>, row: string[], type: string): MetaContent | null => {
        const content = row[keys[type]];
        if (nullUndefinedOrEmpty(content)) {
            return null;
        }

        switch (type) {
            case MetaType.ArtistName:
                return {
                    name: content,
                } as TArtistName;
            case MetaType.CardType:
                switch (content) {
                    case 'Art Card':
                    case 'ArtCard':
                    case 'artCard':
                        return {
                            cardType: CardType.ArtCard,
                        } as TCardType;
                    case 'General':
                    case 'general':
                    default:
                        return {
                            cardType: CardType.General,
                        } as TCardType;
                }
            case MetaType.Description:
                return {
                    description: content,
                } as TDescription;
            case MetaType.DisplayLocation:
                return {
                    displayLocation: content,
                } as TDisplayLocation;
            case MetaType.Email:
                return {
                    email: content,
                } as TEmail;
            case MetaType.GalleryName:
                return {
                    galleryName: content,
                } as TGalleryName;
            case MetaType.Link:
                const linkName = row[keys['Link Name']];
                return {
                    links: [
                        {
                            url: content,
                            title: linkName,
                            onScanDisplay: false,
                        } as ILink,
                    ],
                } as TLink;
            case MetaType.Medium:
                return {
                    medium: content,
                } as TMedium;
            case MetaType.Phone:
                return {
                    phone: content,
                } as TPhone;
            case MetaType.Price:
                return {
                    price: content,
                } as TPrice;
            case MetaType.Provenance:
                return {
                    provenance: content,
                } as TProvenance;
            case MetaType.Size:
                return {
                    size: content,
                } as TSize;
            case MetaType.Title:
                return {
                    title: content,
                } as TTitle;
            case MetaType.Tags:
                return {
                    tags: content.split(',').map(tag => tag.trim()),
                } as TTags;
            case MetaType.Year:
                return {
                    year: content,
                } as TYear;

            // Need to be handled differently
            case MetaType.Custom:
                return null;

            // Unsupported types
            case MetaType.ArtCard:
            case MetaType.ContactInfo:
            case MetaType.ImageInfo:
            case MetaType.NftLink:
            case MetaType.SocialLinks:
            case MetaType.Unknown:
            case MetaType.Urls:
            case MetaType.UserProvidedInfo:
            default:
                return null;
        }
    };

    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            complete: function (results) {
                if (results.data.length < 2) {
                    throw new Error('CSV needs a row of keys and one or more rows of data.');
                }

                const data = results.data as string[][];
                const keys = data.shift() as string[];
                const keysMap = keys.reduce(
                    (agg, curr, index) => {
                        agg[curr] = index;
                        return agg;
                    },
                    {} as Record<string, number>,
                );

                const files = data
                    .map(row => {
                        if (row.length !== keys.length) {
                            // Field count doesn't match, skip.
                            return null;
                        }

                        return {
                            src: row[keysMap['ImageInfo']],
                            meta: row
                                .map((content, index) => {
                                    const metaContent = metaContentForType(keysMap, row, keys[index]);
                                    if (metaContent === null) {
                                        return null;
                                    }

                                    return {
                                        metaID: 0,
                                        key: index.toString(),
                                        metaType: keys[index],
                                        metaContent,
                                    };
                                })
                                // Filter meta where the metaContent is null
                                .filter(n => n),
                        };
                    })
                    // Filter files where the file is null (ie field count is wrong)
                    .filter(n => n);
                resolve(files);
            },
        });
    });
};

export const readProductCsvFromFile = async (file: File, campaignId?: number): Promise<Product[]> => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            complete: function (results) {
                if (results.data.length < 2) {
                    throw new Error('CSV needs a row of keys and one or more rows of data.');
                }

                const data = results.data as string[][];
                const keys = data.shift() as string[];
                const keysMap = keys.reduce(
                    (agg, curr, index) => {
                        agg[curr] = index;
                        return agg;
                    },
                    {} as Record<string, number>,
                );

                // @ts-ignore
                const products: Product[] = data
                    .map(row => {
                        if (row.length !== keys.length) {
                            // Field count doesn't match, skip.
                            return null;
                        }

                        return {
                            campaignId,
                            name: row[keysMap['productName']],
                            url: row[keysMap['productURL']],
                            imageUrl: row[keysMap['imageURL']],
                        };
                    })
                    // Filter files where the file is null (ie field count is wrong)
                    .filter(n => n);
                resolve(products);
            },
        });
    });
};
