import React from 'react';
import { Grid, Stack, Typography, Link as MuiLink } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { useContext } from 'react';
import { MetaDataLabel, MetaDataValue } from '../../theme/units';
import TextField from '../general/TextField';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { Color } from '../../Color';
import { TEmail } from '../../types/MetaTypes';
import { nullUndefinedOrEmpty } from '../../util/string';

const Email = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TEmail | undefined;

    if (!content || nullUndefinedOrEmpty(content?.email)) {
        return null;
    }

    return (
        <>
            <Grid
                item
                container
                direction="row"
                sx={{
                    py: 1,
                    px: 3,
                    ' + .field-phone div:first-of-type p': {
                        visibility: 'hidden',
                    },
                }}
                className="field-email"
            >
                <Grid item {...MetaDataLabel}>
                    <Typography
                        variant="metaDataLabel"
                        sx={{
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        Contact
                    </Typography>
                </Grid>
                <Grid item {...MetaDataValue}>
                    <Typography variant="metaDataValue">
                        <MuiLink href={`mailto:${content.email}`} target="_blank" rel="noreferrer noopener">
                            {content.email}
                        </MuiLink>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

const EmailForm = ({ metaField, onChange }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TEmail;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                justifyItems: 'center',
                px: 2,
            }}
        >
            <TextField
                label="Contact email"
                placeholder="Email"
                value={content?.email ?? ''}
                onChange={value => handleChange({ name: 'email', value })}
                wrapperSx={{
                    flexGrow: 1,
                }}
            />
        </Stack>
    );
};

export { Email, EmailForm };
