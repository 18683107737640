import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Drawer, Stack, Typography } from '@mui/material';
import { Color, purpleGradientCSS } from '../../Color';
import WordMarkWhite from '../../images/WordMarkWhite.svg';
import WordMarkBlack from '../../images/WordMarkBlack.svg';
import UserContext, { TUser } from '../../contexts/UserContext';
import { useContext, useState } from 'react';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import ProfileContextMenu from '../admin/tools/ProfileContextMenu';

const drawerWidth = 280;
const navItems = [
    {
        label: 'Analytics',
        icon: <i className="fa-fw fa-light fa-chart-pie-simple"></i>,
        path: '/iaw',
        regex: /^\/iaw\/?$/,
    },
    {
        label: 'User analytics',
        icon: <i className="fa-fw fa-light fa-user-pen"></i>,
        path: '/iaw/useranalytics',
        regex: /^\/iaw\/useranalytics\/?$/,
    },
    {
        label: 'IRCODE Maintenance',
        icon: <i className="fa-fw fa-light fa-money-bill-transfer"></i>,
        path: '/iaw/maintainircode',
        regex: /^\/iaw\/maintainircode\/?$/,
    },
    {
        label: 'User Dashboard',
        icon: <i className="fa-fw fa-light fa-user"></i>,
        path: '/dashboard',
    },
];

export default function NavBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const { isMobile, darkMode } = useContext(ThemeContext) as TTheme;
    const { user } = useContext(UserContext) as TUser;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isNavOpen, setIsNavOpen] = useState(false);

    const openUrl = (url: string) => {
        if (url.startsWith('http')) {
            window.open(url, '_blank');
        } else {
            navigate(url);
        }
        setIsNavOpen(false);
    };

    if (isMobile) {
        return (
            <>
                <Stack
                    direction="row"
                    spacing={0}
                    sx={{
                        height: 84,
                        backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderLightMode,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexBasis: 80,
                            flexShrink: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <i className="fa-fw fa-solid fa-bars fa-2xl" onClick={() => setIsNavOpen(true)}></i>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                objectFit: 'contain',
                                width: 'auto',
                                height: '26px',
                            }}
                            src={darkMode ? WordMarkWhite : WordMarkBlack}
                            alt=""
                            onClick={() => openUrl('/dashboard')}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexBasis: 80,
                            flexShrink: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            src={user?.profileUrl}
                            alt=""
                            onClick={event => {
                                setAnchorEl(event.currentTarget);
                            }}
                        />
                    </Box>
                    <ProfileContextMenu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        handleClose={() => {
                            setAnchorEl(null);
                        }}
                    />
                </Stack>
                <Drawer
                    variant="temporary"
                    open={isNavOpen}
                    onClose={() => setIsNavOpen(false)}
                    transitionDuration={0}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <Stack
                        component="nav"
                        sx={{
                            backgroundColor: '#121219',
                            width: '100vw',
                            height: '100vh',
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={0}
                            sx={{
                                height: 84,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexBasis: 80,
                                    flexShrink: 0,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <i
                                    className="fa-fw fa-solid fa-xmark fa-2xl"
                                    style={{ color: Color.White }}
                                    onClick={() => setIsNavOpen(false)}
                                ></i>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        objectFit: 'contain',
                                        width: 'auto',
                                        height: '26px',
                                    }}
                                    src={WordMarkWhite}
                                    alt=""
                                    onClick={() => openUrl('/dashboard')}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexBasis: 80,
                                    flexShrink: 0,
                                }}
                            />
                        </Stack>

                        <Stack
                            sx={{
                                alignItems: 'stretch',
                            }}
                        >
                            {navItems.map(item => (
                                <Button
                                    key={item.label}
                                    sx={{
                                        justifyContent: 'flex-start',
                                        paddingX: '40px',
                                        paddingY: '16px',
                                        borderRadius: 0,
                                        background:
                                            item.regex && location.pathname.match(item.regex) ?
                                                purpleGradientCSS
                                            :   'transparent',
                                    }}
                                    onClick={() => {
                                        openUrl(item.path);
                                    }}
                                >
                                    <Typography variant="webNavlink">
                                        <span style={{ paddingRight: '.5rem' }}>{item.icon}</span>
                                        {item.label}
                                    </Typography>
                                </Button>
                            ))}
                        </Stack>
                    </Stack>
                </Drawer>
            </>
        );
    } else {
        return (
            <Stack
                component="nav"
                sx={{
                    width: drawerWidth,
                    backgroundColor: '#121219',
                }}
            >
                <Box
                    component="img"
                    sx={{
                        paddingX: '40px',
                        paddingY: '40px',
                        objectFit: 'contain',
                    }}
                    src={WordMarkWhite}
                    alt=""
                    onClick={() => openUrl('/dashboard')}
                />
                <Stack
                    sx={{
                        alignItems: 'stretch',
                    }}
                >
                    {navItems.map(item => (
                        <Button
                            key={item.label}
                            sx={{
                                justifyContent: 'flex-start',
                                padding: '16px 0 16px 40px',
                                borderRadius: 0,
                                background:
                                    item.regex && location.pathname.match(item.regex) ?
                                        purpleGradientCSS
                                    :   'transparent',
                            }}
                            onClick={() => openUrl(item.path)}
                        >
                            <Typography variant="webNavlink">
                                <span style={{ paddingRight: '.5rem' }}>{item.icon}</span>
                                {item.label}
                            </Typography>
                        </Button>
                    ))}
                </Stack>
            </Stack>
        );
    }
}
