import { useContext } from 'react';
import { Checkbox, MenuItem, Select as MuiSelect, SxProps, Theme } from '@mui/material';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { Color } from '../../Color';
import { ZIndex } from '../../App';

interface Props {
    options: { label: string; value: string }[];
    multiple?: boolean;
    value: string | string[];
    renderValue?: (value: string | string[]) => string;
    optionRenderer?: (option: { label: string; value: string }, selected: boolean) => JSX.Element;
    onChange?: (value: string | string[]) => void;
    sx?: SxProps<Theme>;
    disabled?: boolean;
}

export const Select = ({ options, multiple = false, value, renderValue, optionRenderer, onChange, sx, disabled = false }: Props) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    return (
        <MuiSelect
            MenuProps={{
                style: {
                    zIndex: ZIndex.ContextMenu,
                },
            }}
            multiple={multiple}
            value={value}
            renderValue={renderValue}
            onChange={event => {
                const {
                    target: { value: newValue },
                } = event;
                // console.log('newValue:', newValue);
                // TODO: Not convinced here
                onChange?.(
                    typeof newValue === 'string' ?
                        multiple ? newValue.split(',')
                        :   newValue
                    :   newValue,
                );
            }}
            // TODO: This style constant enforces a minHeight. Still need to figure out to override.
            sx={{
                // Directly target the select element
                '.MuiSelect-select': {
                    border: `1px solid ${Color.Purple}`,
                },
                // Target the input element within the OutlinedInput component
                '.MuiOutlinedInput-input': {
                    p: 1,
                    // TODO: Add #E8E8F2 in Color.ts
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : '#E8E8F2',
                    fontFamily: 'Nunito Sans',
                    fontSize: '1em',
                    fontStyle: 'normal',
                    fontWeight: 600,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${Color.Purple}`,
                },
                ...sx,
            }}
            disabled={disabled}
        >
            {options.map((option, index) => {
                const selected = multiple ? value.indexOf(option.value) > -1 : value === option.value;

                if (optionRenderer) {
                    return optionRenderer(option, selected);
                } else {
                    return (
                        <MenuItem
                            key={`${index}-${option.value}`}
                            value={option.value}
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '1em',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '1em',
                                p: 1,
                                '&.Mui-selected': {
                                    backgroundColor: multiple ? 'transparent' : 'auto',
                                },
                            }}
                        >
                            {multiple && (
                                <Checkbox
                                    checked={selected}
                                    sx={{
                                        p: 1,
                                        height: '1.5em',
                                    }}
                                />
                            )}
                            {option.label}
                        </MenuItem>
                    );
                }
            })}
        </MuiSelect>
    );
};
