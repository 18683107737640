import { Button, Dialog, Stack, Typography } from '@mui/material';
import { create } from 'zustand';

export interface ConfirmOptions {
    title: string;
    message: string;
    yes?: string;
    no?: string;
    destructive?: boolean;
    onConfirmRedirect?: {
        url: string;
        newTab?: boolean;
    };
}

export type ConfirmCallback = (options: ConfirmOptions) => Promise<boolean>;

export interface ConfirmStore extends ConfirmOptions {
    promise: any;
    confirm: ConfirmCallback;
    handleClose: () => void;
    handleConfirm: () => void;
    handleCancel: () => void;
}

const ConfirmDialog = (): JSX.Element => {
    const promise = useConfirmStore(state => state.promise);
    const title = useConfirmStore(state => state.title);
    const message = useConfirmStore(state => state.message);
    const yes = useConfirmStore(state => state.yes);
    const no = useConfirmStore(state => state.no);
    const destructive = useConfirmStore(state => state.destructive);
    const onConfirmRedirect = useConfirmStore(state => state.onConfirmRedirect);
    const handleConfirm = useConfirmStore(state => state.handleConfirm);
    const handleCancel = useConfirmStore(state => state.handleCancel);

    return (
        <Dialog
            open={promise !== undefined}
            sx={{
                // p: 4,
                zIndex: Number.MAX_SAFE_INTEGER,
                '& .MuiDialog-paper': {
                    minWidth: '30vw',
                    p: 4,
                    borderRadius: 2,
                },
            }}
        >
            <Stack direction="column" spacing={2}>
                <Typography
                    sx={{
                        fontFamily: 'Nocturne Serif',
                        fontSize: '32px',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.03em',
                        textAlign: 'left',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    {message}
                </Typography>
                <Button
                    {...(onConfirmRedirect && {
                        href: onConfirmRedirect.url,
                        target: onConfirmRedirect.newTab ? '_blank' : '_self',
                    })}
                    variant="irdbGradient"
                    sx={{
                        height: 56,
                        background: destructive ? '#cc0000' : 'undefined',
                    }}
                    onClick={handleConfirm}
                >
                    {yes}
                </Button>
                <Button
                    variant="irdbGray"
                    sx={{
                        height: 56,
                    }}
                    onClick={handleCancel}
                >
                    {no}
                </Button>
            </Stack>
        </Dialog>
    );
};

const useConfirmStore = create<ConfirmStore>()((set, get) => ({
    promise: undefined,
    title: '',
    message: '',
    yes: 'Yes',
    no: 'No',
    destructive: false,
    confirm: options => {
        return new Promise<boolean>((resolve, reject) => {
            set({ ...options, promise: { resolve, reject } });
        });
    },
    handleClose: () => {
        set({ promise: undefined });
    },
    handleConfirm: () => {
        const { promise, handleClose } = get();
        promise?.resolve(true);
        handleClose();
    },
    handleCancel: () => {
        const { promise, handleClose } = get();
        promise?.resolve(false);
        handleClose();
    },
}));

const useConfirm = (): {
    confirm: ConfirmCallback;
    ConfirmDialog: () => JSX.Element;
} => {
    const confirm = useConfirmStore(state => state.confirm);
    return {
        confirm,
        ConfirmDialog,
    };
};

export default useConfirm;
