import { Box, Typography, Stack } from '@mui/material';
import { useContext, useState, useCallback, useEffect } from 'react';
import useAnalytics, { Event, Parameter, Timeframe, Type } from 'src/hooks/useAnalytics';
import { getMapJSON } from 'dotted-map';
import DottedMap from 'dotted-map/without-countries';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import SortableTable from 'src/components/general/SortableTable';

const mapJsonString = getMapJSON({ height: 60, grid: 'diagonal' });

interface Props {
    campaignID: number;
    timeframe: Timeframe;
}

export default function LocationChart({ campaignID, timeframe }: Props) {
    interface Location {
        city: string;
        result: number;
    }

    interface Geo {
        lat: number;
        lng: number;
    }

    interface Data {
        [key: string]: any;
    }

    const { darkMode } = useContext(ThemeContext) as TTheme;

    const { query } = useAnalytics();

    const [locations, setLocations] = useState<Location[]>([]);
    const [geos, setGeos] = useState<Geo[]>([]);
    const [svgMap, setSvgMap] = useState<string>();

    const fetchAnalytics = useCallback(() => {
        return Promise.all([
            new Promise<Location[]>((resolve, reject) => {
                query(
                    // Type.CountUnique,
                    Type.Count,
                    {
                        event_collection: Event.ImageScanned,
                        timeframe,
                        // interval: 'daily',
                        filters: [
                            // These two must be together
                            {
                                property_name: Parameter.Match,
                                operator: 'eq',
                                property_value: true,
                            },
                            {
                                property_name: Parameter.CampaignID,
                                operator: 'eq',
                                property_value: campaignID,
                            },
                        ],
                        group_by: ['ip_geo_info.province', 'ip_geo_info.city', 'ip_geo_info.country'],
                        order_by: [{ property_name: 'result', direction: 'DESC' }],
                        limit: 20,
                    },
                )
                    .then(results => {
                        resolve(
                            results
                                .filter(
                                    (result: any) =>
                                        result['ip_geo_info.city'] ||
                                        result['ip_geo_info.province'] ||
                                        result['ip_geo_info.country'],
                                )
                                .map((result: any) => {
                                    const city = [
                                        result['ip_geo_info.city'],
                                        result['ip_geo_info.province'],
                                        result['ip_geo_info.country'],
                                    ].find(value => value !== null && value !== undefined);

                                    return {
                                        city: city,
                                        result: result.result,
                                    };
                                })
                                .sort((a: Location, b: Location) => b.result - a.result)
                                .slice(0, 6),
                        );
                    })
                    .catch((error: any) => {
                        reject(error);
                    })
                    .finally(() => {});
            }),
            new Promise<Geo[]>((resolve, reject) => {
                query(
                    // Type.CountUnique,
                    Type.Count,
                    {
                        // event_collection: Event.ImageViewed,
                        event_collection: Event.ImageScanned,
                        timeframe,
                        filters: [
                            // These two must be together
                            {
                                property_name: Parameter.Match,
                                operator: 'eq',
                                property_value: true,
                            },
                            {
                                property_name: Parameter.CampaignID,
                                operator: 'eq',
                                property_value: campaignID,
                            },
                        ],
                        group_by: ['ip_geo_info.coordinates'],
                        order_by: [{ property_name: 'result', direction: 'DESC' }],
                        limit: 1000,
                    },
                )
                    .then(results => {
                        resolve(
                            results.map((result: any, index: number) => {
                                return {
                                    lat: result['ip_geo_info.coordinates'][1],
                                    lng: result['ip_geo_info.coordinates'][0],
                                };
                            }),
                            // .sort((a: Geo, b: Geo) => b.result - a.result)
                        );
                    })
                    .catch((error: any) => {
                        reject(error);
                    })
                    .finally(() => {});
            }),
        ]);
    }, [campaignID, timeframe, query]);

    useEffect(() => {
        if (!campaignID) {
            return;
        }

        fetchAnalytics()
            .then(([locations, geos]: [Location[], Geo[]]) => {
                setLocations(locations);
                setGeos(geos);
            })
            .catch((error: any) => {
                console.error('error', error);
            });
    }, [campaignID, fetchAnalytics]);

    const createMap = useCallback(
        (geos: Geo[]): string => {
            const map = new DottedMap({ map: JSON.parse(mapJsonString) });
            const radius = 0.33;

            for (let i = geos.length - 1; i >= 0; i--) {
                const geo = geos[i];
                const color =
                    i < Math.max(geos.length * 0.1, 10) ?
                        darkMode ? '#DFEE55'
                        :   '#FB9600'
                    :   '#7A5BDB';
                map.addPin({
                    lat: geo.lat,
                    lng: geo.lng,
                    svgOptions: { color, radius: radius * 2 },
                });
            }

            const svgMap = map.getSVG({
                radius,
                color: '#A5A5CE',
                shape: 'circle',
                backgroundColor: 'transparent',
            });

            return svgMap;
        },
        [darkMode],
    );

    useEffect(() => {
        setSvgMap(createMap(geos));
    }, [createMap, geos]);

    const columns = [
        { label: 'Places', sortValue: 'city' },
        { label: 'Views', sortValue: 'result' },
    ];

    const customCellRenderers: { [key: string]: (row: Data) => React.ReactNode } = {
        Places: row => row.city,
        Views: row => row.result,
    };

    return (
        <Stack direction="column" spacing={2}>
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 700,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.4px',
                    textAlign: 'left',
                }}
            >
                Locations Scanned
            </Typography>
            {svgMap && <Box component="img" src={`data:image/svg+xml;utf8,${encodeURIComponent(svgMap)}`} />}
            <SortableTable columns={columns} rows={locations} cellRenderers={customCellRenderers} />
        </Stack>
    );
}
