import { Accordion, AccordionSummary, Typography, AccordionDetails, Divider, Stack, Box, Dialog } from '@mui/material';
import { useContext, useState } from 'react';
import FeedbackContext, { TFeedback } from '../../../../contexts/FeedbackContext';
import { metaFieldForMetaType, metaIncludes, MetaField, addOrMergeCustom } from '../../../../contexts/MetaContext';
import { MetaType, MetaContent } from '../../../../types/MetaTypes';
import { ArtistNameForm } from './ArtistName';
import { CardTypeForm } from './CardType';
import { CustomForm, Custom } from './Custom';
import { Description, DescriptionForm } from './Description';
import { DisplayLocationForm, DisplayLocation } from './DisplayLocation';
import { Email, EmailForm } from './Email';
import { GalleryNameForm, GalleryName } from './GalleryName';
import { Link, LinkForm } from './Link';
import { ProductLinkForm } from './ProductLink';
import { MediumForm } from './Medium';
import { Phone, PhoneForm } from './Phone';
import { PriceForm, Price } from './Price';
import { ProvenanceForm, Provenance } from './Provenance';
import { SizeForm } from './Size';
import { TagsForm, Tags } from './Tags';
import { TitleForm } from './Title';
import { YearForm } from './Year';
import { nullUndefinedOrEmpty } from '../../../../util/string';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GrayBoxButton from '../../../general/GrayBoxButton';
import AddCustom from './AddCustom';

const ArtCard = ({ meta }: { meta: MetaField[] }) => {
    const artistName = metaFieldForMetaType(meta, MetaType.ArtistName);
    const title = metaFieldForMetaType(meta, MetaType.Title);
    const year = metaFieldForMetaType(meta, MetaType.Year);
    const medium = metaFieldForMetaType(meta, MetaType.Medium);
    const size = metaFieldForMetaType(meta, MetaType.Size);

    const Detail = ({ detail }: { detail: string }) => {
        return (
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18px',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    color: 'gray',
                }}
            >
                {detail}
            </Typography>
        );
    };

    const details = [year?.metaContent.year, medium?.metaContent.medium, size?.metaContent.size]
        .filter(detail => !nullUndefinedOrEmpty(detail))
        .map((detail, index) => {
            return <Detail key={index} detail={detail} />;
        });

    return (
        <Stack
            direction="column"
            sx={{
                paddingX: 3,
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '32px',
                    fontWeight: 600,
                    lineHeight: '40px',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                }}
            >
                {artistName?.metaContent.name ?? 'Anonymous'}
            </Typography>
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '32px',
                    fontWeight: 600,
                    lineHeight: '40px',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                    fontStyle: 'italic',
                }}
            >
                {title?.metaContent.title ?? 'Untitled'}
            </Typography>
            <Stack
                direction="row"
                spacing={1}
                divider={<Detail detail="•" />}
                sx={{
                    marginTop: 2,
                }}
            >
                {details}
            </Stack>
        </Stack>
    );
};

export default function ArtCardStack({
    meta,
    isEditing,
    editingTypes = [
        MetaType.CardType,
        MetaType.ArtistName,
        MetaType.Title,
        MetaType.Year,
        MetaType.Medium,
        MetaType.Size,
        MetaType.Link,
        MetaType.Description,
        MetaType.Tags,
        MetaType.Price,
        MetaType.GalleryName,
        MetaType.DisplayLocation,
        MetaType.Provenance,
        MetaType.Email,
        MetaType.Phone,
        MetaType.Custom,
    ],
    copyOnConfirm,
    onChange,
}: {
    meta: MetaField[];
    isEditing: boolean;
    editingTypes?: MetaType[];
    copyOnConfirm: (metaType: MetaType, metaContent: MetaContent) => void;
    onChange: (metaType: MetaType, metaContent: MetaContent) => void;
}) {
    const { confirm } = useContext(FeedbackContext) as TFeedback;

    const [showMore, setShowMore] = useState(false);
    const [showAddCustom, setShowAddCustom] = useState(false);

    if (isEditing) {
        return (
            <>
                <Stack direction="column" spacing={2}>
                    {editingTypes.includes(MetaType.CardType) && (
                        <CardTypeForm
                            metaField={metaFieldForMetaType(meta, MetaType.CardType)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.CardType, metaContent);
                            }}
                            onChange={async (metaContent: MetaContent) => {
                                if (
                                    await confirm({
                                        title: 'Are your sure?',
                                        message: 'Some data may be lost',
                                        yes: 'Switch Templates',
                                        no: 'Cancel',
                                    })
                                ) {
                                    onChange(MetaType.CardType, metaContent);
                                }
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.ArtistName) && (
                        <ArtistNameForm
                            metaField={metaFieldForMetaType(meta, MetaType.ArtistName)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.ArtistName, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.ArtistName, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.Title) && (
                        <TitleForm
                            metaField={metaFieldForMetaType(meta, MetaType.Title)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.Title, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.Title, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.Year) && (
                        <YearForm
                            metaField={metaFieldForMetaType(meta, MetaType.Year)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.Year, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.Year, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.Medium) && (
                        <MediumForm
                            metaField={metaFieldForMetaType(meta, MetaType.Medium)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.Medium, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.Medium, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.Size) && (
                        <SizeForm
                            metaField={metaFieldForMetaType(meta, MetaType.Size)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.Size, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.Size, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.Link) && (
                        <LinkForm
                            metaField={metaFieldForMetaType(meta, MetaType.Link)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.Link, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.Link, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.ProductLink) && (
                        <ProductLinkForm
                            metaField={metaFieldForMetaType(meta, MetaType.ProductLink)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.ProductLink, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.ProductLink, metaContent);
                            }}
                        />
                    )}
                    {[
                        MetaType.Description,
                        MetaType.Tags,
                        MetaType.Price,
                        MetaType.GalleryName,
                        MetaType.DisplayLocation,
                        MetaType.Provenance,
                        MetaType.Email,
                        MetaType.Phone,
                    ].some(metaType => editingTypes.includes(metaType)) && (
                        <Accordion
                            onChange={(event, expanded) => {
                                setShowMore(expanded);
                            }}
                            sx={{
                                backgroundColor: 'transparent',
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito Sans',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        letterSpacing: '0.01em',
                                    }}
                                >
                                    {showMore ? 'Show Less' : 'Show More'}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack
                                    direction="column"
                                    spacing={3}
                                    sx={{
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    {editingTypes.includes(MetaType.Description) && (
                                        <DescriptionForm
                                            metaField={metaFieldForMetaType(meta, MetaType.Description)}
                                            onCopy={async metaContent => {
                                                copyOnConfirm(MetaType.Description, metaContent);
                                            }}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Description, metaContent);
                                            }}
                                        />
                                    )}
                                    {editingTypes.includes(MetaType.Tags) && (
                                        <TagsForm
                                            metaField={metaFieldForMetaType(meta, MetaType.Tags)}
                                            onCopy={async metaContent => {
                                                copyOnConfirm(MetaType.Tags, metaContent);
                                            }}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Tags, metaContent);
                                            }}
                                        />
                                    )}
                                    {editingTypes.includes(MetaType.Price) && (
                                        <PriceForm
                                            metaField={metaFieldForMetaType(meta, MetaType.Price)}
                                            onCopy={async metaContent => {
                                                copyOnConfirm(MetaType.Price, metaContent);
                                            }}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Price, metaContent);
                                            }}
                                        />
                                    )}
                                    {editingTypes.includes(MetaType.GalleryName) && (
                                        <GalleryNameForm
                                            metaField={metaFieldForMetaType(meta, MetaType.GalleryName)}
                                            onCopy={async metaContent => {
                                                copyOnConfirm(MetaType.GalleryName, metaContent);
                                            }}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.GalleryName, metaContent);
                                            }}
                                        />
                                    )}
                                    {editingTypes.includes(MetaType.DisplayLocation) && (
                                        <DisplayLocationForm
                                            metaField={metaFieldForMetaType(meta, MetaType.DisplayLocation)}
                                            onCopy={async metaContent => {
                                                copyOnConfirm(MetaType.DisplayLocation, metaContent);
                                            }}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.DisplayLocation, metaContent);
                                            }}
                                        />
                                    )}
                                    {editingTypes.includes(MetaType.Provenance) && (
                                        <ProvenanceForm
                                            metaField={metaFieldForMetaType(meta, MetaType.Provenance)}
                                            onCopy={async metaContent => {
                                                copyOnConfirm(MetaType.Provenance, metaContent);
                                            }}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Provenance, metaContent);
                                            }}
                                        />
                                    )}
                                    {editingTypes.includes(MetaType.Email) && (
                                        <EmailForm
                                            metaField={metaFieldForMetaType(meta, MetaType.Email)}
                                            onCopy={async metaContent => {
                                                copyOnConfirm(MetaType.Email, metaContent);
                                            }}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Email, metaContent);
                                            }}
                                        />
                                    )}
                                    {editingTypes.includes(MetaType.Phone) && (
                                        <PhoneForm
                                            metaField={metaFieldForMetaType(meta, MetaType.Phone)}
                                            onCopy={async metaContent => {
                                                copyOnConfirm(MetaType.Phone, metaContent);
                                            }}
                                            onChange={(metaContent: MetaContent) => {
                                                onChange(MetaType.Phone, metaContent);
                                            }}
                                        />
                                    )}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {editingTypes.includes(MetaType.Custom) && (
                        <>
                            <CustomForm
                                metaField={metaFieldForMetaType(meta, MetaType.Custom)}
                                onCopy={async metaContent => {
                                    copyOnConfirm(MetaType.Custom, metaContent);
                                }}
                                onChange={(metaContent: MetaContent) => {
                                    console.log(metaContent);
                                    onChange(MetaType.Custom, metaContent);
                                }}
                            />
                            <GrayBoxButton
                                onClick={() => {
                                    setShowAddCustom(true);
                                }}
                            >
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <i className="fa-solid fa-plus" style={{}}></i>
                                    <Typography
                                        sx={{
                                            display: 'inline-block',
                                            ml: 0.5,

                                            fontFamily: 'Nunito Sans',
                                            fontSize: 20,
                                            fontWeight: 400,
                                            lineHeight: '28px',
                                            letterSpacing: '0.01em',
                                        }}
                                    >
                                        Add custom field
                                    </Typography>
                                </Box>
                            </GrayBoxButton>
                        </>
                    )}
                </Stack>
                <Dialog
                    fullScreen
                    open={showAddCustom}
                    onClose={() => setShowAddCustom(false)}
                    sx={{
                        '& .MuiDialog-paper': {
                            // TODO: Color.ts
                            backgroundColor: '#00000AAA',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            // TODO: Color.ts
                            border: '1px solid #A5A5D1',
                            borderRadius: 3,
                            overflow: 'hidden',
                        }}
                    >
                        <AddCustom
                            onSelection={(field: string) => {
                                const customMetaField = metaFieldForMetaType(meta, MetaType.Custom);
                                const newMetaField = addOrMergeCustom(customMetaField, field);
                                onChange(MetaType.Custom, newMetaField.metaContent);
                                setShowAddCustom(false);
                            }}
                            onClose={() => {
                                setShowAddCustom(false);
                            }}
                        />
                    </Box>
                </Dialog>
            </>
        );
    } else {
        return (
            <Stack spacing={2} divider={<Divider />}>
                <Stack
                // In WebApp, the meta components have the spacing. Maybe fix.
                // spacing={2}
                >
                    <ArtCard meta={meta} />
                    <Link metaField={metaFieldForMetaType(meta, MetaType.Link)} />
                </Stack>
                {metaIncludes(meta, [
                    MetaType.Description,
                    MetaType.Price,
                    MetaType.GalleryName,
                    MetaType.DisplayLocation,
                    MetaType.Provenance,
                    MetaType.Email,
                    MetaType.Phone,
                    MetaType.Tags,
                    MetaType.Custom,
                ]) && (
                    <Stack
                    // In WebApp, the meta components have the spacing. Maybe fix.
                    // spacing={2}
                    >
                        <Description metaField={metaFieldForMetaType(meta, MetaType.Description)} />
                        <Price metaField={metaFieldForMetaType(meta, MetaType.Price)} />
                        <GalleryName metaField={metaFieldForMetaType(meta, MetaType.GalleryName)} />
                        <DisplayLocation metaField={metaFieldForMetaType(meta, MetaType.DisplayLocation)} />
                        <Provenance metaField={metaFieldForMetaType(meta, MetaType.Provenance)} />
                        <Email metaField={metaFieldForMetaType(meta, MetaType.Email)} />
                        <Phone metaField={metaFieldForMetaType(meta, MetaType.Phone)} />
                        <Tags metaField={metaFieldForMetaType(meta, MetaType.Tags)} />
                        <Custom metaField={metaFieldForMetaType(meta, MetaType.Custom)} />
                    </Stack>
                )}
            </Stack>
        );
    }
}
