import React, { useContext, useCallback } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Color } from '../../Color';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { imageAccept } from 'src/util/reactDropzone';
import useProfileState from 'src/stores/useProfileState';
import UserAvatar from '../general/UserAvatar';
import { nullUndefinedOrEmpty } from 'src/util/string';

type EditProfileProps = {
    onClose: () => void;
};

const EditProfile: React.FC<EditProfileProps> = ({ onClose }) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { info, setProfileInfo: setInfo, onSave, handleRemoveAvatar } = useProfileState();
    const { name, userName, profileUrl } = info;

    const onDrop = useCallback(
        (files: File[]) => {
            setInfo({ avatarFile: files[0] });
        },
        [setInfo],
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: imageAccept });
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await onSave();
            onClose();
        } catch (error) {
            console.error('Failed to update profile:', error);
        }
    };

    return (
        <Container
            sx={{
                backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderLightMode,
                borderRadius: '0 0 4 4',
            }}
        >
            <Button
                sx={{
                    position: 'absolute',
                    top: '0.5rem',
                    right: '0.5rem',
                    color: '#7f7f7f',
                    fontSize: '1.75rem',
                    lineHeight: '1.25rem',
                }}
                onClick={onClose}
            >
                <i className="fa-duotone fa-circle-xmark"></i>
            </Button>

            <Box sx={{ lineHeight: '3.5rem', marginTop: '2rem', marginBottom: '2rem' }}>
                <Typography variant="headlineMedium">Edit profile</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        gap: '.75rem',
                    }}
                >
                    <Box {...getRootProps()}>
                        <input {...getInputProps()} />
                        <UserAvatar
                            profileUrl={profileUrl}
                            userName={userName}
                            alt="profile image"
                            sx={{ height: '4rem', width: '4rem', cursor: 'pointer' }}
                        />
                    </Box>
                    <Box {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button variant="editProfileImageUpload">UPLOAD</Button>
                    </Box>
                    <Button
                        variant="editProfileImageUpload"
                        onClick={handleRemoveAvatar}
                        disabled={nullUndefinedOrEmpty(profileUrl)}
                    >
                        REMOVE
                    </Button>
                </Box>
            </Box>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <Typography variant="metaDataLabel">Username</Typography>
                <TextField value={userName} variant="outlined" onChange={e => setInfo({ userName: e.target.value })} />
                <Typography variant="metaDataLabel">Name</Typography>
                <TextField value={name} variant="outlined" onChange={e => setInfo({ name: e.target.value })} />
                {/*
                <Typography variant='metaDataLabel'>Email</Typography>
                <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Typography variant='metaDataLabel'>Phone</Typography>
                <TextField
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <Typography variant='metaDataLabel'>Password</Typography>
                <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                */}
                <Button type="submit">Update Profile</Button>
            </Box>
        </Container>
    );
};

export default EditProfile;
