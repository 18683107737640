import * as React from 'react';
import { Typography, TextField, Button, InputAdornment, Box, Stack, Divider } from '@mui/material';
import { Color } from '../../../Color';
import { SocialLink } from './Social';
import { useContext } from 'react';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import WordMarkWhite from '../../../images/WordMarkWhite.svg';

const MenuHeader = ({ children }: { children: React.ReactNode }) => (
    <Typography
        sx={{
            fontFamily: 'Nocturn serif',
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: '26px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: Color.White,
        }}
    >
        {children}
    </Typography>
);

const MenuItem = ({
    children,
    href,
    openInNewTab,
}: {
    children: React.ReactNode;
    href?: string;
    openInNewTab?: boolean;
}) => (
    <Typography
        sx={{
            fontFamily: 'Nunito sans',
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: Color.White,
            textDecoration: 'none',
            transition: 'color 0.3s',
            '&:hover': {
                color: Color.PalerPurple,
                cursor: 'pointer',
            },
        }}
        {...(!!href && {
            component: 'a',
            href,
            ...(openInNewTab && { target: '_blank', rel: 'noreferrer noopener' }),
        })}
    >
        {children}
    </Typography>
);

export default function Footer() {
    const { darkMode } = useContext(ThemeContext) as TTheme;

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
    }

    const socialLinks = [
        { icon: <i className="fa-brands fa-youtube"></i>, path: SocialLink.YouTube },
        { icon: <i className="fa-brands fa-facebook-f"></i>, path: SocialLink.Facebook },
        { icon: <i className="fa-brands fa-twitter"></i>, path: SocialLink.Twitter },
        { icon: <i className="fa-brands fa-instagram"></i>, path: SocialLink.Instagram },
        { icon: <i className="fa-brands fa-linkedin-in"></i>, path: SocialLink.LinkedIn },
    ];

    return (
        <Stack
            direction="column"
            spacing={8}
            sx={{
                width: '100%',
                paddingTop: { xs: 2, sm: 8 },
                paddingBottom: { xs: 2, sm: 2 },
                backgroundColor: Color.Black,
                backgroundImage: 'url(/images/footerBackground.svg)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom right',
            }}
        >
            {false && (
                <Stack
                    direction="column"
                    spacing={4}
                    sx={{
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="mainFont6"
                        component="span"
                        sx={{
                            color: Color.White,
                        }}
                    >
                        Subscribe to Newsletter
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            // fullWidth
                            variant="outlined"
                            placeholder="Enter your email address"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            type="submit"
                                            sx={{ color: 'white', '&:hover': { bgcolor: 'transparent' } }}
                                        >
                                            <i
                                                style={{ color: Color.PalePurple }}
                                                className="fa-thin fa-arrow-right"
                                            ></i>
                                        </Button>
                                    </InputAdornment>
                                ),
                                style: { color: Color.White, backgroundColor: Color.DarkModeBlack },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    width: { xs: '100px', sm: '350px', md: '546px' },
                                    height: '3rem',
                                    borderRadius: '50px',
                                    '& fieldset': {
                                        borderColor: Color.PrimaryLavender,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: Color.PrimaryLavender,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: Color.PrimaryLavender,
                                    },
                                },
                                '& .MuiOutlinedInput-input': {
                                    borderRadius: '50px',
                                    backgroundColor: Color.DarkModeBlack,
                                    color: Color.White,
                                },
                            }}
                        />
                    </form>
                </Stack>
            )}
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{
                    paddingX: { xs: 2, sm: 12 },
                    justifyContent: 'space-between',
                }}
            >
                <Stack direction="column">
                    <Box
                        sx={{
                            paddingBottom: '2rem',
                            display: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            cursor: 'default',
                            userSelect: 'none',
                        }}
                    >
                        <img
                            draggable={false}
                            style={{
                                width: 'auto',
                                height: '1.9rem',
                            }}
                            src={WordMarkWhite}
                            alt="Logo"
                        />
                    </Box>
                    <Stack direction="row" spacing={2}>
                        {socialLinks.map((item, index) => (
                            <a
                                key={index}
                                href={item.path}
                                target="_blank"
                                rel="noreferrer noopener"
                                style={{ color: Color.White }}
                            >
                                {item.icon}
                            </a>
                        ))}
                    </Stack>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={8}>
                    <Stack direction="column" spacing={4}>
                        <MenuHeader>Company</MenuHeader>
                        <Stack direction="column" spacing={2}>
                            <MenuItem openInNewTab href="https://ircode.com/about">
                                About us
                            </MenuItem>
                            <MenuItem href="mailto:support@ircode.com">Contact</MenuItem>
                        </Stack>
                    </Stack>
                    <Stack direction="column" spacing={4}>
                        <MenuHeader>Information</MenuHeader>
                        <Stack direction="column" spacing={2}>
                            <MenuItem openInNewTab href="https://ircode.com/resources">
                                Resources
                            </MenuItem>
                            <MenuItem openInNewTab href="https://ircode.com/pricing">
                                Pricing
                            </MenuItem>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Divider
                sx={{
                    width: '100%',
                    marginY: 4,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                }}
            />
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                sx={{
                    p: 2,
                    justifyContent: 'space-between',
                    // space-between: 2,
                    // display: "flex",
                    // flexDirection: "column",
                    // alignItems: "center",
                    // justifyContent: "space-between",
                    // justifyItems: 'space-between',
                }}
            >
                <Typography variant="footerTerms">IRCODE © 2024 All rights reserved</Typography>
                <Stack
                    direction="row"
                    spacing={2}
                    // sx={{

                    // }}
                >
                    <Typography
                        component="a"
                        variant="footerTerms"
                        href="https://ircode.com/legal/privacy-policy/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Privacy
                    </Typography>
                    <Typography
                        component="a"
                        variant="footerTerms"
                        href="https://ircode.com/legal/terms-conditions/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Terms & Conditions
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}
