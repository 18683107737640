import { useCallback } from 'react';
import { Product } from "src/types/Product";
import { useLocalStorage } from "@uidotdev/usehooks";
import { readProductCsvFromFile } from 'src/util/file';

export default function useProducts(campaignID?: number) {
    const [products, setProducts] = useLocalStorage<Product[]>('products', []);

    const loadProducts = async (file: File) => {
        const productsFromFile = await readProductCsvFromFile(file, campaignID);

        setProducts([
            ...products,
            ...productsFromFile,
        ]);
    };

    const clearProducts = useCallback(
        () => {
            setProducts(products.filter(product => product.campaignId !== campaignID));
        }, [products, campaignID]
    );

    return {
        products,
        loadProducts,
        clearProducts,
    };
}
