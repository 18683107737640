import {
    InputLabel,
    IconButton,
    TextField as MuiTextField,
    SxProps,
    Theme,
    Stack,
    useTheme,
    FormHelperText,
} from '@mui/material';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { useContext } from 'react';
import { Color } from '../../Color';

export interface Props {
    inputRef?: React.Ref<any>;
    type?: 'text' | 'password' | 'tel';
    // inputRef?: React.RefObject<HTMLInputElement>;
    label?: string;
    placeholder?: string;
    value: string;
    rows?: number;
    onChange: (value: string) => void;
    // onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onCopy?: () => void;
    onDelete?: () => void;
    wrapperSx?: SxProps<Theme>;
    labelSx?: SxProps<Theme>;
    sx?: SxProps<Theme>;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    tryValidateByType?: boolean;
    disabled?: boolean;
    helperText?: string;
}

const validators: Record<string, (value: string) => boolean> = {
    tel: (value: string) => {
        return /^\+?[0-9]+$/.test(value);
    },
};

export default function TextField({
    inputRef,
    type = 'text',
    label,
    placeholder,
    value,
    rows = 1,
    onChange,
    onCopy,
    onDelete,
    wrapperSx,
    labelSx,
    sx,
    inputProps,
    tryValidateByType,
    disabled,
    helperText,
}: Props) {
    const theme = useTheme();
    const { darkMode } = useContext(ThemeContext) as TTheme;

    return (
        <Stack
            direction="column"
            spacing={0.25}
            sx={{
                ...wrapperSx,
            }}
        >
            {label && (
                <InputLabel
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        // TODO: Color.ts
                        color: darkMode ? '#E6E6E6' : '#12121A99',
                        ...labelSx,
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <Stack direction="row" spacing={1}>
                <MuiTextField
                    disabled={disabled}
                    inputRef={inputRef}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    multiline={rows > 1}
                    rows={rows}
                    // InputProps={{
                    //     endAdornment: name && (
                    //         <InputAdornment position="end" sx={{ backgroundColor: '#F8F8FB' }}>
                    //             <IconButton onClick={ () => setName('') } edge="end">
                    //                 <ClearIcon />
                    //             </IconButton>
                    //         </InputAdornment>
                    //     )
                    // }}
                    onChange={event => {
                        if (tryValidateByType && type in validators) {
                            const isValid = validators[type];
                            if (!isValid(event.target.value)) {
                                return;
                            }
                        }
                        onChange(event.target.value);
                    }}
                    sx={{
                        flexGrow: 1,
                        '.MuiOutlinedInput-root': {
                            p: 0,

                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                // borderColor: 'initial', // Use 'initial' or specify a specific color to keep it the same on hover
                                // borderColor: 'red',
                                // borderColor: 'auto',
                            },
                        },
                        '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            // borderColor: 'initial',
                            // borderColor: 'purple',
                            // borderColor: 'auto',
                        },

                        '.MuiOutlinedInput-input': {
                            p: 1,

                            borderRadius: '4px',
                            borderWidth: '1px',

                            // TODO: Add to Color.ts
                            borderColor: '#00000052',

                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,

                            fontFamily: 'Nunito Sans',
                            fontSize: '1em',
                            fontWeight: 600,
                            lineHeight: '24px',

                            // TODO: Just merge ahead of time...
                            // @ts-ignore
                            ...sx?.['.MuiOutlinedInput-input'],
                        },
                        ...sx,
                    }}
                    inputProps={inputProps}
                />
                {onCopy && (
                    <IconButton
                        sx={{
                            fontSize: '1rem',
                        }}
                        onClick={onCopy}
                    >
                        <i className="fa-regular fa-copy"></i>
                    </IconButton>
                )}
                {onDelete && (
                    <IconButton
                        sx={
                            {
                                // fontSize: '1rem',
                            }
                        }
                        onClick={onDelete}
                    >
                        <i
                            className="fa-solid fa-xmark"
                            style={
                                {
                                    // color: theme.palette.primary.main,
                                }
                            }
                        ></i>
                    </IconButton>
                )}
            </Stack>
            {helperText && <FormHelperText sx={{ marginTop: 0 }}>{helperText}</FormHelperText>}
        </Stack>
    );
}
