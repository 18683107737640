import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    IconButton,
    Link as MuiLink,
    Stack,
    StackProps,
} from '@mui/material';
import { forwardRef, ReactNode, useContext, useEffect, useState } from 'react';
import DragDropHandle from 'src/components/general/DragDropHandle';
import DragDropList from 'src/components/general/DragDropList';
import { reorder } from 'src/util/array';
import { Color } from '../../../../Color';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { ILink, isTLink, TLink } from '../../../../types/Link';
import { nullUndefinedOrEmpty } from '../../../../util/string';
import TextField from '../../../general/TextField';
import { MetaFieldFormProps, MetaFieldProps } from './Props';

const Link = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TLink | undefined;

    if (!content) {
        return null;
    }

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                width: '100%',
                overflowX: 'auto',
                // width: '50px',
                // overflowX: 'auto',
                // p: 2,
            }}
        >
            {(isTLink(content) ? content.links : []).map(link => {
                let url: URL | undefined;
                try {
                    if (!nullUndefinedOrEmpty(link.url) && !/^https?:\/\//i.test(link.url)) {
                        link.url = 'https://' + link.url;
                    }

                    url = new URL(link.url);
                } catch (error) {
                    // console.error('Error creating URL:', error);
                }

                if (!url) {
                    return null;
                }

                // if (url) {
                //     // url = `https://${url}`;
                //     console.log('url', JSON.stringify(url, null, 4));
                // console.log('url', url);
                // }

                return (
                    <Box key={url.href}>
                        <MuiLink href={url.href} target="_blank" rel="noreferrer noopener">
                            <Chip
                                sx={{
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    lineHeight: '26px',
                                    letterSpacing: '0.15em',
                                    textTransform: 'uppercase',
                                    color: darkMode ? Color.White : Color.Black,
                                    backgroundColor:
                                        darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode, //  Color.White,
                                    // "&:hover": {
                                    //     backgroundColor: Color.primary,
                                    //     color: Color.white,
                                    // },
                                }}
                                // label={url}
                                // <i class="fa-sharp fa-solid fa-link"></i>
                                label={
                                    <>
                                        {!nullUndefinedOrEmpty(link.title) ? link.title : url.hostname}
                                        <i
                                            className="fa-sharp fa-solid fa-link"
                                            style={{
                                                marginLeft: 4,
                                                color: darkMode ? Color.White : Color.Black,
                                            }}
                                        ></i>
                                    </>
                                }
                            />
                        </MuiLink>
                    </Box>
                );
            })}
        </Stack>
    );
};

interface LinkFormItemProps extends StackProps<'div'> {
    disabled?: boolean;
    darkMode?: boolean;
    link: ILink;
    onLinkChange: (link: ILink) => void;
    onLinkCopy?: (link: ILink) => void;
    handle?: ReactNode;
    onDeleteLink: () => void;
}

const LinkFormItem = forwardRef<HTMLDivElement, LinkFormItemProps>(function LinkFormItem(
    { disabled, darkMode, link, handle, onLinkChange: onChange, onLinkCopy: onCopy, onDeleteLink, ...rest },
    ref,
) {
    return (
        <Stack
            ref={ref}
            spacing={1}
            {...rest}
            direction="row"
            sx={{
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                ...rest.sx,
            }}
        >
            <Box
                sx={{
                    minWidth: 4,
                    flexBasis: 4,
                    backgroundColor: darkMode ? 'rgba(1, 1, 1, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                }}
            />
            {handle}
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    flexGrow: 1,
                    p: 1,
                }}
            >
                <TextField
                    disabled={disabled}
                    label="URL"
                    value={link.url}
                    onChange={value => {
                        onChange({ ...structuredClone(link), url: value });
                    }}
                    wrapperSx={{
                        flexGrow: 1,
                    }}
                />
                <TextField
                    disabled={disabled}
                    label="Title"
                    value={link.title}
                    onChange={value => {
                        onChange({ ...structuredClone(link), title: value });
                    }}
                    wrapperSx={{
                        flexGrow: 1,
                    }}
                />
                <FormControlLabel
                    disabled={disabled}
                    control={
                        <Checkbox
                            disabled={disabled}
                            checked={link.onScanDisplay}
                            onChange={event => {
                                onChange({ ...structuredClone(link), onScanDisplay: event.target.checked });
                            }}
                        />
                    }
                    label="Display link on scan"
                />
            </Stack>
            <Stack
                direction="column"
                sx={{
                    justifyContent: 'center',
                }}
            >
                {onCopy && (
                    <IconButton
                        disabled={disabled}
                        sx={{
                            fontSize: '1rem',
                        }}
                        onClick={() => {
                            onCopy(link);
                        }}
                    >
                        <i className="fa-regular fa-copy" />
                    </IconButton>
                )}
                <IconButton
                    disabled={disabled}
                    sx={{
                        width: '2em',
                        height: '2em',
                        alignSelf: 'center',
                    }}
                    onClick={() => onDeleteLink()}
                >
                    <i className="fa-solid fa-xmark" />
                </IconButton>
            </Stack>
        </Stack>
    );
});

const LinkForm = ({ metaField, onCopy, onChange }: MetaFieldFormProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TLink;

    const [links, setLinks] = useState(isTLink(content) ? content.links : []);

    useEffect(() => {
        if (!links.length && isTLink(content)) {
            setLinks(content.links ?? []);
        }
    }, [links, content]);

    const handleSetLinks = (links: ILink[]) => {
        setLinks(links);
        onChange?.({ links });
    };

    const handleChange = (index: number, value: ILink) => {
        const newLinks = [...links];
        newLinks[index] = value;
        handleSetLinks(newLinks);
    };

    const onDeleteLink = (index: number) => {
        const newLinks = structuredClone(links);
        newLinks.splice(index, 1);
        handleSetLinks(newLinks);
    };

    const items = isTLink(content) ? content.links : [];
    const isDragDropEnabled = items.length > 1;

    return (
        <>
            {/* This works, but not for any good reason */}
            <DragDropList
                disabled={!isDragDropEnabled}
                items={items}
                listRender={(children, provided) => (
                    <Stack {...provided.droppableProps} ref={provided.innerRef} rowGap={2}>
                        {children}
                        {provided.placeholder}
                    </Stack>
                )}
                itemRender={([link, index], provided, { isDragging, isDropAnimating }) => (
                    <LinkFormItem
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        handle={isDragDropEnabled ? <DragDropHandle {...provided.dragHandleProps} sm /> : undefined}
                        disabled={isDragging || isDropAnimating}
                        darkMode={darkMode}
                        link={link}
                        onLinkChange={link => handleChange(index, link)}
                        onLinkCopy={onCopy && (() => onCopy({ links: [link] }))}
                        onDeleteLink={() => onDeleteLink(index)}
                    />
                )}
                onDragEnd={result => {
                    if (!result.destination) {
                        return;
                    }

                    const newLinks = reorder(links, result.source.index, result.destination.index);
                    handleSetLinks(newLinks);
                }}
            />
            <Button
                variant="irdbGray"
                sx={{}}
                onClick={() => {
                    const newLinks = [
                        ...(isTLink(content) ? content.links : links),
                        {
                            title: '',
                            url: '',
                            onScanDisplay: false,
                        } as ILink,
                    ];
                    handleSetLinks(newLinks);
                }}
            >
                <i className="fa-solid fa-link"></i>
                Add Link
            </Button>
        </>
    );
};

export { Link, LinkForm };
