import { useContext, useEffect } from 'react';
import { unstable_useBlocker } from 'react-router-dom';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import { create } from 'zustand';

const enabled = create<boolean>()(() => false);

const handler = (e: BeforeUnloadEvent) => {
    e.returnValue = true;
};
const enable = () => {
    if (enabled.getState()) return;
    window.addEventListener('beforeunload', handler);
    enabled.setState(true);
};
const disable = () => {
    if (!enabled.getState()) return;
    window.removeEventListener('beforeunload', handler);
    enabled.setState(false);
};

const useBeforeUnloadConfirm = () => {
    const { confirm } = useContext(FeedbackContext) as TFeedback;
    // TODO: No longer unstable with the latest version of react-router-dom, should be replaced when we update the package.
    const blocker = unstable_useBlocker(({ currentLocation, nextLocation }) => {
        return enabled.getState() && nextLocation.pathname !== currentLocation.pathname;
    });

    useEffect(() => disable, []);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            confirm({ title: 'Leave this page?', message: 'Changes you made may not be saved.' }).then(result => {
                if (result) {
                    disable();
                    blocker.proceed();
                }
            });
        }
    }, [blocker, blocker.state]);

    return { enable, disable };
};

export default useBeforeUnloadConfirm;
