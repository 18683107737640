import React, { useContext, useEffect, useState } from 'react';
import { Stack, Box, Dialog, Button } from '@mui/material';
import { Color } from '../../../../Color';
import MediaContext, {
    Image,
    ImageOperation,
    TMedia,
    imageOperationFromImage,
    Upload,
} from '../../../../contexts/MediaContext';
import MetaContext, {
    MetaData as IMetaData,
    TMeta,
    metaFieldForMetaType,
    newMetaField,
} from '../../../../contexts/MetaContext';
import FeedbackContext, { TFeedback } from '../../../../contexts/FeedbackContext';
import Cropper from '../../../image/CropperExtended';
import { useNavigate } from 'react-router-dom';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { CardType, MetaContent, MetaType, TCardType } from '../../../../types/MetaTypes';
import { ArtistNameForm } from '../MetaData/ArtistName';
import { DescriptionForm } from '../MetaData/Description';
import { TitleForm } from '../MetaData/Title';
import { LinkForm } from '../MetaData/Link';
import { ProductLinkForm } from '../MetaData/ProductLink';
import { TagsForm } from '../MetaData/Tags';
import { CategoryForm } from '../MetaData/Category';
import { Asset } from 'src/util/image';

interface IrcodeInformationTopProps {
    image: Image;
    meta: IMetaData;
    setMeta: (meta: IMetaData) => void;
    onCropped: (imageOperation: ImageOperation<Upload>) => void;
}

export function IrcodeInformationTop({ image, meta, setMeta, onCropped }: IrcodeInformationTopProps) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { confirm } = useContext(FeedbackContext) as TFeedback;
    const { foveate, query } = useContext(MediaContext) as TMedia;

    const [showAddCustom, setShowAddCustom] = useState(false);
    const [showCropper, setShowCropper] = useState(false);
    // This is for local operations
    const [imageOperation, setImageOperation] = useState<ImageOperation<any>>();
    const [croppedImage, setCroppedImage] = useState<Asset>();
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        // console.log('IrcodeInformationTop > meta', meta);
    }, [meta]);

    // const showCropper = imageOperation !== undefined
    //     && imageOperation.operation.type === Type.Crop;

    const onChange = (metaType: MetaType, metaContent: MetaContent) => {
        const newMeta = structuredClone(meta) as IMetaData;
        const index = newMeta.Meta.findIndex(m => m.metaType === metaType);
        if (index !== -1) {
            newMeta.Meta[index].metaContent = metaContent;
        } else {
            newMeta.Meta.push(newMetaField(metaType, metaContent));
        }
        setMeta(newMeta);
    };

    const cardType =
        (metaFieldForMetaType(meta.Meta, MetaType.CardType)?.metaContent as TCardType | undefined)?.cardType ??
        CardType.ArtCard;

    const onCropperProgress = (progress: ImageOperation<any>) => {
        setImageOperation(progress);
    };

    return (
        <>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={4}
                sx={{
                    p: 3,
                }}
            >
                <Stack
                    key="meta"
                    direction="column"
                    spacing={3}
                    sx={{
                        width: { xs: '100%', md: '50%' },
                        minWidth: { md: 400, sm: 300, xs: 'unset' },
                    }}
                >
                    <CategoryForm
                        metaField={metaFieldForMetaType(meta.Meta, MetaType.Category)}
                        onChange={async (metaContent: MetaContent) => {
                            onChange(MetaType.Category, metaContent);
                        }}
                    />
                    <TitleForm
                        metaField={metaFieldForMetaType(meta.Meta, MetaType.Title)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Title, metaContent);
                        }}
                    />
                    <LinkForm
                        metaField={metaFieldForMetaType(meta.Meta, MetaType.Link)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Link, metaContent);
                        }}
                        // showAddLink={true}
                    />
                    <ProductLinkForm
                        metaField={metaFieldForMetaType(meta.Meta, MetaType.ProductLink)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.ProductLink, metaContent);
                        }}
                    />
                    <ArtistNameForm
                        metaField={metaFieldForMetaType(meta.Meta, MetaType.ArtistName)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.ArtistName, metaContent);
                        }}
                    />
                    <DescriptionForm
                        metaField={metaFieldForMetaType(meta.Meta, MetaType.Description)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Description, metaContent);
                        }}
                    />
                    <TagsForm
                        metaField={metaFieldForMetaType(meta.Meta, MetaType.Tags)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Tags, metaContent);
                        }}
                    />
                </Stack>

                {
                    <Stack
                        key="cropper"
                        direction="column"
                        spacing={4}
                        sx={{
                            width: { xs: '100%', md: '50%' },
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                width: '100%',
                                height: 368,
                                borderRadius: 1,
                                objectFit: 'contain',
                            }}
                            src={croppedImage?.url ?? image?.imageUrl}
                            alt=""
                        />
                        <Stack direction={{ xs: 'column', md: 'row' }}>
                            <Button
                                variant="irdbGray"
                                sx={{
                                    height: 58,
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    lineHeight: '26px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                    textTransform: 'none',
                                }}
                                onClick={async () => {
                                    setImageOperation(await imageOperationFromImage(image));
                                    setShowCropper(true);
                                }}
                            >
                                <i className="fa-solid fa-crop-simple"></i> Crop image
                            </Button>
                            {false && (
                                <Button
                                    variant="irdbGray"
                                    sx={{
                                        height: 58,
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '18px',
                                        fontWeight: 600,
                                        lineHeight: '26px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                    }}
                                >
                                    <i className="fa-light fa-images fa-2xl" style={{ color: Color.Purple }}></i>Upload
                                    new
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                }
            </Stack>
            <Dialog open={showCropper}>
                <Cropper
                    imageOperation={imageOperation}
                    upload
                    onProgress={onCropperProgress}
                    onSuccess={i => {
                        setCroppedImage(i.cropped);
                        setShowCropper(false);
                        onCropped(i);
                    }}
                    onRetake={() => {
                        setShowCropper(false);
                        setImageOperation(undefined);
                    }}
                    onCancel={() => {
                        setShowCropper(false);
                        setImageOperation(undefined);
                    }}
                />
            </Dialog>
        </>
    );
}

interface IrcodeInformationBottomProps {
    image: Image;
    meta: IMetaData;
    onUpdate: () => void;
}

export function IrcodeInformationBottom({ image, meta, onUpdate }: IrcodeInformationBottomProps) {
    const navigate = useNavigate();
    const { confirm, notify, setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { remove } = useContext(MediaContext) as TMedia;
    const { save } = useContext(MetaContext) as TMeta;

    const onSave = async () => {
        const confirmation = await confirm({
            title: 'Save Changes ',
            message: 'Are you sure you want to make updates to IRCODE?',
        });
        if (confirmation) {
            try {
                setShowLoading(true);

                // const image = imageFromImageOperation(imageOperation!);
                await save(image!.imageID, meta.Meta);

                // setMeta(setAllToIsEditing(meta, false));

                // If isAdd, reload as non-isAdd
                // if (isAdded) {
                //     // if (add) {
                //     //     goToParentPath();
                //     // }
                //     onPublish?.(image!.imageID);
                //     setIsPublished(true);

                //     // @ts-ignore
                //     if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.messageHandler) {
                //         // @ts-ignore
                //         window.webkit.messageHandlers.messageHandler.postMessage({
                //             "message": "close"
                //         });
                //     }
                //     // const newImageOperation = await getImage(image!.imageID);
                //     // setImageOperation(newImageOperation);
                // } else {

                // TODO: Refresh
                // refresh();

                await onUpdate();
                // }

                // publish(Event.RefreshImages);
                await notify('Success', 'Your IRCODE has been updated');
            } catch (error: any) {
                notify('Error saving metadata', error.message);
            } finally {
                setShowLoading(false);
            }
        }
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={
                {
                    // alignItems: 'flex-start',
                }
            }
        >
            <Button
                variant="irdbGradient"
                onClick={async () => {
                    onSave();
                    // await saveMeta(image.imageID, meta.Meta);
                    // refresh();
                }}
                sx={{
                    width: '30%',
                    // width: 'auto',
                }}
            >
                <i className="fa-light fa-check"></i>Save Changes
            </Button>
            <Button
                variant="irdbText"
                onClick={async () => {
                    if (
                        await confirm({
                            title: 'IRCODE',
                            message: 'Are you sure you want to delete this IRCODE?',
                        })
                    ) {
                        await remove(image.imageID);
                        navigate('/dashboard/myircodes');
                    }
                }}
            >
                Delete IRCODE
            </Button>
        </Stack>
    );
}
