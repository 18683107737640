import { Box, Button, Chip, IconButton as MuiIconButton, Link as MuiLink, Stack, StackProps } from '@mui/material';
import { forwardRef, ReactNode, useContext, useEffect, useState } from 'react';
import DragDropHandle from 'src/components/general/DragDropHandle';
import DragDropList from 'src/components/general/DragDropList';
import FileDropArea from 'src/components/general/FileDropArea';
import { IconButton } from 'src/components/general/IconButton';
import { reorder } from 'src/util/array';
import { imageAccept } from 'src/util/reactDropzone';
import { Color } from '../../../../Color';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { IProductLink, isTProductLink, TProductLink } from '../../../../types/ProductLink';
import { nullUndefinedOrEmpty } from '../../../../util/string';
import TextField from '../../../general/TextField';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { Select } from '../../../general/Select';
import useProducts from 'src/hooks/useProducts';
import { Product } from 'src/types/Product';
import { createFileFromURL } from 'src/util/file';

export const DefaultProductImageUrl =
    'https://firebasestorage.googleapis.com/v0/b/ircode-1a662.appspot.com/o/campaigns%2Fdownload.jpeg?alt=media&token=1c4d62de-4308-424f-b4ee-2c0679285957';

const ProductLink = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TProductLink | undefined;

    if (!content) {
        return null;
    }

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                width: '100%',
                overflowX: 'auto',
            }}
        >
            {(isTProductLink(content) ? content.links : []).map(link => {
                let url: URL | undefined;
                try {
                    if (!nullUndefinedOrEmpty(link.linkToFollow) && !/^https?:\/\//i.test(link.linkToFollow)) {
                        link.linkToFollow = 'https://' + link.linkToFollow;
                    }

                    url = new URL(link.linkToFollow);
                } catch (error) {
                    // console.error('Error creating URL:', error);
                }

                if (!url) {
                    return null;
                }

                return (
                    <Box key={url.href}>
                        <MuiLink href={url.href} target="_blank" rel="noreferrer noopener">
                            <Chip
                                sx={{
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    lineHeight: '26px',
                                    letterSpacing: '0.15em',
                                    textTransform: 'uppercase',
                                    color: darkMode ? Color.White : Color.Black,
                                    backgroundColor:
                                        darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode, //  Color.White,
                                    // "&:hover": {
                                    //     backgroundColor: Color.primary,
                                    //     color: Color.white,
                                    // },
                                }}
                                // label={url}
                                // <i class="fa-sharp fa-solid fa-link"></i>
                                label={
                                    <>
                                        {!nullUndefinedOrEmpty(link.title) ? link.title : url.hostname}
                                        <i
                                            className="fa-sharp fa-solid fa-link"
                                            style={{
                                                marginLeft: 4,
                                                color: darkMode ? Color.White : Color.Black,
                                            }}
                                        ></i>
                                    </>
                                }
                            />
                        </MuiLink>
                    </Box>
                );
            })}
        </Stack>
    );
};

interface ProductLinkFormItemProps extends StackProps<'div'> {
    disabled?: boolean;
    darkMode?: boolean;
    link: IProductLink;
    onLinkChange: (link: IProductLink) => void;
    onLinkCopy?: (link: IProductLink) => void;
    handle?: ReactNode;
    onDeleteLink: () => void;
}

const ProductLinkFormItem = forwardRef<HTMLDivElement, ProductLinkFormItemProps>(
    function ProductLinkFormItem(
        { disabled = false, handle, darkMode, link, onLinkCopy: onCopy, onLinkChange: onChange, onDeleteLink, ...rest }, ref,
    ) {

    const { products } = useProducts();
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    useEffect(() => {
        if (selectedProduct) {
            createFileFromURL(selectedProduct.imageUrl, crypto.randomUUID())
                .then(file => {
                    onChange({
                        ...structuredClone(link),
                        title: selectedProduct.name,
                        linkToFollow: selectedProduct.url,
                        upload: {
                            file,
                            preview: URL.createObjectURL(file),
                        },
                    });
            });
        }
    }, [link, onChange, selectedProduct]);

    const onDrop = (files: File[]) => {
        const file = files[0];
        if (file) {
            onChange({
                ...structuredClone(link),
                upload: {
                    file,
                    preview: URL.createObjectURL(file),
                },
            });
        }
    };

    const imgSrc =
        link.upload ? link.upload.preview
        : link.imageUrl ? link.imageUrl
        : DefaultProductImageUrl;

    return (
        <Stack
            ref={ref}
            direction="row"
            {...rest}
            spacing={1}
            sx={{
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                ...rest.sx,
                // py: 1,
                // px: 3,
            }}
        >
            <Box
                sx={{
                    minWidth: 4,
                    flexBasis: 4,
                    backgroundColor: darkMode ? 'rgba(1, 1, 1, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                }}
            />
            {handle}
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    flexGrow: 1,
                    flexWrap: 'wrap',
                    p: 1,
                }}
            >
                <Select
                    options={products
                        .map(product => ({
                            value: product.url,
                            label: product.name,
                        }))
                        // .sort((a, b) => a.label.localeCompare(b.label))
                    }
                    value={selectedProduct?.url ?? ''}
                    onChange={async value => {
                        setSelectedProduct(products.find(product => product.url === value));
                    }}
                />
                <Box
                    sx={{
                        position: 'relative',
                        width: { xs: '100%', md: 150, lg: 200 },
                        height: { xs: 'auto', md: 150, lg: 200 },
                        aspectRatio: '1 / 1',
                        mr: 1,
                    }}
                >
                    <FileDropArea
                        dropzoneOptions={{
                            onDrop,
                            accept: imageAccept,
                            disabled,
                            multiple: false,
                        }}
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <>
                            <Box
                                component="img"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                src={imgSrc}
                                alt=""
                            />
                            <IconButton
                                disabled={disabled}
                                sx={{
                                    position: 'absolute',
                                    // fontSize: '1rem',
                                    bottom: '1rem',
                                    right: '1rem',
                                }}
                                icon="fa-pen"
                            />
                        </>
                    </FileDropArea>
                </Box>
                <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                        flexGrow: 1,
                        p: 1,
                    }}
                >
                    <TextField
                        disabled={disabled}
                        label="Product Name"
                        value={link.title}
                        onChange={value => {
                            onChange({ ...structuredClone(link), title: value });
                        }}
                    />
                    <TextField
                        disabled={disabled}
                        label="URL"
                        value={link.linkToFollow}
                        onChange={value => {
                            onChange({ ...structuredClone(link), linkToFollow: value });
                        }}
                    />
                </Stack>
            </Stack>
            <Stack
                direction="column"
                sx={{
                    justifyContent: 'center',
                }}
            >
                {onCopy && (
                    <MuiIconButton
                        disabled={disabled}
                        sx={{
                            fontSize: '1rem',
                        }}
                        onClick={() => {
                            onCopy(link);
                        }}
                    >
                        <i className="fa-regular fa-copy"></i>
                    </MuiIconButton>
                )}
                <MuiIconButton
                    disabled={disabled}
                    sx={{
                        width: '2em',
                        height: '2em',
                        alignSelf: 'center',
                        // fontSize: '1rem',
                    }}
                    onClick={() => onDeleteLink()}
                >
                    <i
                        className="fa-solid fa-xmark"
                        style={
                            {
                                // color: theme.palette.primary.main,
                            }
                        }
                    ></i>
                </MuiIconButton>
            </Stack>
        </Stack>
    );
});

const ProductLinkForm = ({ metaField, onCopy, onChange }: MetaFieldFormProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TProductLink;

    const [links, setLinks] = useState(isTProductLink(content) ? content.links : []);

    useEffect(() => {
        if (!links.length && isTProductLink(content)) {
            setLinks(content.links ?? []);
        }
    }, [links, content]);

    const handleSetLinks = (links: IProductLink[]) => {
        setLinks(links);
        onChange?.({ links });
    };

    const handleChange = (index: number, value: IProductLink) => {
        const newLinks = [...links];
        newLinks[index] = value;
        handleSetLinks(newLinks);
    };

    const onDeleteLink = (index: number) => {
        const newLinks = structuredClone(links);
        newLinks.splice(index, 1);
        handleSetLinks(newLinks);
    };

    const items = isTProductLink(content) ? content.links : [];
    const isDragDropEnabled = items.length > 1;

    return (
        <>
            <DragDropList
                disabled={!isDragDropEnabled}
                items={items}
                onDragEnd={result => {
                    if (!result.destination) {
                        return;
                    }
                    const newLinks = reorder(links, result.source.index, result.destination.index);
                    handleSetLinks(newLinks);
                }}
                listRender={(children, provided) => (
                    <Stack {...provided.droppableProps} ref={provided.innerRef} rowGap={2}>
                        {children}
                        {provided.placeholder}
                    </Stack>
                )}
                itemRender={([link, index], provided, { isDragging, isDropAnimating }) => (
                    <ProductLinkFormItem
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        handle={isDragDropEnabled ? <DragDropHandle {...provided.dragHandleProps} /> : undefined}
                        disabled={isDragging || isDropAnimating}
                        darkMode={darkMode}
                        link={link}
                        onLinkChange={value => handleChange(index, value)}
                        onDeleteLink={() => onDeleteLink(index)}
                        onLinkCopy={onCopy && (() => onCopy({ links: [link] }))}
                    />
                )}
            />
            <Button
                variant="irdbGray"
                sx={{}}
                onClick={() => {
                    const newLinks = [
                        ...(isTProductLink(content) ? content.links : links),
                        {
                            title: '',
                            linkToFollow: '',
                            imageUrl: '',
                        } as IProductLink,
                    ];
                    handleSetLinks(newLinks);
                }}
            >
                <i className="fa-solid fa-link"></i>
                Add Product Link
            </Button>
        </>
    );
};

export { ProductLink, ProductLinkForm };
