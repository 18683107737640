import { Alert, CircularProgress, Modal, Snackbar } from '@mui/material';
import React, { createContext, FC, useState } from 'react';
import useExpiringState from '../hooks/useExpiringState';
import useNotify from '../hooks/useNotify';
import useConfirm, { ConfirmCallback } from '../hooks/useConfirm';

export type TFeedback = {
    notify: any;
    confirm: ConfirmCallback;
    showLoading: boolean;
    setShowLoading: (showLoading: boolean) => void;
    setSuccessMessage: (message?: string) => void;
    setErrorMessage: (message?: string) => void;
};

const FeedbackContext = createContext<TFeedback | undefined>(undefined);

interface Props {
    children: React.ReactNode;
}

export const FeedbackProvider: FC<Props> = ({ children }) => {
    const { notify, NotifyDialog } = useNotify();
    const { confirm, ConfirmDialog } = useConfirm();
    const [showLoading, setShowLoading] = useState(false);

    const [successMessage, setSuccessMessage] = useExpiringState<string>(5000, undefined);
    const [errorMessage, setErrorMessage] = useExpiringState<string>(5000, undefined);

    return (
        <FeedbackContext.Provider
            value={{
                notify,
                confirm,
                showLoading,
                setShowLoading,
                setSuccessMessage,
                setErrorMessage,
            }}
        >
            {children}
            <Modal
                open={showLoading}
                sx={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backdropFilter: 'blur(4px)',
                    WebkitBackdropFilter: 'blur(4px)',
                    // TODO: Need a way to keep this on top...
                    zIndex: 10000,
                }}
            >
                <CircularProgress
                    sx={{
                        color: '#fff',
                        outline: 0,
                    }}
                />
            </Modal>
            {/*
            // TODO: What is this type?
            // @ts-ignore */}
            <NotifyDialog />
            {/*
            // TODO: What is this type?
            // @ts-ignore */}
            <ConfirmDialog />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={successMessage !== undefined}
                autoHideDuration={5000}
                /*onClose={handleClose}*/
            >
                <Alert
                    severity="success"
                    sx={{ width: '100%' }}
                    /*onClose={handleClose}*/
                >
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={errorMessage !== undefined}
                // open={true}
                // autoHideDuration={5000}
                /*onClose={handleClose}*/
            >
                <Alert
                    severity="error"
                    sx={{ width: '100%' }}
                    /*onClose={handleClose}*/
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </FeedbackContext.Provider>
    );
};

export default FeedbackContext;
