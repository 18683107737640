import { ReactNode } from 'react';
import { SxProps, Theme, Typography } from '@mui/material';

export default function Title({ children, sx }: { children: ReactNode; sx?: SxProps<Theme> }) {
    return (
        <Typography
            sx={{
                fontFamily: 'Nocturne Serif',
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '26px',
                letterSpacing: '0em',
                textAlign: 'left',
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
}
