import { FirebaseError } from 'firebase/app';
import { useContext, useEffect } from 'react';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import FirebaseContext, { TFirebase } from 'src/contexts/FirebaseContext';
import UserContext, { TUser } from 'src/contexts/UserContext';
import { getFBAuthErrorMessage } from 'src/util/firebase';
import { Quality, shrink } from 'src/util/image';
import { validateName, validateUsername } from 'src/util/string';
import { create, StoreApi } from 'zustand';

export interface TAccount {
    name: string;
    userName: string;
    email: string;
    phoneNumber: string;
    profileUrl?: string;
    newPassword: string;
    confirmNewPassword: string;
    existingPassword: string;
    avatarFile?: File | null;
    providerID?: string;
}

const useProfileInfo = create<TAccount & { set: StoreApi<TAccount>['setState'] }>()((set, get) => ({
    name: '',
    userName: '',
    email: '',
    phoneNumber: '',
    profileUrl: '',
    newPassword: '',
    confirmNewPassword: '',
    existingPassword: '',
    avatarFile: null,
    providerID: '',
    set(state) {
        set(state);
    },
}));

const useProfileState = () => {
    const { set: setInfo, ...info } = useProfileInfo();
    const { setShowLoading, notify, confirm } = useContext(FeedbackContext) as TFeedback;
    const {
        user,
        // addProfileAvatar,
        removeProfileAvatar,
        editProfile,
        updatePassword,
        deleteUser,
    } = useContext(UserContext) as TUser;
    const { upload } = useContext(FirebaseContext) as TFirebase;

    useEffect(() => {
        if (info.avatarFile) {
            setInfo({ profileUrl: URL.createObjectURL(info.avatarFile) });
        }
    }, [info.avatarFile, setInfo]);

    useEffect(() => {
        setInfo({
            name: user?.fullName ?? '',
            userName: user?.userName ?? '',
            email: user?.email ?? '',
            phoneNumber: user?.phone ?? '',
            profileUrl: user?.profileUrl,
            newPassword: '',
            confirmNewPassword: '',
            existingPassword: '',
            providerID: user?.providerID ?? '',
            avatarFile: null,
        });
    }, [user, setInfo]);

    // const handleAvatarUpload = async () => {
    //     if (!info.avatarFile) {
    //         return;
    //     }
    //     setShowLoading(true);
    //     shrink(info.avatarFile, Quality.High)
    //         .then(shrunkenFile => {
    //             return upload(
    //                 shrunkenFile.file,
    //                 'profile',
    //                 progress => {
    //                 });
    //         })
    //         .then(downloadUrl => {
    //             setInfo({ profileUrl: downloadUrl});
    //             return addProfileAvatar(downloadUrl);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         })
    //         .finally(() => {
    //             setShowLoading(false);
    //             setInfo({ avatarFile: null });
    //         });
    // };

    const handleRemoveAvatar = async () => {
        if (info.avatarFile) {
            setInfo({ avatarFile: null, profileUrl: user?.profileUrl });
            return;
        }
        setInfo({ profileUrl: undefined });
    };

    const saveProfile = async () => {
        try {
            let profileUrl = info.profileUrl;
            if (info.avatarFile) {
                const shrunk = await shrink(info.avatarFile, Quality.High);
                const downloadUrl = await upload(shrunk.file, 'profile', progress => {});
                profileUrl = downloadUrl;
                if (info.profileUrl) {
                    URL.revokeObjectURL(info.profileUrl);
                }
                setInfo({ avatarFile: null, profileUrl });
            } else if (!info.profileUrl && user?.profileUrl) {
                profileUrl = undefined;
                await removeProfileAvatar();
                setInfo({ profileUrl });
            }
            setShowLoading(true);
            await editProfile(info.email, info.name, info.userName, info.phoneNumber, profileUrl);
        } catch (error: any) {
            notify('Error saving metadata', error.message);
        } finally {
            setShowLoading(false);
        }
    };

    const changePassword = async () => {
        if (info.newPassword === '') {
            return;
        }

        if (info.newPassword !== info.confirmNewPassword) {
            throw new Error('Passwords do not match');
        }

        await updatePassword(info.existingPassword, info.newPassword);
    };

    const onSave = async () => {
        if (!validateName(info.name)) {
            notify('Problem saving changes', 'Names cannot contain special characters.');
            return;
        }
        if (!validateUsername(info.userName)) {
            notify(
                'Problem saving changes',
                'Username has to be between 5 to 15 characters long. Only letters, numbers, dashes, periods and underscores are allowed.',
            );
            return;
        }
        const confirmation = await confirm({
            title: 'Save Changes',
            message: 'Are you sure you want to save changes to your profile?',
        });
        if (confirmation) {
            try {
                setShowLoading(true);
                await saveProfile();
                //     await editProfile(info.email, info.name, info.userName, info.phoneNumber);
                if (info.newPassword) {
                    await changePassword();
                }
                notify('Success', 'Profile updated successfully');
            } catch (error: any) {
                let errorMessage;
                if (error instanceof FirebaseError) {
                    const fbErrorMessage = getFBAuthErrorMessage(error);
                    if (fbErrorMessage) {
                        errorMessage = fbErrorMessage;
                    }
                }
                if (!errorMessage) {
                    errorMessage = error.message;
                }
                notify('Problem saving changes', errorMessage);
            } finally {
                setInfo({
                    newPassword: '',
                    confirmNewPassword: '',
                    existingPassword: '',
                });
                setShowLoading(false);
            }
        }
    };

    const onDeleteUser = async () => {
        if (await confirm({ title: 'IRCODE', message: 'Are you sure you want to delete your account?' })) {
            try {
                // await remove(image.imageID);
                await deleteUser();

                // await signOut();
                // TODO: Try replace = true
                // navigateTo('/dashboard', true);
                // Using navigater caused the page to sit at a spinner. This is a workaround.
                document.location.href = '/dashboard';
            } catch (error) {
                // console.error("Error signing out:", error);
            }
        }
    };

    return {
        info,
        setProfileInfo: useProfileInfo.setState,
        // handleAvatarUpload,
        handleRemoveAvatar,
        changePassword,
        onSave,
        onDeleteUser,
    };
};

export default useProfileState;
