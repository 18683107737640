import { Avatar, AvatarProps } from '@mui/material';
import { useContext } from 'react';
import { Color } from 'src/Color';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';

export interface UserAvatarProps extends Omit<AvatarProps, 'src' | 'children'> {
    profileUrl?: string;
    userName?: string;
}

export default function UserAvatar({ profileUrl, userName, ...rest }: UserAvatarProps) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    return (
        <Avatar
            {...(profileUrl ? { src: profileUrl, alt: 'User avatar' } : { children: userName?.[0] || null })}
            {...rest}
            sx={{
                backgroundColor: darkMode ? Color.Purple : Color.PalePurple,
                color: darkMode ? Color.White : Color.Black,
                ...rest.sx,
            }}
        />
    );
}
