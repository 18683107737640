import React, { useState, useContext, useEffect } from 'react';
import AdminContext, { TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import {
    Stack,
    TextField,
    CircularProgress,
    InputAdornment,
    Button,
    Box,
    Typography,
    Tooltip,
    Dialog,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { Color } from '../../Color';
import UserContext from '../../contexts/UserContext';
import ModAdmin from './ModAdmin';
import EditUser from './EditUser';
import ModAccount from './ModAccount';
import EnvContext, { TEnv } from '../../contexts/EnvContext';
import RemoveUser from './RemoveUser';

export default function UserAnalytics() {
    const { impersonate } = useContext(EnvContext) as TEnv;
    const { fetchUserSearch, analyticsRefresh } = useContext(AdminContext) as TAdmin;
    const [count, setCount] = useState(0);
    const [searchResults, setSearchResults] = useState<UserSearchResults[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [days, setDays] = useState('');
    const [sortField, setSortField] = useState<keyof UserSearchResults>('userCreated');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [userCount, setUserCount] = useState(0);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [activeButtons, setActiveButtons] = useState<{ [key: number]: boolean }>({});
    const [openPasswordResetDialog, setOpenPasswordResetDialog] = useState(false);
    const [removeDialog, setRemoveDialog] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [openAdminModDialog, setOpenAdminModDialog] = useState(false);
    const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
    const [selectedUserEmail, setSelectedUserEmail] = useState('');
    const [selectedUserName, setSelectedUserName] = useState('');
    const [selectedUserFullName, setSelectedUserFullName] = useState('');
    const [selectedUserPhone, setSelectedUserPhone] = useState('');
    const [selectedUserType, setSelectedUserType] = useState('');
    const [selectedAdminType, setSelectAdminedType] = useState('');
    const [activeMenuItemIndex, setActiveMenuItemIndex] = useState<number | null>(null);
    const [value, setValue] = useState('');
    const userContext = useContext(UserContext);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [selectedUserFBId, setSelectedUserFBId] = useState('');
    const [activeDialog, setActiveDialog] = useState<{ type: string; index: number } | null>(null);
    const [isLocalLoading, setIsLocalLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserSearchResults | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLocalLoading(true);
            const response = await fetchUserSearch(searchTerm, days);
            if (response?.Results.Users) {
                const users = response.Results.Users;
                setSearchResults(users);
                setCount(count + 1);
                handleSort(sortField, users);
            }
            setIsLocalLoading(false);
        };
        fetchData();
    }, [searchTerm, days, analyticsRefresh]);

    const clearSearchTerm = () => {
        setSearchTerm('');
    };

    const clearDays = () => {
        setDays('');
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const effectiveDays = searchTerm.trim() === '' && days.trim() === '' ? '999' : days;
        const response = await fetchUserSearch(searchTerm, effectiveDays);
        if (response?.Results.Users) {
            const users = response.Results.Users;
            setSearchResults(users);
            handleSort(sortField, users);
        }
    };

    const handlePasswordReset = async () => {
        if (selectedUserEmail) {
            try {
                await userContext?.sendPasswordResetEmail(selectedUserEmail);
                setOpenPasswordResetDialog(false);
                setSelectedUserEmail('');
                setSelectedUserName('');
                console.log('Password reset email sent successfully.');
            } catch (error) {
                console.error('Failed to send password reset email:', error);
            }
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        setAnchorEl(event.currentTarget);
        setActiveMenuItemIndex(index);
        setActiveButtons(prevState => ({
            ...prevState,
            [index]: true,
        }));
    };

    const handleMenuClose = (index: number) => {
        setActiveMenuItemIndex(null);
        setAnchorEl(null);
        setActiveButtons(prevState => ({
            ...Object.keys(prevState).reduce(
                (acc, key) => {
                    const numKey = Number(key);
                    acc[numKey] = false;
                    return acc;
                },
                {} as { [key: number]: boolean },
            ),
            [index]: false,
        }));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleSort = (field: keyof UserSearchResults, list: UserSearchResults[] = searchResults) => {
        const isSameField = sortField === field;
        const newSortDirection =
            isSameField ?
                sortDirection === 'asc' ?
                    'desc'
                :   'asc'
            :   sortDirection;

        setSortField(field);
        setSortDirection(newSortDirection);

        const sortedResults = [...list].sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return newSortDirection === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'string' && typeof bValue === 'string') {
                return newSortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
            return 0;
        });
        setSearchResults(sortedResults);
        setUserCount(sortedResults.length);
    };
    const handleOpenDialog = (type: string, index: number) => {
        setActiveDialog({ type, index });
    };

    const handleCloseDialog = () => {
        setActiveDialog(null);
    };

    const handleCloseRemoveUserDialog = () => {
        setRemoveDialog(false);
        setSelectedUserId(null);
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flex: 1 }}>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        m: 'auto',
                        p: 4,
                        borderRadius: '8px',
                        width: { sm: '100%', md: '90%' },
                        backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
                        boxShadow: 1,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Stack direction="column" spacing={2} sx={{ marginBottom: 5 }}>
                            <form
                                onSubmit={handleSubmit}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <TextField
                                    placeholder="Search user by ..."
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Clear">
                                                    <Button onClick={clearSearchTerm}>
                                                        <i
                                                            style={{ fontWeight: 400 }}
                                                            className="fa-solid fa-xmark"
                                                        ></i>
                                                    </Button>
                                                </Tooltip>
                                                <TextField
                                                    variant="standard"
                                                    placeholder="Days"
                                                    value={days}
                                                    onChange={e => setDays(e.target.value)}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            padding: '0 10px',
                                                            width: '60px',
                                                            textAlign: 'center',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        endAdornment:
                                                            days ?
                                                                <InputAdornment position="start">
                                                                    <Typography>Days</Typography>
                                                                </InputAdornment>
                                                            :   null,
                                                    }}
                                                />
                                                <Tooltip title="Clear">
                                                    <Button onClick={clearDays}>
                                                        <i
                                                            style={{ fontWeight: 400 }}
                                                            className="fa-solid fa-xmark"
                                                        ></i>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Search">
                                                    <Button type="submit">
                                                        <i
                                                            style={{ fontWeight: 400, fontSize: '18px' }}
                                                            className="fa-thin fa-arrow-right"
                                                        ></i>
                                                    </Button>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            width: { xs: '100px', sm: '350px', md: '546px' },
                                            height: '3rem',
                                            borderRadius: '50px',
                                        },
                                    }}
                                />
                            </form>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    height: '3rem',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="mainFont9">{userCount} Users in search results</Typography>
                            </Box>
                        </Stack>
                    </Box>
                    {isLocalLoading && (
                        <CircularProgress
                            sx={{
                                alignSelf: 'center',
                            }}
                        />
                    )}
                    {searchResults.length > 0 ?
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortField === 'userName'}
                                                direction={sortField === 'userName' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('userName')}
                                            >
                                                Username
                                            </TableSortLabel>
                                        </TableCell>

                                        <TableCell>
                                            <TableSortLabel
                                                active={sortField === 'fullName'}
                                                direction={sortField === 'fullName' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('fullName')}
                                            >
                                                Full Name
                                            </TableSortLabel>
                                        </TableCell>

                                        <TableCell>
                                            <TableSortLabel
                                                active={sortField === 'countIRCodes'}
                                                direction={sortField === 'countIRCodes' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('countIRCodes')}
                                            >
                                                Registered IRCODES
                                            </TableSortLabel>
                                        </TableCell>

                                        <TableCell>
                                            <TableSortLabel
                                                active={sortField === 'userCreated'}
                                                direction={sortField === 'userCreated' ? sortDirection : 'desc'}
                                                onClick={() => handleSort('userCreated')}
                                            >
                                                Signed Up
                                            </TableSortLabel>
                                        </TableCell>

                                        <TableCell>
                                            <TableSortLabel
                                                active={sortField === 'phone'}
                                                direction={sortField === 'phone' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('phone')}
                                            >
                                                Phone
                                            </TableSortLabel>
                                        </TableCell>

                                        <TableCell>
                                            <TableSortLabel
                                                active={sortField === 'email'}
                                                direction={sortField === 'email' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('email')}
                                            >
                                                Email
                                            </TableSortLabel>
                                        </TableCell>

                                        <TableCell>
                                            <TableSortLabel
                                                active={sortField === 'userTypeDisplay'}
                                                direction={sortField === 'userTypeDisplay' ? sortDirection : 'asc'}
                                                onClick={() => handleSort('userTypeDisplay')}
                                            >
                                                Account
                                            </TableSortLabel>
                                        </TableCell>

                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {searchResults.map((result, index) => (
                                        <TableRow key={result.userID}>
                                            <TableCell
                                                onClick={() => window.open(`/dashboard/profile/${result.userID}`)}
                                                sx={{ alignItems: 'center' }}
                                            >
                                                <Typography
                                                    sx={{
                                                        display: result.accountLocked ? 'inline' : 'none',
                                                        marginRight: 1,
                                                    }}
                                                >
                                                    <i className="fa-light fa-lock"></i>
                                                </Typography>
                                                {result.userName}
                                            </TableCell>
                                            <TableCell>{result.fullName}</TableCell>
                                            <TableCell>{result.countIRCodes} </TableCell>
                                            <TableCell>
                                                {new Date(result.userCreated * 1000).toLocaleString(undefined, {
                                                    dateStyle: 'medium',
                                                })}
                                                <br />
                                                {new Date(result.userCreated * 1000).toLocaleString(undefined, {
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    hourCycle: 'h12',
                                                })}
                                            </TableCell>
                                            <TableCell>{result.phone}</TableCell>
                                            <TableCell>{result.email}</TableCell>
                                            <TableCell>{result.userTypeDisplay}</TableCell>
                                            <TableCell>
                                                <Button variant="editIawUser" onClick={e => handleMenuClick(e, index)}>
                                                    Action
                                                    <i
                                                        style={{ marginLeft: '8px' }}
                                                        className={`fa-sharp fa-light ${activeButtons[index] ? 'fa-caret-down' : 'fa-caret-up'}`}
                                                    ></i>
                                                </Button>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={open && activeMenuItemIndex === index}
                                                    onClose={() => handleMenuClose(index)}
                                                    elevation={0}
                                                    sx={{
                                                        transition: 'none',
                                                        animation: 'none',
                                                        boxShadow: 'none',
                                                        '& .MuiPaper-root': {
                                                            border: '1px solid purple',
                                                            borderRadius: '8px',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem
                                                        key={`${index}-upgrade`}
                                                        onClick={event => {
                                                            setSelectedUserName(result.userName);
                                                            setSelectedUserType(result.userType);
                                                            setSelectedUserFBId(result.FBuserID);
                                                            setSelectedUserId(result.userID);
                                                            handleOpenDialog('upgrade', index);
                                                            handleMenuClose(index);
                                                            setOpenUpgradeDialog(true);
                                                            handleMenuClose(index);
                                                            setOpenUpgradeDialog(true);
                                                        }}
                                                    >
                                                        Upgrade User
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={`${index}-edit`}
                                                        onClick={event => {
                                                            setSelectedUser(result);
                                                            setSelectedUserEmail(result.email);
                                                            setSelectedUserName(result.userName);
                                                            setSelectedUserFullName(result.fullName);
                                                            setSelectedUserPhone(result.phone);
                                                            setSelectedUserFBId(result.FBuserID);
                                                            setSelectedUserId(result.userID);
                                                            handleOpenDialog('edit', index);
                                                            setOpenEditUserDialog(true);
                                                            handleMenuClose(index);
                                                        }}
                                                    >
                                                        Edit User Info
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={`${index}-reset`}
                                                        onClick={event => {
                                                            setSelectedUserEmail(result.email);
                                                            setSelectedUserName(result.userName);
                                                            handleOpenDialog('reset', index);
                                                            setOpenPasswordResetDialog(true);
                                                            handleMenuClose(index);
                                                        }}
                                                    >
                                                        Reset Password
                                                    </MenuItem>

                                                    <MenuItem
                                                        key={`${index}-admin`}
                                                        onClick={event => {
                                                            setSelectedUserType(
                                                                result.internalAdmin === 2 ? 'admin'
                                                                : result.internalAdmin === 3 ? 'support'
                                                                : 'none',
                                                            );
                                                            setSelectedUser(result);
                                                            setSelectAdminedType(
                                                                result.internalAdmin === 2 ? 'admin'
                                                                : result.internalAdmin === 3 ? 'support'
                                                                : 'none',
                                                            );
                                                            handleOpenDialog('admin', index);
                                                            handleCloseDialog();
                                                            handleMenuClose(index);
                                                            setOpenAdminModDialog(true);
                                                        }}
                                                    >
                                                        Add/Remove Admin/Support
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={`${index}-impersonate`}
                                                        onClick={event => {
                                                            impersonate({
                                                                // result as User
                                                                ...result,
                                                            });
                                                        }}
                                                    >
                                                        Impersonate
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={`${index}-remove`}
                                                        onClick={event => {
                                                            setSelectedUser(result);
                                                            setSelectedUserName(result.userName);
                                                            setRemoveDialog(true);
                                                            handleMenuClose(index);
                                                        }}
                                                    >
                                                        Mark to Remove
                                                    </MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    :   <Typography variant="subtitle1" textAlign="center">
                            Empty search field returns all users
                        </Typography>
                    }
                </Stack>
            </Box>
            <Dialog open={openUpgradeDialog} onClose={handleCloseDialog}>
                <ModAccount
                    userName={selectedUserName}
                    accountType={selectedUserType}
                    value={value}
                    handleChange={handleChange}
                    setOpenAccountModDialog={setOpenUpgradeDialog}
                    selectedUserId={selectedUserId}
                    selectedUserFBId={selectedUserFBId}
                    setSelectedUserId={setSelectedUserId}
                    setSelectedUserFBId={setSelectedUserFBId}
                />
            </Dialog>
            <Dialog open={openPasswordResetDialog} onClose={handleCloseDialog}>
                <Box p={2}>
                    <Typography>Are you sure you want to reset the password for {selectedUserName}?</Typography>
                    <Button onClick={handlePasswordReset}>Yes</Button>
                    <Button onClick={() => setOpenPasswordResetDialog(false)}>No</Button>
                </Box>
            </Dialog>
            <Dialog open={openAdminModDialog} onClose={handleCloseDialog}>
                <ModAdmin
                    incomingValue={selectedAdminType}
                    selectedUser={selectedUser}
                    initialValue={selectedAdminType}
                    handleAdminChange={handleChange}
                    setOpenAdminModDialog={setOpenAdminModDialog}
                    setSelectedUserId={setSelectedUserId}
                    setSelectedUserFBId={setSelectedUserFBId}
                />
            </Dialog>
            <Dialog open={openEditUserDialog} onClose={handleCloseDialog}>
                <EditUser
                    handleChange={handleChange}
                    userName={selectedUserName}
                    fullName={selectedUserFullName}
                    email={selectedUserEmail}
                    phone={selectedUserPhone}
                    setSelectedUserPhone={setSelectedUserPhone}
                    setSelectedUserFullName={setSelectedUserFullName}
                    setSelectedUserEmail={setSelectedUserEmail}
                    setSelectedUserName={setSelectedUserName}
                    setOpenEditUserDialog={setOpenEditUserDialog}
                    selectedUserId={selectedUserId}
                    selectedUserFBId={selectedUserFBId}
                    setSelectedUserId={setSelectedUserId}
                    setSelectedUserFBId={setSelectedUserFBId}
                />
            </Dialog>
            <Dialog open={removeDialog} onClose={handleCloseRemoveUserDialog}>
                <RemoveUser
                    selectedUser={selectedUser}
                    onClose={handleCloseRemoveUserDialog}
                    setRemoveDialog={setRemoveDialog}
                />
            </Dialog>
        </>
    );
}
