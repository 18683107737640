import { Outlet } from 'react-router-dom';
import './App.css';
import EnvContext, { TEnv } from './contexts/EnvContext';
import { useContext } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Color } from './Color';
import ThemeContext, { TTheme } from './contexts/ThemeContext';
import { useGoogleAnalytics } from './hooks/useAnalytics';

// https://mui.com/material-ui/customization/z-index/
// mobile stepper: 1000
// fab: 1050
// speed dial: 1050
// app bar: 1100
// drawer: 1200
// modal: 1300
// snackbar: 1400
// tooltip: 1500

// Select the element by its ID
// var element = document.getElementById('myElement');

// // Get the computed styles of the element
// var style = window.getComputedStyle(element);

// // Read the z-index value
// var zIndex = style.getPropertyValue('z-index');

// console.log(zIndex);

export enum ZIndex {
    Menu = 2000,
    BottomSheet = 3000,
    ProfileSheet = 5000,
    ProfileSubMenu = 5050,
    ImageList = 6000,
    AddCustom = 7000,
    FAQ = 7500,
    AuthSheet = 8000,
    // These will be dismissed immediately with action, so no fear of needing to present over them
    ContextMenu = 10_000,
}

function App() {
    const { impersonate, impersonated } = useContext(EnvContext) as TEnv;
    const { darkMode } = useContext(ThemeContext) as TTheme;

    useGoogleAnalytics();

    return (
        <>
            {impersonated && (
                <Stack
                    direction="row"
                    sx={{
                        p: 2,
                        // alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: 'red', // darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '20px',
                            fontWeight: 400,
                            lineHeight: '28px',
                            letterSpacing: '0.01em',
                            color: darkMode ? Color.White : Color.Black,
                        }}
                    >
                        Impersonating {impersonated?.fullName}
                    </Typography>
                    <Button
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '20px',
                            fontWeight: 400,
                            lineHeight: '28px',
                            letterSpacing: '0.01em',
                            color: darkMode ? Color.White : Color.Black,
                        }}
                        onClick={() => {
                            impersonate(undefined);
                        }}
                    >
                        <i className="fa-regular fa-hand" style={{ marginRight: 8 }}></i> Stop impersonating
                    </Button>
                </Stack>
            )}
            <Outlet />
        </>
    );
}

export default App;
