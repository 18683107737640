import { Box, Pagination as MuiPagination, PaginationItem } from '@mui/material';
import { Select } from './Select';
import { Color } from '../../Color';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { useContext } from 'react';
import { ImageSortByField, ImageSortOrderField } from '../../types/Image';

interface Props {
    count: number;
    page: number;
    limit?: number;
    total?: number;
    sortBy?: ImageSortByField;
    sortOrder?: ImageSortOrderField;
    onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    onLimitChange?: (value: number) => void;
    onSortChange?: (sortBy: ImageSortByField, sortOrder: ImageSortOrderField) => void;
}

export default function Pagination({
    count,
    page,
    limit,
    total,
    sortBy,
    sortOrder,
    onChange,
    onLimitChange,
    onSortChange,
}: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
            }}
        >
            <MuiPagination
                count={count}
                page={page}
                renderItem={item => {
                    return (
                        <PaginationItem
                            sx={{
                                ...(item.type === 'start-ellipsis' || item.type === 'end-ellipsis' ?
                                    {
                                        color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                    }
                                :   {}),
                                ...(item.type === 'page' ?
                                    {
                                        border: item.selected ? `1px solid ${Color.Purple}` : 'none',
                                        // TODO: Color.ts
                                        backgroundColor: item.selected ? '#DFDFED' : 'transparent',
                                        color:
                                            item.selected ? Color.Purple
                                            : darkMode ? Color.White
                                            : Color.PrimaryDarkGrayBlue,
                                    }
                                :   {}),
                                ...(item.type === 'previous' || item.type === 'next' ?
                                    {
                                        // TODO: Color.ts
                                        backgroundColor: item.disabled ? '#CAC1EC' : '#7A5BDC',
                                        color: Color.White,
                                        '&:hover': {
                                            // TODO: Color.ts
                                            backgroundColor: item.disabled ? '#CAC1EC' : '#7A5BDC',
                                        },
                                    }
                                :   {}),
                            }}
                            {...item}
                        />
                    );
                }}
                onChange={onChange}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 2,
                }}
            />
            {limit && onLimitChange && (
                <Select
                    options={[
                        { value: '25', label: 'Show 25/page' },
                        { value: '50', label: 'Show 50/page' },
                        { value: '100', label: 'Show 100/page' },
                        { value: '500', label: 'Show ' + ((total || 1) < 501 ? 'all' : '500/page') },
                    ]}
                    value={limit.toString()}
                    onChange={value => {
                        onLimitChange(parseInt(value as string));
                    }}
                />
            )}
            {sortBy && sortOrder && onSortChange && (
                <Select
                    options={[
                        { value: 'internalID-DESC', label: '↑ Sort: Newest' },
                        { value: 'internalID-ASC', label: '↓ Sort: Oldest' },
                        { value: 'title-ASC', label: '↑ Sort: Title A-Z' },
                        { value: 'title-DESC', label: '↓ Sort: Title Z-A' },
                    ]}
                    value={`${sortBy}-${sortOrder}`}
                    onChange={value => {
                        const [newSortBy, newSortOrder] = (value as string).split('-');
                        onSortChange(newSortBy as ImageSortByField, newSortOrder as ImageSortOrderField);
                    }}
                />
            )}
        </Box>
    );
}
