import { Grid, Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { MetaDataLabel, MetaDataRow, MetaDataValue } from '../../../../theme/units';
import { TCardType, CardType as CardTypeEnum } from '../../../../types/MetaTypes';
import { Select } from '../../../general/Select';

interface Props extends MetaFieldProps {
    showEditing?: boolean;
    onEdit?: () => void;
}

const CardType = ({ metaField, showEditing, onEdit }: Props) => {
    const content = metaField?.metaContent as TCardType | undefined;

    if (!content) {
        return null;
    }

    return (
        <>
            <Grid item container direction="row" {...MetaDataRow}>
                <Grid item {...MetaDataLabel}>
                    <Typography
                        variant="metaDataLabel"
                        sx={{
                            p: 2,
                        }}
                    >
                        Title
                    </Typography>
                </Grid>
                <Grid item {...MetaDataValue}>
                    <Typography
                        variant="metaDataValue"
                        sx={{
                            p: 2,
                        }}
                    >
                        {content.cardType}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

const CardTypeForm = ({ metaField, onCopy, onChange }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TCardType | undefined;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack direction="column" spacing={2}>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: 'center',
                }}
            >
                <Select
                    options={[
                        { value: CardTypeEnum.General, label: 'General' },
                        { value: CardTypeEnum.ArtCard, label: 'Art Card' },
                    ]}
                    value={content?.cardType ?? CardTypeEnum.General}
                    onChange={async value => {
                        handleChange({ name: 'cardType', value: value as string });
                    }}
                    sx={{
                        flexGrow: 1,
                    }}
                />
                {onCopy && (
                    <i
                        className="fa-regular fa-copy"
                        onClick={() => {
                            onCopy(content);
                        }}
                    ></i>
                )}
            </Stack>
        </Stack>
    );
};

export { CardType, CardTypeForm };
