import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    IconButton,
    Link as MuiLink,
    Stack,
    StackProps,
} from '@mui/material';
import { forwardRef, ReactNode, useContext, useEffect, useState } from 'react';
import useAnalytics from 'src/hooks/useAnalytics';
import { reorder } from 'src/util/array';
import { Color } from '../../Color';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { ILink, isTLink, TLink } from '../../types/Link';
import { nullUndefinedOrEmpty } from '../../util/string';
import DragDropHandle from '../general/DragDropHandle';
import DragDropList from '../general/DragDropList';
import TextField from '../general/TextField';
import { MetaFieldFormProps, MetaFieldProps } from './Props';

const Link = ({ image, metaField }: MetaFieldProps) => {
    const { imageLinkOpened } = useAnalytics();
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TLink | undefined;

    if (!content || !isTLink(content) || content.links.filter(link => link.url.trim() !== '').length === 0) {
        return null;
    }

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                width: '100%',
                overflowX: 'auto',
                pt: 2,
                pb: 1,
                px: 3,
            }}
        >
            {content.links.map(link => {
                let url: URL | undefined;
                try {
                    if (!nullUndefinedOrEmpty(link.url) && !/^https?:\/\//i.test(link.url)) {
                        link.url = 'https://' + link.url;
                    }

                    url = new URL(link.url);
                } catch (error) {
                    // console.error('Error creating URL:', error);
                }

                if (!url) {
                    return null;
                }

                return (
                    <Box key={url.href}>
                        <MuiLink
                            href={url.href}
                            target="_blank"
                            rel="noreferrer noopener"
                            onClick={() => {
                                image && imageLinkOpened(image, link);
                            }}
                        >
                            <Chip
                                sx={{
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    lineHeight: '26px',
                                    letterSpacing: '0.15em',
                                    textTransform: 'uppercase',
                                    color: darkMode ? Color.White : Color.Black,
                                    backgroundColor:
                                        darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                }}
                                label={
                                    <>
                                        {link.title || url.hostname}
                                        <i
                                            className="fa-sharp fa-solid fa-link"
                                            style={{
                                                marginLeft: 4,
                                                color: darkMode ? Color.White : Color.Black,
                                            }}
                                        ></i>
                                    </>
                                }
                            />
                        </MuiLink>
                    </Box>
                );
            })}
        </Stack>
    );
};

interface LinkFormItemProps extends StackProps<'div'> {
    disabled?: boolean;
    darkMode?: boolean;
    link: ILink;
    onLinkChange: (link: ILink) => void;
    handle?: ReactNode;
    onDeleteLink: () => void;
}

const LinkFormItem = forwardRef<HTMLDivElement, LinkFormItemProps>(function LinkFormItem(
    { disabled, darkMode, link, handle, onLinkChange: onChange, onDeleteLink, ...rest },
    ref,
) {
    return (
        <Stack
            ref={ref}
            direction="row"
            {...rest}
            spacing={1}
            sx={{
                // backgroundColor: Color.LightLavenderDarkMode,
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                ...rest.sx,
            }}
        >
            <Box
                sx={{
                    minWidth: 4,
                    flexBasis: 4,
                    backgroundColor: darkMode ? 'rgba(1, 1, 1, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                }}
            />
            {handle}
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    flexGrow: 1,
                    p: 1,
                }}
            >
                <TextField
                    disabled={disabled}
                    label="URL"
                    placeholder="https://..."
                    value={link.url}
                    onChange={value => {
                        onChange({ ...structuredClone(link), url: value });
                    }}
                    wrapperSx={{
                        flexGrow: 1,
                    }}
                />
                <TextField
                    disabled={disabled}
                    label="Title"
                    placeholder="My link title"
                    value={link.title}
                    onChange={value => {
                        onChange({ ...structuredClone(link), title: value });
                    }}
                    wrapperSx={{
                        flexGrow: 1,
                    }}
                />
                <FormControlLabel
                    disabled={disabled}
                    control={
                        <Checkbox
                            disabled={disabled}
                            checked={link.onScanDisplay}
                            onChange={event => {
                                onChange({ ...structuredClone(link), onScanDisplay: event.target.checked });
                            }}
                        />
                    }
                    label="Display link on scan"
                />
            </Stack>
            <IconButton
                disabled={disabled}
                sx={
                    {
                        // fontSize: '1rem',
                    }
                }
                onClick={() => onDeleteLink()}
            >
                <i
                    className="fa-solid fa-xmark"
                    style={
                        {
                            // color: theme.palette.primary.main,
                        }
                    }
                ></i>
            </IconButton>
        </Stack>
    );
});

const LinkForm = ({ metaField, onChange }: MetaFieldFormProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TLink;

    const [links, setLinks] = useState(isTLink(content) ? content.links : []);

    useEffect(() => {
        if (!links.length && isTLink(content)) {
            setLinks(content.links);
        }
    }, [links, content]);

    const handleSetLinks = (links: ILink[]) => {
        setLinks(links);
        onChange?.({ links });
    };

    const handleChange = (index: number, value: ILink) => {
        const newLinks = [...links];
        newLinks[index] = value;
        handleSetLinks(newLinks);
    };

    const onDeleteLink = (index: number) => {
        const newLinks = structuredClone(links);
        newLinks.splice(index, 1);
        handleSetLinks(newLinks);
    };

    const items = isTLink(content) ? content.links : [];
    const isDragDropEnabled = items.length > 1;

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                py: 1,
                px: 2,
            }}
        >
            <DragDropList
                disabled={!isDragDropEnabled}
                items={items}
                listRender={(children, provided) => (
                    <Stack {...provided.droppableProps} ref={provided.innerRef} rowGap={2}>
                        {children}
                        {provided.placeholder}
                    </Stack>
                )}
                itemRender={([link, index], provided, { isDragging, isDropAnimating }) => (
                    <LinkFormItem
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        handle={isDragDropEnabled ? <DragDropHandle {...provided.dragHandleProps} sm /> : undefined}
                        disabled={isDragging || isDropAnimating}
                        darkMode={darkMode}
                        link={link}
                        onLinkChange={link => handleChange(index, link)}
                        onDeleteLink={() => onDeleteLink(index)}
                    />
                )}
                onDragEnd={result => {
                    if (!result.destination) {
                        return;
                    }

                    const newLinks = reorder(links, result.source.index, result.destination.index);
                    handleSetLinks(newLinks);
                }}
            />
            <Button
                variant="irdbGray"
                sx={{
                    width: '10em',
                }}
                onClick={() => {
                    const newLinks = [
                        ...links,
                        {
                            title: '',
                            url: '',
                            onScanDisplay: false,
                        } as ILink,
                    ];
                    handleSetLinks(newLinks);
                }}
            >
                <i className="fa-solid fa-plus" />
                ADD LINK
            </Button>
        </Stack>
    );
};

export { Link, LinkForm };
