import { Image } from '../../contexts/MediaContext';
import { Stack, BoxProps, StackProps, Typography, Box } from '@mui/material';
import { useContext } from 'react';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { Color } from '../../Color';
import { cls } from 'react-image-crop';

export interface PrivateIndicatorBaseProps {
    image: Image;
    iconMode?: boolean;
}

interface PrivateIndicatorIconProps extends BoxProps<'i'> {
    iconMode: true;
}

export interface PrivateIndicatorTextProps extends StackProps {
    iconMode?: false;
}

export type PrivateIndicatorProps = PrivateIndicatorBaseProps & (PrivateIndicatorIconProps | PrivateIndicatorTextProps);

const PrivateIndicator = (props: PrivateIndicatorProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { image, iconMode, ...rest } = props;
    const isPublic = !image || image.imageStatus === 'publish';
    if (isPublic) {
        return null;
    }
    if (iconMode) {
        return (
            <Box
                component="i"
                pt={1.5}
                pb={1.6}
                px={1}
                borderRadius={2}
                {...rest}
                className={cls('fa-solid fa-lock fa-sm', rest.className)}
                sx={{
                    backgroundColor: darkMode ? 'rgba(0,0,0,0.87)' : '#fff',
                    ...rest.sx,
                }}
            />
        );
    }
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            alignSelf="flex-start"
            borderRadius={2}
            spacing={1}
            mx={3}
            my={1}
            pl={2}
            pr={1.5}
            py={0.5}
            {...(rest as StackProps)}
            sx={{
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                ...rest.sx,
            }}
        >
            <i className="fa-regular fa-lock" />
            <Typography variant="webNavlink" fontSize={13} color="textSecondary">
                This IRCODE is private and visible only to you.
            </Typography>
        </Stack>
    );
};

export default PrivateIndicator;
