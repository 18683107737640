import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Card, LinearProgress, Stack, Typography } from '@mui/material';
import { useCapturedStateWorkaround } from '../../../hooks/useCapturedStateWorkaround';
import MediaContext, {
    TMedia,
    ImageOperation,
    Prep,
    Foveate,
    Upload,
    Query,
    Add,
    replaceImageOperation,
    removeImageOperation,
    Init,
    RegistrationStatus,
} from '../../../contexts/MediaContext';
import { Type } from '../../../contexts/Operation';
import Pending from './Pending';
import Available from './Available';
import Unavailable from './Unavailable';
import Adding from './Adding';
import Added from './Added';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { Color } from '../../../Color';
import MetaContext, { TMeta } from '../../../contexts/MetaContext';
import UserContext, { TUser } from '../../../contexts/UserContext';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import useBeforeUnloadConfirm from '../../../hooks/useBeforeUnloadConfirm';
import { adminIrcodeAccept, ircodeAccept } from 'src/util/reactDropzone';
import FileDropArea from '../../general/FileDropArea';

interface Props {
    onStart: () => void;
    onAdd?: (imageId: string) => Promise<void>;
    onComplete: () => void;
}

export default function BulkUploader({ onStart, onAdd, onComplete }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { notify } = useContext(FeedbackContext) as TFeedback;
    const { user } = useContext(UserContext) as TUser;
    const { init, prep, foveate, upload, query, add } = useContext(MediaContext) as TMedia;
    const { save } = useContext(MetaContext) as TMeta;

    const [files, setFiles, filesRef] = useCapturedStateWorkaround<File[]>();
    const [imageOperations, setImageOperations] = useState<ImageOperation<any>[]>([]);
    const cancelHandlers = useRef<Record<string, Function>>({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const { enable: enableUnloadConfirm, disable: disableUnloadConfirm } = useBeforeUnloadConfirm();

    useEffect(() => {
        if (imageOperations.length === 0) {
            onComplete();
            setProgress(0);
        } else {
            onStart();
        }

        // TODO: if any image operation is processing, we are processing...

        const totalProgress = imageOperations.reduce((acc, imageOperation) => {
            switch (imageOperation.operation.type) {
                case Type.Prep:
                    return acc + 0;
                case Type.Upload:
                    const progress = (imageOperation as ImageOperation<Upload>).operation.Results?.progress ?? 0;
                    return acc + progress;
                case Type.Query:
                    // TODO: Queries take a long time, should at least try and give them some weight
                    return acc + 100;
                case Type.Foveate:
                    return acc + 100;
                default:
                    return acc + 100;
            }
        }, 0);

        setProgress(totalProgress / imageOperations.length);
    }, [imageOperations]);

    useEffect(() => {
        if (filesRef.current === undefined || filesRef.current.length === 0) {
            return;
        }

        onStart();

        for (const file of filesRef.current) {
            // TODO: This entire graph needs to be pushed back into the context with just callbacks for updates / cancel handles

            const { id, promise, cancel } = init(file, (progress: ImageOperation<Init>) => {
                replaceImageOperation(progress, imageOperations, setImageOperations);
            });
            cancelHandlers.current[id] = cancel;
            // continue;

            promise
                .then((is: ImageOperation<Init>[]) => {
                    return Promise.allSettled(
                        is.map(imageOperation => {
                            const { id, promise, cancel } = prep(imageOperation, true);
                            cancelHandlers.current[id] = cancel;

                            // TODO: Set a parent cancel to cancel all children

                            return promise
                                .then(async (i: ImageOperation<Prep>): Promise<ImageOperation<Foveate>> => {
                                    replaceImageOperation(i, imageOperations, setImageOperations);

                                    const { id, promise, cancel } = foveate(i);
                                    cancelHandlers.current[id] = cancel;

                                    return promise;
                                })
                                .then(i => {
                                    replaceImageOperation(i, imageOperations, setImageOperations);

                                    const { id, promise, cancel } = query(i, (progress: ImageOperation<Query>) => {
                                        replaceImageOperation(progress, imageOperations, setImageOperations);
                                    });
                                    cancelHandlers.current[id] = cancel;

                                    return promise;
                                })
                                .then(i => {
                                    replaceImageOperation(i, imageOperations, setImageOperations);
                                })
                                .catch(async error => {
                                    console.error(error);
                                })
                                .finally(() => {
                                    console.log('One finished');
                                });
                        }),
                    );
                })
                .catch(async error => {
                    console.error(error);
                    await notify('Upload Error', error);
                })
                .finally(() => {
                    console.log('All finished');
                    setIsProcessing(false);
                });
        }

        setFiles([]);
    }, [files]);

    const onDrop = useCallback(async (files: any[]) => {
        // TODO: Pull from user records
        const maxSimultaneousUploads = 10;

        // TODO: Maybe block further uploads until this is done?
        // TODO: Maybe only check for new files here...

        // TODO: Check if file is already in the system
        setFiles(files);
    }, []);

    const registerSelectedImages = () => {
        Promise.allSettled(
            imageOperations
                .filter(i => {
                    return (i as ImageOperation<Query>).operation.Results?.ImageAlreadyExists === false;
                })
                .filter(i => i.bulkOperation?.selected === true)
                .map(i => {
                    const cancellablePromise = upload(i, (progress: ImageOperation<Upload>) => {
                        replaceImageOperation(progress, imageOperations, setImageOperations);
                    });
                    return cancellablePromise.promise
                        .then((i: ImageOperation<Upload>) => {
                            replaceImageOperation(i, imageOperations, setImageOperations);
                            const { id, promise, cancel } = add(
                                i,
                                i.bulkOperation?.status,
                                (added: ImageOperation<Add>) => {
                                    replaceImageOperation(added, imageOperations, setImageOperations);
                                },
                            );
                            cancelHandlers.current[id] = cancel;

                            return promise;
                        })
                        .then((added: ImageOperation<Add>) => {
                            return new Promise<ImageOperation<Add>>(async (resolve, reject) => {
                                // console.log('Upload progress');
                                replaceImageOperation(added, imageOperations, setImageOperations);
                                await save(
                                    added.operation.Results!.Image.imageID,
                                    added.bulkOperation?.meta ?? [],
                                    status => {
                                        console.log('status', status);
                                        const newAdded = structuredClone(added);
                                        newAdded.operation.status = status;
                                        replaceImageOperation(newAdded, imageOperations, setImageOperations);
                                    },
                                );

                                // TODO: I don't like this
                                const newAdded = structuredClone(added);
                                newAdded.operation.type = Type.Completed;
                                replaceImageOperation(newAdded, imageOperations, setImageOperations);

                                resolve(newAdded);
                            });
                        })
                        .then(async (added: ImageOperation<Add>) => {
                            if (onAdd) {
                                await onAdd(added.operation.Results!.Image.imageID);
                            }

                            return added;
                        });
                }),
        )
            .then((added: PromiseSettledResult<ImageOperation<Add>>[]) => {
                // console.log('added', added);
                // console.log('All Adds complete');

                added.forEach((added: PromiseSettledResult<ImageOperation<Add>>) => {
                    // console.log('added', added);
                    if (added.status === 'fulfilled') {
                        replaceImageOperation(added.value, imageOperations, setImageOperations);
                    } else {
                        // TODO: Handle error
                        console.error(added.reason);
                    }
                });

                // setImageOperations([]);
                // replaceImageOperation(added, imageOperations);
            })
            .catch((error: any) => {
                console.error(error);
            })
            .finally(() => {
                // setImageOperations([]);
            });
    };

    // console.log('imageOperations', imageOperations);
    const pendingIrcodes = imageOperations.filter(i => i.type === 'image' && i.status === RegistrationStatus.Pending);
    // console.log('pendingIrcodes', pendingIrcodes);
    const availableIrcodes = imageOperations.filter(
        i => i.type === 'image' && i.status === RegistrationStatus.Available,
    );
    // console.log('availableIrcodes', availableIrcodes);
    const addingIrcodes = imageOperations.filter(i => i.type === 'image' && i.status === RegistrationStatus.Adding);
    // console.log('addingIrcodes', addingIrcodes);
    const addedIrcodes = imageOperations.filter(i => i.type === 'image' && i.status === RegistrationStatus.Added);
    // console.log('addedIrcodes', addedIrcodes);
    const unavailableIrcodes = imageOperations.filter(
        i => i.type === 'image' && i.status === RegistrationStatus.Unavailable,
    );
    // console.log('unavailableIrcodes', unavailableIrcodes);

    const cancelButtonText =
        (addedIrcodes.length || unavailableIrcodes.length) && !availableIrcodes.length ?
            'Return to My IRCODES'
        :   'Cancel';

    useEffect(() => {
        if (isProcessing || pendingIrcodes.length || availableIrcodes.length || addingIrcodes.length) {
            enableUnloadConfirm();
        } else {
            disableUnloadConfirm();
        }
    }, [isProcessing, pendingIrcodes, availableIrcodes, addingIrcodes]);

    return (
        <Stack direction="column" spacing={4} sx={{}}>
            <FileDropArea
                dropzoneOptions={{
                    onDrop,
                    accept: !user?.internalAdmin ? ircodeAccept : adminIrcodeAccept,
                }}
                dragInvalidText={null}
                dragValidText={null}
                className="dashboard-uploader"
            >
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        p: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <i className="fa-light fa-images fa-2xl" style={{ color: Color.Purple }}></i>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '20px',
                            fontWeight: 400,
                            lineHeight: '28px',
                            letterSpacing: '0.01em',
                            textAlign: 'left',
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        Drop your images here or click to upload
                    </Typography>
                </Stack>
            </FileDropArea>

            {(progress > 0 || (imageOperations.length > 0 && pendingIrcodes.length > 0)) && (
                <Card
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                    }}
                    elevation={0}
                >
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '19px',
                                letterSpacing: '0.15em',
                                textAlign: 'left',
                                color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                textTransform: 'uppercase',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Querying {pendingIrcodes.length}/{imageOperations.length}
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                                width: '100%',
                                height: '4px',
                                borderRadius: '2px',
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: Color.Purple,
                                },
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexGrow: 1,
                                backgroundColor: 'red',
                            }}
                        ></Box>
                        <Button
                            variant="irdbText"
                            onClick={() => {
                                // cancel();
                                setImageOperations([]);
                            }}
                        >
                            Cancel<i className="fa-light fa-x" style={{ marginLeft: 4 }}></i>
                        </Button>
                    </Stack>
                </Card>
            )}

            <Pending key="pending" imageOperations={pendingIrcodes} />

            <Available
                key="available"
                imageOperations={availableIrcodes}
                replaceImageOperation={imageOperation =>
                    replaceImageOperation(imageOperation, imageOperations, setImageOperations)
                }
                removeImageOperation={imageOperation =>
                    removeImageOperation(imageOperation, imageOperations, setImageOperations)
                }
                registerSelectedImages={registerSelectedImages}
                registerDisabled={isProcessing || !!pendingIrcodes.length || !!addingIrcodes.length}
            />

            <Adding key="adding" imageOperations={addingIrcodes} />

            <Added key="added" imageOperations={addedIrcodes} />

            <Unavailable key="unavailable" imageOperations={unavailableIrcodes} />

            {imageOperations.length > 0 && (
                <Button
                    variant="irdbText"
                    onClick={() => {
                        // TODO: Need to cancel all ... getting better

                        console.log('cancelHandlers.current', cancelHandlers.current);
                        Object.keys(cancelHandlers.current).forEach(key => {
                            cancelHandlers.current[key]();
                        });
                        setImageOperations([]);
                    }}
                >
                    {cancelButtonText}
                    <i className="fa-light fa-x" style={{ marginLeft: 4 }}></i>
                </Button>
            )}
        </Stack>
    );
}
