export const MetaDataRow = {
    sx: {
        py: 1,
        px: 3,
    },
};

export const MetaDataLabel = {
    xs: 4,
    sx: {
        display: 'flex',
        alignItems: 'center',
        height: 24,
    },
};

export const MetaDataValue = {
    xs: 8,
    // break-word
    sx: {
        // TODO: Didn't works
        // Wrapped text was busting into the padding
        // boxDecorationBreak: 'clone',

        overflowWrap: 'break-word',
        // wordWrap: 'break-word',
    },
};
