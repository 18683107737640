import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import { ReactNode, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../../Color';
import MediaContext, { Image, PagedResults, TMedia } from '../../../contexts/MediaContext';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import UserContext, { TUser } from '../../../contexts/UserContext';
import useAuth from '../../../hooks/useAuth';
import Pagination from '../../general/Pagination';
import PageHeader from './PageHeader';
import usePageTitle from 'src/hooks/usePageTitle';

const formatTime = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    // Not needed, felt bad deleting:
    // const hours = date.getHours();
    // const amPm = hours >= 12 ? 'PM' : 'AM';
    // const formattedHours = ((hours + 11) % 12 + 1).toString().padStart(2, '0');
    // const formattedMinutes = date.getMinutes().toString().padStart(2, '0');
    return date.toLocaleString('default', { hour: 'numeric', minute: '2-digit', hour12: true });
};

const groupHistoryItems = (historyItems?: Image[]) => {
    if (!historyItems) {
        return {};
    }
    const groupedItems: { [key: string]: Image[] } = {};

    const now = new Date();
    const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000);
    const todayStart = new Date(now.setHours(0, 0, 0, 0));

    const yesterdayStart = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    yesterdayStart.setHours(0, 0, 0, 0);

    historyItems.forEach(item => {
        const itemdate = new Date(item.timeScanned * 1000);
        let label;

        if (itemdate > oneHourAgo) {
            label = 'PAST HOUR';
        } else if (itemdate >= todayStart) {
            label = 'EARLIER TODAY';
        } else if (itemdate >= yesterdayStart && itemdate < todayStart) {
            label = 'YESTERDAY';
        } else {
            label = itemdate.toLocaleDateString('default', { month: 'long', day: 'numeric' });
        }

        if (!groupedItems[label]) {
            groupedItems[label] = [];
        }
        groupedItems[label].push(item);
    });

    return groupedItems;
};

const copyToClipboard = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
        // alert('Copied to clipboard');
    } catch (error) {
        console.error('Failed to copy:', error);
    }
};

function EmptyHistory({ darkMode }: { darkMode: boolean }) {
    return (
        <Box
            sx={{
                height: '140px',
                width: '100%',
                marginTop: 2,
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                borderRadius: '8px',
                padding: '24px 24px 24px 32px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Grid item xs={10}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}></Stack>
                </Stack>
            </Grid>
        </Box>
    );
}

function NoHistoryDisplay({ darkMode }: { darkMode: boolean }) {
    return (
        <Box position="relative">
            <Box sx={{ filter: 'blur(4px)' }}>
                <EmptyHistory darkMode={darkMode} />
                <EmptyHistory darkMode={darkMode} />
                <EmptyHistory darkMode={darkMode} />
                <EmptyHistory darkMode={darkMode} />
                <EmptyHistory darkMode={darkMode} />
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'linear-gradient(90deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0.8) 100%)',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%)',
                    },
                }}
            >
                <Stack
                    direction="row"
                    spacing={8}
                    sx={{
                        justifyContent: 'center',
                        padding: '20px',
                    }}
                >
                    <Stack direction="column" spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '40px', lineHeight: '32px', opacity: 0.6 }}>
                            <i className="fa-light fa-clock-rotate-left" />
                        </Typography>
                    </Stack>
                    <Stack direction="column" spacing={2}>
                        <Typography
                            sx={{
                                fontFamily: 'Nocturne Serif',
                                fontSize: '24px',
                                fontWeight: 400,
                                lineHeight: '26px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                opacity: 0.6,
                            }}
                        >
                            You have no history yet
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28px',
                                letterSpacing: '0.01em',
                                textAlign: 'left',
                                opacity: 0.4,
                            }}
                        >
                            Check back after viewing some IRCODES
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}

interface HistoryGroupItemProps {
    historyItem: Image;
    darkMode: boolean;
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

function HistoryGroupItem({ historyItem, darkMode, onClick }: HistoryGroupItemProps) {
    return (
        <Box
            key={historyItem.imageID}
            sx={{
                height: { xs: 'unset', md: 100 },
                flexGrow: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: 2,
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,

                margin: 2,
            }}
            onClick={onClick}
        >
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    height: '100%',
                    alignItems: { xs: 'flex-start', md: 'center' },
                    paddingBottom: { xs: 2, md: 0 },
                    borderRadius: 2,
                }}
            >
                <Box
                    component="img"
                    sx={{
                        flexShrink: 0,
                        width: { xs: '100%', md: 140 },
                        height: { xs: 200, md: 100 },

                        borderTopLeftRadius: { xs: 0, md: '8px' },
                        borderBottomLeftRadius: { xs: 0, md: '8px' },
                        objectFit: { xs: 'contain', md: 'cover' },
                    }}
                    src={historyItem.imageUrl}
                    alt=""
                />
                <Typography
                    sx={{
                        whiteSpace: 'pre-line',
                        paddingRight: 2,
                        paddingLeft: { xs: 2, md: 2, lg: 4 },
                        py: 2,
                    }}
                    noWrap
                    flexGrow={1}
                    maxWidth="100%"
                    maxHeight="100%"
                    variant="webHistoryTitle"
                >
                    {historyItem.metaContent?.title || 'Untitled'}
                </Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    maxWidth="100%"
                    spacing={4}
                    rowGap={1}
                    sx={{
                        pl: { xs: 2, md: 0 },
                        pr: { md: 4, lg: 6 },
                        flexWrap: { xs: 'wrap', md: 'nowrap' },
                    }}
                >
                    <Typography
                        whiteSpace="nowrap"
                        color={darkMode ? Color.White : Color.PrimaryDarkGrayBlue}
                        variant="webTimeNotification"
                    >
                        {formatTime(historyItem.timeScanned)}
                    </Typography>
                    <IconButton
                        sx={{
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                        }}
                        onClick={e => {
                            e.stopPropagation();
                            copyToClipboard(`${window.location.origin}/dashboard/ircodes/${historyItem.imageID}`);
                        }}
                    >
                        <i style={{ fontSize: '12px' }} className="fa-light fa-link"></i>
                    </IconButton>
                </Stack>
            </Stack>
        </Box>
    );
}

function HistoryGroup({ label, children }: { children: ReactNode | ReactNode[]; label: string }) {
    return (
        <Box pb={1.5}>
            <Typography variant="webFont8" sx={{ px: 2 }}>
                {label.toLocaleUpperCase()}
            </Typography>
            {children}
        </Box>
    );
}

export default function History() {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { fetchHistory } = useContext(MediaContext) as TMedia;
    const { user } = useContext(UserContext) as TUser;

    const [history, setHistory] = useState<PagedResults<Image[]>>();
    const [currentPage, setCurrentPage] = useState(1);
    const historiesPerPage = 10;
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useAuth(() => {
        if (user?.userID === undefined) {
            return;
        }
        fetchHistory(currentPage)
            .then(history => {
                setHistory(history);
            })
            .catch(error => console.error(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, [user]);

    const historyCount = history?.Count ?? 1;
    const pageCount = Math.ceil(historyCount / historiesPerPage);

    const groupedHistory = useMemo(() => {
        const indexOfLastHistory = currentPage * historiesPerPage;
        const indexOfFirstHistory = indexOfLastHistory - historiesPerPage;
        const currentHistory = history?.Results.slice(indexOfFirstHistory, indexOfLastHistory);
        return groupHistoryItems(currentHistory);
    }, [history, currentPage]);

    usePageTitle('History');

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                flexGrow: 1,
                p: 4,
                height: '100%',
            }}
        >
            <Grid item xs={12}>
                <PageHeader title="History" />
            </Grid>

            <Box
                sx={{
                    px: 2,
                    pt: 4,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                    height: '100%',
                }}
            >
                {isLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress
                            sx={{
                                m: 4,
                                color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                            }}
                        />
                    </Box>
                : history === undefined ?
                    <NoHistoryDisplay darkMode={darkMode} />
                :   Object.entries(groupedHistory).map(([timeLabel, historyItems]) => (
                        <HistoryGroup key={timeLabel} label={timeLabel}>
                            {historyItems.map(historyItem => (
                                <HistoryGroupItem
                                    key={historyItem.imageID}
                                    historyItem={historyItem}
                                    darkMode={darkMode}
                                    onClick={() => {
                                        navigate(`/dashboard/ircodes/${historyItem.imageID}`);
                                    }}
                                />
                            ))}
                        </HistoryGroup>
                    ))
                }
            </Box>

            {pageCount > 1 && (
                <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={(event, page) => {
                        setCurrentPage(page);
                        window.scrollTo(0, 0);
                    }}
                />
            )}
        </Stack>
    );
}
