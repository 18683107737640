import { useContext, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Button, Stack } from '@mui/material';
import { Color } from '../../Color';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import MediaContext, { ImageOperation, TMedia } from '../../contexts/MediaContext';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';

interface Props {
    imageOperation?: ImageOperation<any> | null;
    onConfirm: (imageOperation: ImageOperation<any>) => void;
    onRetake: () => void;
    onCancel: () => void;
}

// You can make Blob -> File
// You can make HTMLImageElement -> Blob

// function getFile(imageElement: HTMLImageElement): Promise<File> {
//     return new Promise((resolve, reject) => {
//         const canvas = document.createElement('canvas');
//         canvas.width = imageElement.naturalWidth;
//         canvas.height = imageElement.naturalHeight;

//         const ctx = canvas.getContext('2d');
//         ctx?.drawImage(imageElement, 0, 0);

//         canvas.toBlob(
//             blob => {
//                 if (blob) {
//                     resolve(new File([blob], "cropped.jpeg", { type: "image/jpeg" }));
//                 } else {
//                     reject(new Error('Canvas to Blob conversion failed'));
//                 }
//             },
//             'image/jpeg',
//             0.8
//         );
//     });
// }

// TODO: Only really need an image here, maybe _then_ move it to a iamge operation
export default function Cropper({ imageOperation, onConfirm, onRetake, onCancel }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { notify } = useContext(FeedbackContext) as TFeedback;
    const { crop: cropFunction } = useContext(MediaContext) as TMedia;

    const initialCrop = imageOperation?.cropped?.cropArea ?? { x: 5, y: 5, width: 90, height: 90, unit: '%' };

    const [crop, setCrop] = useState<Crop>(initialCrop);
    const imgRef = useRef<HTMLImageElement>(null);

    const completeCrop = async () => {
        try {
            if (!imageOperation || !imageOperation!.original) {
                return;
            }

            if (!imgRef.current) {
                return;
            }

            let useCrop = crop;
            // We use percentage as the source image can be any size
            if (crop.unit === 'px') {
                const image = imgRef.current;
                useCrop = {
                    x: (100 * crop.x) / image.width,
                    y: (100 * crop.y) / image.height,
                    width: (100 * crop.width) / image.width,
                    height: (100 * crop.height) / image.height,
                    unit: '%',
                };
            }

            console.log('imageOperation', imageOperation);
            // const cropped = cropImage(imageOperation!.original!.file, useCrop);
            const newImageOperation = await cropFunction(imageOperation, useCrop);
            onConfirm(newImageOperation);
        } catch (error) {
            console.error(error);
            notify('Error cropping image', error);
        }
    };

    if (!imageOperation) {
        return null;
    }

    return (
        <Stack
            sx={{
                p: 4,
                borderRadius: 4,
                backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
            }}
        >
            <Typography
                variant="mainFont6"
                sx={{
                    mb: 2,
                }}
            >
                Adjust image
            </Typography>

            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 1,
                }}
            >
                <ReactCrop crop={crop} onChange={c => setCrop(c)}>
                    <img ref={imgRef} src={imageOperation.original?.preview} alt="" />
                </ReactCrop>
            </Box>
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    disabled={crop.width === 0 || crop.height === 0}
                    variant="irdbGradient"
                    sx={{
                        height: '56px',
                        textTransform: 'none',
                    }}
                    onClick={async () => {
                        completeCrop();
                    }}
                >
                    <i className="fa-solid fa-crop"></i>Confirm crop
                </Button>
                {/* <Button
                    variant="irdbGray"
                    sx={{
                        height: '56px',
                        textTransform: 'none',
                    }}
                    onClick={ async () => {
                        onRetake();
                    }}
                >
                    <i className="fa-solid fa-camera"></i>Retake image
                </Button> */}
                <Button
                    variant="irdbText"
                    sx={{
                        height: '56px',
                    }}
                    onClick={async () => {
                        onCancel();
                    }}
                >
                    Cancel
                </Button>
            </Stack>
        </Stack>
    );
}

export type { Props as CropperProps };
