import { Box, Button, Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { nullUndefinedOrEmpty } from '../../../../util/string';
import TextField from '../../../general/TextField';
import { imageAccept } from 'src/util/reactDropzone';
import Title from '../Title';
import UserAvatar from 'src/components/general/UserAvatar';
import useProfileState from 'src/stores/useProfileState';
import usePasswordValidation, { MIN_PASSWORD_LENGTH } from 'src/hooks/usePasswordValidator';
import { CheckCircleIcon } from '@heroicons/react/outline';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';

export function AccountTop() {
    const { info, handleRemoveAvatar, setProfileInfo: setInfo } = useProfileState();
    const {
        name,
        userName,
        email,
        phoneNumber,
        profileUrl,
        newPassword,
        existingPassword,
        confirmNewPassword,
        providerID,
    } = info;

    const onDrop = useCallback(
        (files: File[]) => {
            const file = files[0];
            setInfo({ avatarFile: file });
        },
        [setInfo],
    );
    const newPasswordValidState = usePasswordValidation(newPassword);

    function PasswordValidationListItem({ content, isChecked }: { content: string; isChecked: boolean }) {
        const transition = 'color 0.2s';
        return (
            <ListItem disableGutters disablePadding>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <CheckCircleIcon width={24} color={isChecked ? '#67cd67' : '#525157'} style={{ transition }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ style: { transition } }} primary={content} />
            </ListItem>
        );
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: imageAccept });
    return (
        <>
            <Stack direction="column" spacing={2}>
                <Title
                    sx={{
                        // marginBottom: '1rem'
                        marginY: 20,
                    }}
                >
                    Account details
                </Title>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={2}
                    sx={
                        {
                            // equal size
                        }
                    }
                >
                    <Stack
                        spacing={3}
                        sx={{
                            // flexGrow: 1,
                            width: { xs: '100%', md: '50%' },
                        }}
                    >
                        <TextField label="Name" value={name} onChange={value => setInfo({ name: value })} />
                        <TextField label="Username" value={userName} onChange={value => setInfo({ userName: value })} />
                        <TextField
                            label="Email"
                            value={email}
                            helperText={
                                providerID === 'password' || !providerID ?
                                    'Your email address is used for signing in and cannot be changed.'
                                :   `Your email address is tied to your
                                    ${providerID.charAt(0).toUpperCase()}${providerID.split('.')[0].slice(1)} account and cannot be changed.`
                            }
                            disabled
                            onChange={() => {}}
                        />
                        <TextField
                            label="Phone Number"
                            type="tel"
                            tryValidateByType
                            value={phoneNumber}
                            onChange={value => setInfo({ phoneNumber: value })}
                        />
                    </Stack>
                    <Box
                        sx={{
                            // flexGrow: 1,
                            width: { xs: '100%', md: '50%' },
                            // lineHeight: '3.5rem'
                            // marginTop: '2rem',
                            // marginBottom: '2rem'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                alignSelf: 'stretch',
                                gap: '.75rem',
                            }}
                        >
                            <Box {...getRootProps()}>
                                <input {...getInputProps()} />
                                <UserAvatar userName={userName} profileUrl={profileUrl} />
                            </Box>
                            <Box {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button variant="editProfileImageUpload">UPLOAD</Button>
                            </Box>
                            <Button
                                variant="editProfileImageUpload"
                                onClick={handleRemoveAvatar}
                                disabled={nullUndefinedOrEmpty(profileUrl)}
                            >
                                REMOVE
                            </Button>
                        </Box>
                    </Box>
                </Stack>
                {providerID === 'password' && (
                    <>
                        <Divider />
                        <Title>Change password</Title>
                        <Stack
                            spacing={2}
                            sx={{
                                // flexGrow: 1,
                                width: { xs: '100%', md: '50%' },
                            }}
                        >
                            <TextField
                                label="New password"
                                value={newPassword}
                                type="password"
                                onChange={value => setInfo({ newPassword: value })}
                            />
                            <TextField
                                label="Confirm new password"
                                value={confirmNewPassword}
                                type="password"
                                onChange={value => setInfo({ confirmNewPassword: value })}
                            />
                            <TextField
                                label="Enter current password to verify"
                                value={existingPassword}
                                type="password"
                                onChange={value => setInfo({ existingPassword: value })}
                            />
                            <Collapse timeout={600} in={!!newPassword.length} style={{ marginTop: 0 }}>
                                <List disablePadding sx={{ pt: 2 }}>
                                    <PasswordValidationListItem
                                        content={`Minimum ${MIN_PASSWORD_LENGTH} characters`}
                                        isChecked={newPasswordValidState.length}
                                    />
                                    <PasswordValidationListItem
                                        content="At least 1 lowercased letter"
                                        isChecked={newPasswordValidState.lowerCaseLetter}
                                    />
                                    <PasswordValidationListItem
                                        content="At least 1 uppercased letter"
                                        isChecked={newPasswordValidState.upperCaseLetter}
                                    />
                                    <PasswordValidationListItem
                                        content="At least 1 number"
                                        isChecked={newPasswordValidState.number}
                                    />
                                    <PasswordValidationListItem
                                        content="At least 1 special character"
                                        isChecked={newPasswordValidState.specialCharacter}
                                    />
                                </List>
                            </Collapse>
                        </Stack>
                    </>
                )}

                {/* <Divider /> */}
                {/* <Title>Two-factor authentication</Title> */}
            </Stack>
        </>
    );
}

export function AccountBottom() {
    const { onSave, onDeleteUser, info } = useProfileState();
    const newPasswordValidState = usePasswordValidation(info.newPassword);
    const { notify } = useContext(FeedbackContext) as TFeedback;

    const handleSave = async () => {
        if (info.newPassword && !Object.values(newPasswordValidState).every(Boolean)) {
            notify('Problem saving changes', 'Please enter a valid password.');
            return;
        }
        if (info.newPassword !== info.confirmNewPassword) {
            notify('Problem saving changes', 'Passwords do not match.');
            return;
        }
        await onSave();
    };
    return (
        <Stack
            direction="column"
            spacing={2}
            // sx={{
            //     // flexGrow: 1,
            //     justifyContent: 'flex-end',
            // }}
        >
            <Button
                variant="irdbGradient"
                onClick={async () => {
                    handleSave();
                }}
            >
                Save Changes
            </Button>
            <Button variant="irdbText" onClick={onDeleteUser}>
                Delete Account
            </Button>
        </Stack>
    );
}
