import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './contexts/UserContext';
import { FeedbackProvider } from './contexts/FeedbackContext';
import WebFont from 'webfontloader';
import { AdminProvider } from './contexts/AdminContext';
import { MediaProvider } from './contexts/MediaContext';
import Image from './components/image/Image';
import { Button, CssBaseline, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import { MetaProvider } from './contexts/MetaContext';
import Cookies from 'js-cookie';
import { EnvProvider } from './contexts/EnvContext';
import { FirebaseProvider } from './contexts/FirebaseContext';
import { ThemeProvider } from './contexts/ThemeContext';
import Overview from './components/admin/pages/Overview';
import Analytics from './components/admin/pages/Analytics';
import Notifications from './components/admin/pages/Notifications';
import Settings from './components/admin/pages/Settings';
import History from './components/admin/pages/History';
import WebApp from './components/WebApp';
import Dashboard from './components/admin/pages/Dashboard';
import Ircode from './components/admin/pages/Ircode';
import { EventProvider } from './contexts/EventContext';
import MyIrcode from './components/admin/pages/MyIrcode';
import Profile from './components/admin/pages/Profile';
import Dash from './components/IAW/Dash';
import DeleteIrcode from './components/IAW/DeleteIrcode';
import UserAnalytics from './components/IAW/UserAnalytics';
import AdminAnalytics from './components/IAW/AdminAnalytics';
import PageNotFound from './components/PageNotFound';
import MaintainIrcode from './components/IAW/MaintainIrcode';
import Contest from './components/admin/pages/Contest';
import ScansViews from './components/IAW/ScansViews';
import Enterprise from './components/admin/pages/Enterprise';
import EnterpriseOverview from './components/admin/pages/EnterpriseOverview';
import EnterpriseIrcode from './components/admin/pages/Enterprise/Ircode';
import { CampaignEdit } from './components/admin/pages/CampaignEdit';
import { EnterpriseProvider } from './contexts/EnterpriseContext';
import MyIrcodes from './components/admin/pages/MyIrcodes';
import Campaigns from './components/admin/pages/Campaigns';
import Trash from './components/admin/pages/Trash';

WebFont.load({
    google: {
        // TODO: Audit the weights we need
        families: ['Nunito Sans:400,500,600,700'],
    },
});

const Cookie = () => {
    const [useCookie, setUseCookie] = React.useState<string>(Cookies.get('useCookie') ?? '');

    return (
        <Grid item container direction="column" spacing={2} sx={{ p: 2 }}>
            <Grid item>
                <FormControl fullWidth>
                    <TextField
                        label="Cookie"
                        type="string"
                        value={useCookie}
                        onChange={event => {
                            setUseCookie(event.target.value);
                        }}
                        autoComplete="off"
                    />
                    <FormHelperText>Override your auth with another's</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item container direction="row" spacing={2}>
                <Grid item>
                    <Button
                        onClick={() => {
                            Cookies.set('useCookie', useCookie);
                        }}
                        color="primary"
                        size="large"
                        variant="contained"
                    >
                        Save
                    </Button>
                </Grid>

                <Grid item>
                    <Button
                        onClick={() => {
                            setUseCookie('');
                            Cookies.remove('useCookie');
                        }}
                        color="primary"
                        size="large"
                        variant="contained"
                    >
                        Clear
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <PageNotFound />,
        children: [
            {
                path: '/',
                element: <WebApp />,
            },
            {
                path: '/iaw',
                element: <Dash />,
                children: [
                    {
                        path: '/iaw',
                        element: <AdminAnalytics />,
                    },
                    {
                        path: '/iaw/useranalytics',
                        element: <UserAnalytics />,
                    },
                    {
                        path: '/iaw/deleteircode',
                        element: <DeleteIrcode />,
                    },
                    {
                        path: '/iaw/maintainircode',
                        element: <MaintainIrcode />,
                    },
                    {
                        path: '/iaw/scansviews',
                        element: <ScansViews />,
                    },
                ],
            },
            {
                path: '/enterprise',
                element: (
                    <EnterpriseProvider>
                        <Enterprise />
                    </EnterpriseProvider>
                ),
                children: [
                    {
                        // path: "/enterprise",
                        path: '',
                        element: <EnterpriseOverview />,
                    },
                    {
                        path: 'campaigns/:id',
                        element: <CampaignEdit />,
                    },
                    {
                        path: 'campaigns',
                        element: <Campaigns />,
                    },
                    {
                        path: 'campaigns/:id/ircodes/:imageId',
                        element: <EnterpriseIrcode />,
                    },
                ],
            },
            {
                path: '/dashboard',
                element: <Dashboard />,
                children: [
                    {
                        // path: "/dashboard",
                        path: '',
                        element: <Overview />,
                    },
                    {
                        path: 'myircodes',
                        element: (
                            <EnterpriseProvider>
                                <MyIrcodes />
                            </EnterpriseProvider>
                        ),
                    },
                    {
                        path: 'myircodes/:id',
                        element: <MyIrcode />,
                    },
                    {
                        path: 'ircodes/:id',
                        element: <Ircode />,
                    },
                    {
                        path: 'profile/:id',
                        element: <Profile />,
                    },
                    {
                        path: 'analytics',
                        element: <Analytics />,
                    },
                    {
                        path: 'notifications',
                        element: <Notifications />,
                    },
                    {
                        path: 'history',
                        element: <History />,
                    },
                    {
                        path: 'trash',
                        element: <Trash />,
                    },
                    {
                        path: 'settings',
                        element: <Settings />,
                    },
                ],
            },
            {
                path: 'cookie',
                element: <Cookie />,
            },
        ],
    },

    {
        path: 'contest',
        element: <Contest />,
    },

    // Example: "https://development-ircode-1a662.web.app/image/bf88af33-0cbb-4317-a963-0af89e340937",
    // TODO: Will need profile overlay
    {
        path: '/image/:id',
        element: <Image />,
        // TODO: Better "404"
        errorElement: <div></div>,
    },

    // Example: "https://development-ircode-1a662.web.app/image/info/bf88af33-0cbb-4317-a963-0af89e340937(/add)",
    // This is what the native app will open
    // TODO: Don't scroll (Not sure how to test this, the browser scrolls, but i don't want any elements to scroll within themselves)
    {
        path: '/image/info/:id/:add?',
        element: <Image />,
        // TODO: Better "404"
        errorElement: <div></div>,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    // <React.StrictMode>
    <EnvProvider>
        <EventProvider>
            <FirebaseProvider>
                <ThemeProvider>
                    <CssBaseline />
                    <FeedbackProvider>
                        <AdminProvider>
                            <UserProvider>
                                <MediaProvider>
                                    <MetaProvider>
                                        <RouterProvider router={router} />
                                    </MetaProvider>
                                </MediaProvider>
                            </UserProvider>
                        </AdminProvider>
                    </FeedbackProvider>
                </ThemeProvider>
            </FirebaseProvider>
        </EventProvider>
    </EnvProvider>,
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
