import { Box, Button, Stack, Typography } from '@mui/material';
import { useRef, useCallback, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import { useScreen } from 'usehooks-ts';
import { getSelectorsByUserAgent } from 'react-device-detect';
import { useCapturedStateWorkaround } from '../hooks/useCapturedStateWorkaround';
import usePinch from 'src/hooks/usePinch';
import useCameraZoom from 'src/hooks/useCameraZoom';
import { dataUrlToFile } from 'src/util/image';
import useVideoStream from 'src/hooks/useWebcamStream';
import useCameraPermissions from 'src/hooks/useCameraPermissions';
import isMobileJs from 'ismobilejs';

// export interface LiveViewInterface {
//     capture: () => void;
//     switchCamera?: () => void;
// }

enum Platform {
    IOS = 'iOS',
    Android = 'Android',
}

enum Camera {
    // iOS
    iOSBackTripleCamera = 'Back Triple Camera',
    iOSBackUltraWideCamera = 'Back Ultra Wide Camera',
    iOSBackDualWideCamera = 'Back Dual Wide Camera',
    iOSBackCamera = 'Back Camera',
    // We don't use these on iOS
    // iOSBackTelephotoCamera = "Back Telephoto Camera",
    // iOSFrontCamera = 'Front Camera',
    // iOSDeskViewCamera = 'Desk View Camera',

    // Android
    AndroidBackCamera = 'camera2 0, facing back',
    // AndroidFrontCamera = "camera2 1, facing front",
}

const cameraSelector = (devices: MediaDeviceInfo[], platform: Platform): string => {
    // setDeviceId(filteredDevices.find(device => device.label === Camera.Back)?.deviceId ?? {});
    const defaultCamera = '{}';
    switch (platform) {
        case Platform.IOS:
            switch (true) {
                case devices.some(device => device.label === Camera.iOSBackTripleCamera):
                    return devices.find(device => device.label === Camera.iOSBackTripleCamera)?.deviceId ?? '{}';
                case devices.some(device => device.label === Camera.iOSBackUltraWideCamera):
                    return devices.find(device => device.label === Camera.iOSBackUltraWideCamera)?.deviceId ?? '{}';
                case devices.some(device => device.label === Camera.iOSBackDualWideCamera):
                    return devices.find(device => device.label === Camera.iOSBackDualWideCamera)?.deviceId ?? '{}';
                case devices.some(device => device.label === Camera.iOSBackCamera):
                    return devices.find(device => device.label === Camera.iOSBackCamera)?.deviceId ?? '{}';
                default:
                    return defaultCamera;
            }
        case Platform.Android:
            switch (true) {
                case devices.some(device => device.label === Camera.AndroidBackCamera):
                    return devices.find(device => device.label === Camera.AndroidBackCamera)?.deviceId ?? '{}';
                default:
                    return defaultCamera;
            }
        default:
            return defaultCamera;
    }

    // devices

    // From iOS
    // if AVCaptureDevice.default(.builtInTripleCamera, for: .video, position: .back) != nil {
    //     return TripleCameraHandler(queryImageGenerator: queryImageGenerator)
    // }

    // if AVCaptureDevice.default(.builtInUltraWideCamera, for: .video, position: .back) != nil {
    //     return UltraWideCameraHandler(queryImageGenerator: queryImageGenerator)
    // }

    // if AVCaptureDevice.default(.builtInWideAngleCamera, for: .video, position: .back) != nil {
    //     return WideCameraHandler(queryImageGenerator: queryImageGenerator)
    // }
};

interface LiveViewProps {
    // ref: MutableRefObject<LiveViewInterface>;
    myRef: any;
    pause: boolean;
    onDevices: (devices: MediaDeviceInfo[]) => void;
    onPhoto: (file: File) => void;
    theme: any;
}

const MAX_ZOOM = 10;

export const LiveView = ({ /*ref,*/ myRef, pause, onDevices, onPhoto, theme }: LiveViewProps) => {
    const screen = useScreen();
    // const { isIOS } =
    const { isIOS } = getSelectorsByUserAgent(window.navigator.userAgent);
    // const [selectors, data] = useDeviceSelectors(window.navigator.userAgent)

    // TODO: Start with back camera if there is one.
    // MediaDeviceInfo.deviceId
    // const [deviceId, setDeviceId] = useState({});
    const [deviceId, setDeviceId, deviceIdRef] = useCapturedStateWorkaround({});
    const [allDevices, setAllDevices] = useState<MediaDeviceInfo[]>([]);
    const [devices, setDevices, devicesRef] = useCapturedStateWorkaround<MediaDeviceInfo[]>([]);
    const [screenshot, setScreenshot] = useState<string | null>(null);
    const { hasPermission, setHasPermission } = useCameraPermissions();
    const { zoomLevel, setZoom, isZoomSupported } = useCameraZoom({ maxZoom: MAX_ZOOM });
    const { pinchProps } = usePinch({
        enabled: isZoomSupported,
        onPinch(amount) {
            setZoom(zoomLevel.current + amount / 100);
        },
    });
    const { stream, setStream } = useVideoStream();

    useEffect(() => {
        if (pause) {
            setStream(null);
        }
    }, [pause]);

    useEffect(() => {
        async function checkPermissions() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                setHasPermission(true);
                stream.getTracks().forEach(track => track.stop());
            } catch (err) {
                setHasPermission(false);
            }

            try {
                // Check devices even if permission is denied
                const mediaDevices = await navigator.mediaDevices.enumerateDevices();
                setAllDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput'));
            } catch (err) {
                if (err instanceof Error) {
                    console.error('Error enumerating devices:', err.message);
                } else {
                    console.error('Unknown error occurred while enumerating devices.');
                }
            }
        }
        const { apple } = isMobileJs();
        if (!stream && (apple.phone || apple.tablet || apple.ipod)) {
            checkPermissions();
        }
    }, [stream]);

    const webcamRef = useRef<Webcam>();
    myRef.current.capture = useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            // console.log('imageSrc', imageSrc);
            if (imageSrc) {
                setScreenshot(imageSrc);
                onPhoto(dataUrlToFile(imageSrc));
            }
        }
    }, [webcamRef]);

    myRef.current.switchCamera = useCallback(() => {
        // if (webcamRef.current) {
        //     const imageSrc = webcamRef.current.getScreenshot();
        //     if (imageSrc) {
        //         onPhoto(dataUrlToFile(imageSrc));
        //     }
        // }
        // const currentDevices = devicesRef.current ?? [];
        // const currentDevice = currentDevices.find(device => deviceIdRef.current === device.deviceId);
        // if (currentDevice && ([Camera.Front, Camera.Back] as string[]).includes(currentDevice.label)) {
        //     const switchToDevice = currentDevice.label === Camera.Front ? Camera.Back : Camera.Front;
        //     // const newDevice = devices.find(device => device.label === switchToDevice);
        //     setDeviceId(currentDevices.find(device => device.label === switchToDevice)?.deviceId ?? {});
        // }
    }, [webcamRef]);

    const onUserMedia = async (stream: MediaStream) => {
        setStream(stream);
        if (devicesRef.current?.length === 0) {
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();
            // console.log('mediaDevices', mediaDevices);
            // setDevices(mediaDevices);

            // onDevices(mediaDevices);
            // return;

            const videoInputDevices = mediaDevices.filter(({ kind }) => kind === 'videoinput');
            console.log('videoInputDevices', videoInputDevices);
            setAllDevices(videoInputDevices);

            // return (kind === "videoinput") && ([Camera.Front, Camera.Back] as string[]).includes(label);

            // If there are no front or back cameras, just allow any.
            // if (filteredDevices.length === 0) {
            //     filteredDevices = mediaDevices.filter(({ kind }) => {
            //         return (kind === "videoinput");
            //     });
            // }
            // console.log('filteredDevices', filteredDevices);

            // setDeviceId(filteredDevices.find(device => device.label === 'Back Camera')?.deviceId ?? filteredDevices[0].deviceId);
            // Strangely, {} seems to be like, the default or something?
            setDeviceId(cameraSelector(videoInputDevices, isIOS ? Platform.IOS : Platform.Android));
            // setDeviceId(filteredDevices.find(device => device.label === Camera.Back)?.deviceId ?? {});
            // TODO: Can we just use this?
            // setDeviceId()
            setDevices(videoInputDevices);
            onDevices(videoInputDevices);

            // setDevices(
            //     mediaDevices
            //         .filter(({ kind, label }) => {
            //             return (kind === "videoinput") && ['Front Camera', 'Back Camera'].includes(label);
            //         }))

            // navigator.mediaDevices.enumerateDevices()
            //     .then(mediaDevices =>
            //         setDevices(
            //             mediaDevices
            //                 .filter(({ kind, label }) => {
            //                     return (kind === "videoinput") && ['Front Camera', 'Back Camera'].includes(label);
            //                 }))
            //     );
        }
    };

    // We can't stack filters, so we have two boxes.

    // TODO: Match orientation
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    filter: pause ? 'blur(10px);' : undefined,
                }}
            >
                {hasPermission === false && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.9)',
                            color: 'white',
                            zIndex: 2,
                        }}
                    >
                        <Stack direction="column" spacing={4} alignItems="center" sx={{ maxWidth: '90%' }}>
                            <img
                                src={require('../images/WordMarkWhite.png')}
                                alt="Logo"
                                style={{
                                    height: 40,
                                    objectFit: 'contain',
                                    marginBottom: '1rem',
                                }}
                            />
                            <Typography variant="headlineMedium" align="center">
                                Enable Camera Access
                            </Typography>
                            <Typography variant="metaDataValue" align="center">
                                To get the most out of the app, you'll need to allow camera access for IRCODE to begin
                                scanning and searching images
                            </Typography>
                            <Button
                                variant="irdbGradient"
                                onClick={async () => {
                                    try {
                                        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                                        if (stream) {
                                            setHasPermission(true);
                                        }
                                    } catch (error) {
                                        console.error(error);
                                    }
                                }}
                            >
                                Enable Camera
                            </Button>
                        </Stack>
                    </Box>
                )}
                {false && (
                    <Box
                        sx={{
                            position: 'absolute',
                            // top: 0,
                            // left: 0,
                            zIndex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '1rem',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            borderRadius: '0.5rem',
                        }}
                    >
                        {allDevices.map((device, key) => (
                            <Button
                                key={device.deviceId}
                                variant="contained"
                                color="primary"
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    marginBottom: '1rem',
                                }}
                                sx={{
                                    textTransform: 'none',
                                }}
                                onClick={() => setDeviceId(device.deviceId)}
                            >
                                {device.label || `Device ${key + 1}`}
                                {` (${device.kind})`}
                            </Button>
                        ))}
                    </Box>
                )}
                {hasPermission && (
                    <Box
                        {...pinchProps}
                        sx={{
                            // Disables default pinch-zoom behavior
                            touchAction: 'pan-x pan-y',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            bottom: '130px',
                            width: '100%',
                            filter: pause ? 'brightness(50%);' : undefined,
                            zIndex: '1',
                        }}
                    >
                        {!pause && (
                            <>
                                <Webcam
                                    // TODO: More refs not working with typescript
                                    // @ts-ignore
                                    ref={webcamRef}
                                    videoConstraints={{
                                        deviceId,
                                        facingMode: { ideal: 'environment' },
                                    }}
                                    width="100%"
                                    height="100%"
                                    screenshotFormat="image/jpeg"
                                    onUserMedia={onUserMedia}
                                    // mirrored={true}
                                    style={{
                                        opacity: pause ? 0 : 1,
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        zIndex: '1',
                                        top: '0',
                                        left: '0',
                                    }}
                                />
                                <Box
                                    sx={{
                                        width: '60vw',
                                        height: '62vw',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        filter: 'drop-shadow(0 0 .2em black)',
                                        zIndex: 2,
                                    }}
                                >
                                    <svg
                                        viewBox="-1 -1 37 38"
                                        style={{
                                            opacity: 0.5,
                                            width: '100%',
                                        }}
                                    >
                                        <path
                                            d="M0 0 9 0M26 0 35 0 35 9M35 27 35 36 26 36M9 36 0 36 0 27M0 9 0 0 2 0"
                                            stroke="#fff"
                                            strokeWidth=".33"
                                            fill="none"
                                        />
                                    </svg>
                                </Box>
                            </>
                        )}
                        {pause && screenshot && (
                            <img
                                width="100%"
                                height="100%"
                                src={screenshot}
                                style={{
                                    objectFit: 'cover',
                                    position: 'absolute',
                                    zIndex: '2',
                                    top: '0',
                                    left: '0',
                                }}
                                alt="Processing..."
                            />
                        )}
                    </Box>
                )}
            </Box>
        </>
    );
};

LiveView.defaultProps = {
    myRef: {},
    // ref: { current: {
    //     capture: () => {},
    //     switchCamera: () => {},
    // } }
};
