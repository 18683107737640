export const addOrRemove = (array: any[], value: any) => {
    const index = array.indexOf(value);

    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const merge = <T>(array1: T[], array2: T[], equalityCheck?: (a: T, b: T) => boolean): T[] => {
    equalityCheck ??= (a, b) => a === b;
    const result = [...array1];
    const arr2Len = array2.length;
    for (let i = 0; i < arr2Len; i++) {
        const item = array2[i];
        for (let j = 0; j < result.length; j++) {
            if (equalityCheck(result[j], item)) {
                break;
            } else if (j === result.length - 1) {
                result.push(item);
            }
        }
    }
    return result;
};
