import { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// import Webcam from "react-webcam";
import {
    Alert,
    Box,
    Button,
    Container,
    Dialog,
    Drawer,
    Fade,
    IconButton,
    Stack,
    SwipeableDrawer,
    Toolbar,
    Typography,
    useTheme,
} from '@mui/material';
import { useCapturedStateWorkaround } from '../hooks/useCapturedStateWorkaround';
import MediaContext, {
    TMedia,
    ImageOperation,
    Prep,
    Query,
    Foveate,
    imageOperationFromImage,
    imageFromImageOperation,
    Init,
    Image,
    RegistrationStatus,
    similarImagesFromImageOperation,
} from '../contexts/MediaContext';
import { Operation, Type } from '../contexts/Operation';
import { useNavigate } from 'react-router-dom';
import Info from './image/Info';
import { ZIndex } from '../App';
import { LiveView /*LiveViewInterface*/ } from './LiveView';
import { Color } from '../Color';
import Available from './image/Available';
import useExpiringState from '../hooks/useExpiringState';
import StackNav from './StackNav';
import IrdbDrawer from './general/Drawer';
import FAQSheet from './FAQSheet';
import Cropper from './image/CropperExtended';
import UserContext, { TUser, SignInResult, SignInStatus } from '../contexts/UserContext';
import Profile from './Profile/Profile';
import SignIn from './auth/SignIn';
import { Puller } from './general/Puller';
import EventContext, { TEvent, Event as IRCODEEvent } from '../contexts/EventContext';
import ThemeContext, { TTheme } from '../contexts/ThemeContext';
import EnvContext, { TEnv } from '../contexts/EnvContext';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import PageNotFound from './PageNotFound';
import WordMarkWhite from '../images/WordMarkWhite.svg';
import useTorch from '../hooks/useTorch';
import useCameraPermissions from 'src/hooks/useCameraPermissions';
import { metaContentForMetaType, MetaField } from 'src/contexts/MetaContext';
import isMobileJs from 'ismobilejs';
import { useOrientation } from '@uidotdev/usehooks';
import { adminIrcodeAccept, ircodeAccept } from 'src/util/reactDropzone';
import { MetaType } from '../types/MetaTypes';
import { TLink } from '../types/Link';
import usePageTitle from 'src/hooks/usePageTitle';

const WebApp = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { showWebApp } = useContext(EnvContext) as TEnv;
    const { publish } = useContext(EventContext) as TEvent;
    const {
        darkMode,
        // isMobile
    } = useContext(ThemeContext) as TTheme;
    const { user, userIsAnonymous } = useContext(UserContext) as TUser;
    const { prep, foveate, upload, query, add, load } = useContext(MediaContext) as TMedia;
    const { notify, confirm } = useContext(FeedbackContext) as TFeedback;
    const isMobile = isMobileJs().phone;
    const isLandscapeMode = useOrientation().type.includes('landscape');

    // const liveViewRef = useRef<LiveViewInterface>({
    //     capture: () => { },
    //     switchCamera: () => { },
    // });

    const fade = 500;

    const liveViewRef = useRef({});

    const [isProcessing, setIsProcessing] = useState(false);
    const [imageOperation, setImageOperation] = useState<ImageOperation<any>>();
    const [imageCreating, setImageCreating] = useState<Image>();
    const [file, setFile, fileRef] = useCapturedStateWorkaround<File>();
    const [errorMessage, setErrorMessage] = useExpiringState<string>(5000, undefined);
    const [availableDrawerHeight, setAvailableDrawerHeight] = useState<number | string>(fade);
    const [showFaq, setShowFaq] = useState(false);

    const cancelRef = useRef<() => void>();

    const drawerBleeding = 56;

    // useEffect(() => {
    //     // return () => (imagesRef.current ?? []).forEach(image => URL.revokeObjectURL(image.preview));
    //     return () => {
    //         if (imageOperation?.preview) {
    //             URL.revokeObjectURL(imageOperation?.preview);
    //         }
    //     };
    // }, []);

    useEffect(() => {
        if (!isMobile) {
            navigate('/dashboard');
        }
    }, [isMobile, navigate]);

    useEffect(() => {
        // console.log('useEffect > file');
        if (file === undefined) {
            return;
        }

        const id = crypto.randomUUID();
        const imageOperation: ImageOperation<Init> = {
            id,
            type: 'image',
            file,
            meta: [],
            operation: {
                type: Type.Init,
                status: '',
                Pending: true,
                Completed: false,
            },
            status: RegistrationStatus.Pending,
        };

        const { promise, cancel } = prep(imageOperation);
        promise
            .then(async (i: ImageOperation<Prep>): Promise<ImageOperation<Foveate>> => {
                // console.log('Image created');

                setIsProcessing(true);
                // TODO: Clear file here? setFile(undefined);
                setImageOperation(i);
                const { promise, cancel } = foveate(i);

                cancelRef.current = cancel;
                return promise;
            })
            .then(i => {
                // console.log('Image foveated, start query');

                setImageOperation(i);

                const { promise, cancel } = query(i, (progress: ImageOperation<Query>) => {
                    // console.log('Image progressed');
                    setImageOperation(progress);
                });

                cancelRef.current = cancel;
                return promise;
            })
            .then(i => {
                setImageOperation(i);

                // TODO: Handle match, no match, error
                if ((i.operation as Operation<Query>).ErrorMessage) {
                    setErrorMessage((i.operation as Operation<Query>).ErrorMessage);
                } else if ((i as ImageOperation<Query>).operation.Results?.ImageAlreadyExists === true) {
                    const image = (i as ImageOperation<Query>).operation.Results?.Image;
                    if (image) {
                        const imageLinks = metaContentForMetaType(image, MetaType.Link) as TLink;
                        const scanOnSiteLink = imageLinks?.links.find(link => link.onScanDisplay);
                        if (scanOnSiteLink) {
                            // url constructor might throw an error if the url is invalid, which we don't want the outer catch to catch and show to the user as an error message.
                            try {
                                const url = new URL(scanOnSiteLink.url);
                                confirm({
                                    title: 'External link',
                                    message: `This IRCODE would like to send you to ${url.hostname}`,
                                    yes: 'Ok',
                                    no: 'Cancel',
                                    destructive: false,
                                    onConfirmRedirect: { url: url.href, newTab: true },
                                });
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }
                } else {
                    // ???
                }
            })
            .catch(error => {
                console.error(error);
                setErrorMessage(error.message);
            })
            .finally(() => {
                setIsProcessing(false);
            });
    }, [file]);

    const onDrop = useCallback(async (files: any[]) => {
        const file = files[0];
        // TODO: Maybe block further uploads until this is done?
        // TODO: Maybe only check for new files here...

        setFile(file);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: !user?.internalAdmin ? ircodeAccept : adminIrcodeAccept,
    });

    const parentRef = useRef(null);
    const childRef = useRef(null);
    const searchingRef = useRef(null);
    const captureRef = useRef(null);
    const [parentHeight, setParentHeight] = useState<number | string>('auto');

    const [authOpen, setAuthOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState<string>();

    const { isTorchOn, isTorchSupported, toggle: toggleTorch, turnOff: turnOffTorch } = useTorch();
    const [showCropper, setShowCropper] = useState(false);
    const { hasPermission } = useCameraPermissions();

    const calculateChildHeight = (): number => {
        childRef.current = isProcessing ? searchingRef.current : captureRef.current;
        // console.log('searchingRef.current', searchingRef.current);
        // console.log('captureRef.current', captureRef.current);
        return (childRef.current as unknown as HTMLElement)?.offsetHeight;
    };

    useEffect(() => {
        setParentHeight(calculateChildHeight());
    }, [isProcessing]);

    useEffect(() => {
        if (isMobile) {
            document.body.style.overflow = 'hidden';
        }

        setTimeout(() => {
            setParentHeight(calculateChildHeight());
        }, fade + 1);
    }, []);

    const onAdd = async (operation: ImageOperation<Query>) => {
        // TODO: Not sure what the change was here
        // prep(operation!.original!.file).promise.then(r => console.log("Res: ", r))
        prep(operation).promise.then(r => console.log('Res: ', r));
        setImageOperation(operation);
        if (operation.operation.Results?.Image) {
            setImageCreating(operation.operation.Results.Image);
            return;
        }
        const image = {
            imageUrl: operation.cropped?.preview ?? operation.original?.preview ?? '',
            metaArray: [] as MetaField[],
        } as Image;
        setImageCreating(image);
    };

    const showAvailable =
        !imageCreating &&
        imageOperation !== undefined &&
        imageOperation.operation.type === Type.Query &&
        imageOperation.operation.Results?.ImageAlreadyExists === false;
    const showInfo =
        !!imageCreating ||
        (imageOperation !== undefined &&
            ((imageOperation.operation.type === Type.Query &&
                imageOperation.operation.Results?.ImageAlreadyExists === true) ||
                imageOperation.operation.type === Type.Add));
    // || (imageOperation.operation.type === Type.Load)

    const isCameraDisabled =
        authOpen || profileOpen || showFaq || showAvailable || showCropper || showInfo || isProcessing;

    const onCropperProgress = (progress: ImageOperation<any>) => {
        setImageOperation(progress);
    };
    useEffect(() => {
        if (isTorchSupported && isCameraDisabled) {
            turnOffTorch();
        }
    }, [isTorchSupported, isCameraDisabled, turnOffTorch]);

    usePageTitle('Home');

    if (showWebApp === undefined) {
        return null;
    } else if (showWebApp && isMobile) {
        if (isLandscapeMode) {
            return (
                <Stack
                    direction="column"
                    spacing={8}
                    sx={{
                        display: 'flex',
                        height: '100vh',
                        p: 2,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: Color.PrimaryDarkGrayBlue,
                    }}
                    style={{
                        height: '100dvh',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            objectFit: 'contain',
                            width: 'auto',
                            height: '56px',
                        }}
                        src={WordMarkWhite}
                        alt=""
                    />
                    <Typography
                        variant="mainFont6"
                        sx={{
                            textAlign: 'center',
                            color: Color.White,
                        }}
                    >
                        Please rotate your device to portrait orientation to use IRCODE.
                    </Typography>
                </Stack>
            );
        }

        return (
            <Box
                id="WebApp"
                sx={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                style={{
                    height: '100dvh', //device viewport height (with fallback in sx)
                }}
            >
                {/* Profile Header */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: theme.spacing(4),
                        right: theme.spacing(4),
                        zIndex: ZIndex.Menu,
                    }}
                    onClick={() => {
                        if (userIsAnonymous) {
                            setAuthOpen(true);
                        } else {
                            setProfileOpen(true);
                        }
                    }}
                >
                    <i className="fa-regular fa-circle-user fa-2xl" style={{ color: Color.White }}></i>
                </Box>

                <LiveView
                    myRef={liveViewRef}
                    pause={isCameraDisabled}
                    onDevices={devices => {
                        // setIsCameraSwapEnabled(devices.length > 1);
                    }}
                    onPhoto={file => {
                        setFile(file);
                    }}
                    theme={theme}
                />

                {isProcessing && imageOperation && (
                    <Box
                        sx={{
                            textAlign: 'center',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        }}
                    >
                        <img
                            src={imageOperation.cropped?.preview ?? imageOperation.original?.preview}
                            style={{
                                objectFit: 'cover',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                filter: 'blur(.7em) brightness(.8)',
                                zIndex: 1,
                            }}
                            alt=""
                        />
                        <img
                            src={imageOperation.cropped?.preview ?? imageOperation.original?.preview}
                            style={{
                                objectFit: 'contain',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 2,
                            }}
                            alt=""
                        />
                    </Box>
                )}

                {true && (
                    // TODO: Drop Container
                    <Container
                        ref={parentRef}
                        sx={{
                            position: 'absolute',
                            height: parentHeight || 130,
                            // rob: can we get this working/?
                            // background-image 500ms;
                            transition: `height ${fade}ms;`,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: Color.PrimaryDarkGrayBlue,
                            zIndex: 2, // above LiveView
                            overflow: 'hidden',

                            // TODO: This shows too quickly
                            // backgroundSize: 'cover',

                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                        }}
                    >
                        {/* Capture */}
                        <Fade in={!isProcessing} timeout={fade}>
                            <Container
                                ref={captureRef}
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    height: '130px',
                                    overflow: 'hidden',

                                    px: 2,
                                    py: 4,
                                }}
                            >
                                {/* TODO: Why am I still using Toolbar? */}
                                <Toolbar
                                    sx={{
                                        flexGrow: 1,
                                        justifyContent: 'space-around',
                                    }}
                                >
                                    <span {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <IconButton
                                            style={{
                                                color: Color.PrimaryLavender,
                                                height: '2.65rem',
                                                width: '2.65rem',
                                                fontSize: '1.5rem',
                                            }}
                                        >
                                            <i className="fa-regular fa-images"></i>
                                        </IconButton>
                                    </span>
                                    <Button
                                        disabled={!hasPermission}
                                        style={{
                                            height: 64,
                                            width: 64,
                                            opacity: hasPermission ? 1 : 0.5,
                                            transition: 'opacity 0.3s',
                                        }}
                                        onClick={() => {
                                            // TODO: Get typing to work
                                            // @ts-ignore
                                            liveViewRef.current?.capture();
                                        }}
                                    >
                                        <img
                                            src={require('../images/LogoMark.png')}
                                            alt="Logo"
                                            style={{
                                                width: 64,
                                                height: 64,
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </Button>
                                    <IconButton
                                        disabled={!isTorchSupported}
                                        style={{
                                            height: '2.65rem',
                                            width: '2.65rem',
                                            userSelect: 'none',
                                            ...(isTorchOn && { color: Color.PrimaryLavender }),
                                        }}
                                        onClick={toggleTorch}
                                    >
                                        <img
                                            hidden={!isTorchSupported}
                                            src={require('../images/flashLight.png')}
                                            alt="flash"
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'contain',
                                                ...(!isTorchOn && { filter: 'grayscale(100%)' }),
                                            }}
                                        />
                                    </IconButton>
                                </Toolbar>
                            </Container>
                        </Fade>

                        {/* Searching Animation */}
                        <Fade in={isProcessing} timeout={fade}>
                            <Box
                                ref={searchingRef}
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,

                                    // I tried to only make the bottom "as big as it needed to be", but it proved too finicky
                                    height: 313,

                                    overflow: 'hidden',
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 16,
                                    backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    spacing={2}
                                    sx={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        top: 0,
                                        left: 0,
                                        zIndex: 2,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="mainFont6"
                                        sx={{
                                            my: 8,
                                            textAlign: 'center',
                                            color: Color.Purple,
                                        }}
                                    >
                                        Searching...
                                    </Typography>
                                    <Button
                                        variant="irdbText"
                                        // color="primary"
                                        sx={{
                                            my: 2,
                                            alignSelf: 'center',
                                            color: Color.White,
                                        }}
                                        onClick={() => {
                                            cancelRef.current?.();
                                        }}
                                    >
                                        Cancel<i className="fa-solid fa-xmark" style={{ marginLeft: 8 }}></i>
                                    </Button>
                                </Stack>
                                <Box
                                    component="img"
                                    src={require('../images/SearchAnimation.gif')}
                                    sx={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        top: 0,
                                        left: 0,
                                        zIndex: 1,
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>
                        </Fade>
                    </Container>
                )}

                {/* Auth */}
                <Dialog
                    fullScreen
                    // transitionDuration={transitionDuration}
                    open={authOpen}
                    onClose={(event: SyntheticEvent<{}, Event>) => {
                        console.log('onClose');
                        setAuthOpen(false);
                    }}
                    style={{
                        // This only works on `style`, not `sx`
                        zIndex: ZIndex.AuthSheet,
                    }}
                    sx={{
                        '& .MuiDialog-paper': {
                            backgroundColor: Color.Black,
                            backgroundImage: {
                                xs: 'none',
                                sm: 'url(/images/signInBackground.svg)',
                            },
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'top right',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    }}
                >
                    <SignIn
                        onClose={() => setAuthOpen(false)}
                        onComplete={(result: SignInResult) => {
                            // onClose(result);
                            switch (result.status) {
                                case SignInStatus.Success:
                                    setAuthOpen(false);
                                    // closeMenu();
                                    break;
                                case SignInStatus.SignUp:
                                    break;
                                default:
                                    break;
                            }
                        }}
                    />
                </Dialog>

                {/* Profile */}
                <SwipeableDrawer
                    anchor="bottom"
                    open={profileOpen}
                    onOpen={() => setProfileOpen(true)}
                    onClose={() => setProfileOpen(false)}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    // Not called 🙃
                    // onScroll={ () => {
                    // } }
                    onScrollCapture={element => {
                        // console.log('element', element);
                        // TODO: Can we really not just limit scrolling callbacks?
                        if ((element.target as HTMLElement).id === 'navTiles') {
                            return;
                        }

                        const { scrollTop, scrollHeight, clientHeight } = element.target as HTMLElement;
                        if (scrollTop > 0 && scrollHeight - scrollTop === clientHeight) {
                            console.log('Scrolled to bottom');
                            publish(IRCODEEvent.ProfileScrolledToBottom);
                        }
                    }}
                    style={{
                        zIndex: ZIndex.ProfileSheet,
                    }}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: '100%',
                            height: '100%',
                            overflow: 'scroll',
                            backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                            backgroundImage: 'none',
                        },
                    }}
                >
                    <Puller />
                    <Profile
                        selectedImageId={selectedImageId}
                        setSelectedImageId={setSelectedImageId}
                        toggle={() => setProfileOpen(false)}
                        setAuthOpen={setAuthOpen}
                    />
                </SwipeableDrawer>

                {/* Available */}
                <SwipeableDrawer
                    anchor="bottom"
                    open={showAvailable}
                    disableSwipeToOpen={true}
                    ModalProps={{
                        // This allows the changing of the image to be observed
                        keepMounted: false,
                    }}
                    onClose={function (event: SyntheticEvent<{}, Event>): void {
                        setImageOperation(undefined);
                    }}
                    onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                    sx={{
                        '& .MuiDrawer-paper': {
                            transition: `height ${fade}ms`,
                            height: availableDrawerHeight,
                            // The default has a background image that is actually a gradient and prevented us from having rounded corners
                            backgroundImage: 'none',
                            backgroundColor: 'transparent',
                        },
                        '& .MuiDrawer-paper > .MuiBox-root, & .stack': {
                            height: '100%',
                        },
                    }}
                    style={{
                        zIndex: ZIndex.BottomSheet,
                    }}
                >
                    <IrdbDrawer>
                        <StackNav
                            initialComponent={(pushToStack, popFromStack) => {
                                return (
                                    <Available
                                        pushToStack={pushToStack}
                                        popFromStack={popFromStack}
                                        imageOperation={imageOperation}
                                        onLayout={(height: number) => {
                                            // console.log('onLayout', height);
                                            // setAvailableDrawerHeight(height);
                                            setAvailableDrawerHeight('100%');
                                        }}
                                        onCropper={() => {
                                            setImageOperation(imageOperation);
                                            setShowCropper(true);
                                        }}
                                        onAdd={onAdd}
                                        onFaq={() => {
                                            setShowFaq(true);
                                        }}
                                        onCancel={() => {
                                            setImageOperation(undefined);

                                            // trying to stop jumpiness
                                            // setAvailableDrawerHeight(500);
                                        }}
                                    />
                                );
                            }}
                        />
                    </IrdbDrawer>
                </SwipeableDrawer>

                {/* Cropper */}
                <Drawer
                    anchor="bottom"
                    open={showCropper}
                    sx={{
                        zIndex: ZIndex.BottomSheet,
                    }}
                    // open={true}
                    // disableSwipeToOpen={true}
                    // ModalProps={{
                    //     // This allows the changing of the image to be observed
                    //     keepMounted: false,
                    // }}
                    // onClose={function (event: SyntheticEvent<{}, Event>): void {
                    //     setImageOperation(undefined);
                    // }}
                    // onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                    // sx={{
                    //     '& .MuiDrawer-paper': {
                    //         transition: 'height 500ms',
                    //         height: availableDrawerHeight,
                    //     },
                    // }}
                    // style={{
                    //     zIndex: ZIndex.BottomSheet,
                    // }}
                >
                    <Cropper
                        // url={imageOperation?.downloadUrl ?? ''}
                        imageOperation={imageOperation}
                        onProgress={onCropperProgress}
                        onSuccess={async (i: ImageOperation<any>) => {
                            setShowCropper(false);
                            onCropperProgress(i);
                        }}
                        onRetake={() => {
                            setImageOperation(undefined);
                        }}
                        onCancel={() => {
                            setShowCropper(false);
                        }}
                        handleViewExisting={async imageOperation => {
                            setImageOperation(imageOperation);
                            setShowCropper(false);
                        }}
                    />
                </Drawer>

                {/* Info */}
                <SwipeableDrawer
                    anchor="bottom"
                    open={showInfo}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={true}
                    ModalProps={{
                        keepMounted: false,
                    }}
                    onClose={function (event: SyntheticEvent<{}, Event>): void {
                        setImageOperation(undefined);
                    }}
                    onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                    style={{
                        zIndex: ZIndex.BottomSheet,
                        // backgroundColor: 'red',
                    }}
                    // sx={{
                    //     '& .MuiDrawer-paper': {
                    //         width: "100%",
                    //         height:"100%",
                    //         overflow: 'scroll',
                    //         backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                    //     },
                    // }}
                >
                    <IrdbDrawer>
                        <StackNav
                            initialComponent={(pushToStack, popFromStack) => {
                                return (
                                    <Info
                                        pushToStack={pushToStack}
                                        popFromStack={popFromStack}
                                        // imageOperation={imageOperation}
                                        image={
                                            imageCreating || (imageOperation && imageFromImageOperation(imageOperation))
                                        }
                                        similarImages={
                                            imageOperation && similarImagesFromImageOperation(imageOperation)
                                        }
                                        isAdd={!!imageCreating}
                                        handleAdd={async () => {
                                            if (!imageOperation) return null;
                                            try {
                                                const { promise: uPromise } = await upload(
                                                    imageOperation,
                                                    _progress => {},
                                                );
                                                const uploaded = await uPromise;
                                                const { promise: aPromise } = await add(uploaded, 'publish');
                                                const added = await aPromise;
                                                if (
                                                    added.operation.Results?.ImageAdded &&
                                                    added.operation.Results.Image
                                                ) {
                                                    return added.operation.Results.Image.imageID;
                                                }
                                            } catch (error) {
                                                console.error(error);
                                            }
                                            return null;
                                        }}
                                        onSaved={async (id: string) => {
                                            const image = await load(id);
                                            setImageOperation(await imageOperationFromImage(image));
                                            setImageCreating(undefined);
                                            setSelectedImageId(id);
                                            setProfileOpen(true);
                                        }}
                                        onClose={() => {
                                            setImageOperation(undefined);
                                            setImageCreating(undefined);
                                        }}
                                    />
                                );
                            }}
                        />
                    </IrdbDrawer>
                </SwipeableDrawer>

                {/* FAQ */}
                {false && (
                    <SwipeableDrawer
                        anchor="bottom"
                        open={showFaq}
                        onClose={() => setShowFaq(false)}
                        onOpen={() => setShowFaq(true)}
                        // sx={{
                        //     backgroundColor: Color.White,
                        //     // TODO: Give FAQ a z-index
                        //     zIndex: ZIndex.ProfileSubMenu
                        // }}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: '100%',
                                height: '100%',
                                overflow: 'scroll',
                                backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                            },
                        }}
                        style={{
                            // zIndex doesn't work in sx
                            zIndex: ZIndex.FAQ,
                        }}
                    >
                        <FAQSheet
                            handleFaqClose={() => {
                                setShowFaq(false);
                            }}
                        />
                    </SwipeableDrawer>
                )}

                {errorMessage && (
                    <Container sx={{ position: 'absolute', zIndex: 1000 }}>
                        <Alert severity="error" sx={{ zIndex: 1000 }}>
                            {errorMessage}
                        </Alert>
                    </Container>
                )}
            </Box>
        );
    } else if (showWebApp && !isMobile) {
        // TODO: Currently not showing web app on desktop
        return (
            <Stack
                direction="column"
                spacing={8}
                sx={{
                    display: 'flex',
                    height: '100vh',
                    p: 2,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: Color.PrimaryDarkGrayBlue,
                }}
                style={{
                    height: '100dvh',
                }}
            >
                <Box
                    component="img"
                    sx={{
                        objectFit: 'contain',
                        width: 'auto',
                        height: '56px',
                    }}
                    src={WordMarkWhite}
                    alt=""
                />
                <Typography
                    variant="mainFont6"
                    sx={{
                        textAlign: 'center',
                        color: Color.White,
                    }}
                >
                    Please wait while we redirect you to the IRCODE dashboard.
                </Typography>
            </Stack>
        );
    } else {
        return <PageNotFound />;
    }
};

export default WebApp;
