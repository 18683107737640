import { Box, Dialog, Divider, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import FeedbackContext, { TFeedback } from '../../../../contexts/FeedbackContext';
import { metaFieldForMetaType, metaIncludes, MetaField, addOrMergeCustom } from '../../../../contexts/MetaContext';
import { MetaType, MetaContent } from '../../../../types/MetaTypes';
import { CardTypeForm } from './CardType';
import { CustomForm, Custom } from './Custom';
import { Description, DescriptionForm } from './Description';
import { Link, LinkForm } from './Link';
import { TagsForm, Tags } from './Tags';
import { Title, TitleForm } from './Title';
import AddCustom from './AddCustom';
import GrayBoxButton from '../../../general/GrayBoxButton';
import { ProductLinkForm } from './ProductLink';

export default function GeneralCardStack({
    meta,
    isEditing,
    editingTypes = [
        MetaType.CardType,
        MetaType.Title,
        MetaType.Link,
        MetaType.ProductLink,
        MetaType.Description,
        MetaType.Tags,
        MetaType.Custom,
    ],
    copyOnConfirm,
    onChange,
}: {
    meta: MetaField[];
    isEditing: boolean;
    editingTypes?: MetaType[];
    copyOnConfirm: (metaType: MetaType, metaContent: MetaContent) => void;
    onChange: (metaType: MetaType, metaContent: MetaContent) => void;
}) {
    const { confirm } = useContext(FeedbackContext) as TFeedback;

    const [showAddCustom, setShowAddCustom] = useState(false);

    if (isEditing) {
        return (
            <>
                <Stack direction="column" spacing={2}>
                    {editingTypes.includes(MetaType.CardType) && (
                        <CardTypeForm
                            metaField={metaFieldForMetaType(meta, MetaType.CardType)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.CardType, metaContent);
                            }}
                            onChange={async (metaContent: MetaContent) => {
                                if (
                                    await confirm({
                                        title: 'Are your sure?',
                                        message: 'Some data may be lost',
                                        yes: 'Switch Templates',
                                        no: 'Cancel',
                                    })
                                ) {
                                    onChange(MetaType.CardType, metaContent);
                                }
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.Title) && (
                        <TitleForm
                            metaField={metaFieldForMetaType(meta, MetaType.Title)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.Title, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.Title, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.Link) && (
                        <LinkForm
                            metaField={metaFieldForMetaType(meta, MetaType.Link)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.Link, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.Link, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.ProductLink) && (
                        <ProductLinkForm
                            metaField={metaFieldForMetaType(meta, MetaType.ProductLink)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.ProductLink, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.ProductLink, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.Description) && (
                        <DescriptionForm
                            metaField={metaFieldForMetaType(meta, MetaType.Description)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.Description, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.Description, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.Tags) && (
                        <TagsForm
                            metaField={metaFieldForMetaType(meta, MetaType.Tags)}
                            onCopy={async metaContent => {
                                copyOnConfirm(MetaType.Tags, metaContent);
                            }}
                            onChange={(metaContent: MetaContent) => {
                                onChange(MetaType.Tags, metaContent);
                            }}
                        />
                    )}
                    {editingTypes.includes(MetaType.Custom) && (
                        <>
                            <CustomForm
                                metaField={metaFieldForMetaType(meta, MetaType.Custom)}
                                onCopy={async metaContent => {
                                    copyOnConfirm(MetaType.Custom, metaContent);
                                }}
                                onChange={(metaContent: MetaContent) => {
                                    onChange(MetaType.Custom, metaContent);
                                }}
                            />
                            <GrayBoxButton
                                onClick={() => {
                                    setShowAddCustom(true);
                                }}
                            >
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <i className="fa-solid fa-plus" style={{}}></i>
                                    <Typography
                                        sx={{
                                            display: 'inline-block',
                                            ml: 0.5,

                                            fontFamily: 'Nunito Sans',
                                            fontSize: 20,
                                            fontWeight: 400,
                                            lineHeight: '28px',
                                            letterSpacing: '0.01em',
                                        }}
                                    >
                                        Add custom field
                                    </Typography>
                                </Box>
                            </GrayBoxButton>
                        </>
                    )}
                </Stack>
                <Dialog
                    fullScreen
                    open={showAddCustom}
                    onClose={() => setShowAddCustom(false)}
                    sx={{
                        '& .MuiDialog-paper': {
                            // TODO: Color.ts
                            backgroundColor: '#00000AAA',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            // TODO: Color.ts
                            border: '1px solid #A5A5D1',
                            borderRadius: 3,
                            overflow: 'hidden',
                        }}
                    >
                        <AddCustom
                            onSelection={(field: string) => {
                                const customMetaField = metaFieldForMetaType(meta, MetaType.Custom);
                                const newMetaField = addOrMergeCustom(customMetaField, field);
                                onChange(MetaType.Custom, newMetaField.metaContent);
                                setShowAddCustom(false);
                            }}
                            onClose={() => {
                                setShowAddCustom(false);
                            }}
                        />
                    </Box>
                </Dialog>
            </>
        );
    } else {
        return (
            <Stack spacing={2} divider={<Divider />}>
                <Stack
                // In WebApp, the meta components have the spacing. Maybe fix.
                // spacing={2}
                >
                    <Title metaField={metaFieldForMetaType(meta, MetaType.Title)} />
                    <Link metaField={metaFieldForMetaType(meta, MetaType.Link)} />
                </Stack>
                {metaIncludes(meta, [MetaType.Description, MetaType.Tags, MetaType.Custom]) && (
                    <Stack
                    // In WebApp, the meta components have the spacing. Maybe fix.
                    // spacing={2}
                    >
                        <Description metaField={metaFieldForMetaType(meta, MetaType.Description)} />
                        <Tags metaField={metaFieldForMetaType(meta, MetaType.Tags)} />
                        <Custom metaField={metaFieldForMetaType(meta, MetaType.Custom)} />
                    </Stack>
                )}
            </Stack>
        );
    }
}
