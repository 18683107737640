import { Stack, Typography, Box } from '@mui/material';
import { ImageOperation, Query, imageFromImageOperation } from '../../../contexts/MediaContext';
import { Color } from '../../../Color';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { MetaType, TTitle } from '../../../types/MetaTypes';

interface Props {
    imageOperations: ImageOperation<Query>[];
}

export default function Added({ imageOperations }: Props) {
    const navigate = useNavigate();
    const { darkMode } = useContext(ThemeContext) as TTheme;

    if (imageOperations.length === 0) {
        return null;
    } else {
        return (
            <Stack
                direction="column"
                spacing={2}
                // alignItems="flex-start"
                // justifyContent="flex-start"
                sx={
                    {
                        // borderRadius: 2,
                    }
                }
            >
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        // alignContent: 'center',
                        // justifyItems: 'center',
                        alignItems: 'center',
                        p: 4,
                        borderRadius: 2,
                        // backgroundColor: 'linear-gradient(292.79deg, #8C9A09 9.08%, #128C34 92.12%)',
                        background: 'linear-gradient(292.79deg, #8C9A09 9.08%, #128C34 92.12%)',

                        color: Color.White,
                        // backgroundColor: "#B0DB48",
                    }}
                >
                    <i className="fa-solid fa-check-double fa-2xl" style={{ marginRight: '16px' }}></i>
                    <Typography
                        sx={{
                            fontFamily: 'Nocturne Serif',
                            fontSize: '24px',
                            fontWeight: 400,
                            lineHeight: '26px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                        }}
                    >
                        IRCODE{imageOperations.length === 1 ? '' : 'S'} published!
                    </Typography>
                </Stack>
                {imageOperations.map(imageOperation => {
                    const imgSrc = imageOperation.cropped?.preview ?? imageOperation.original?.preview;
                    const image = imageFromImageOperation(imageOperation);
                    const isUploaded = imageOperation.operation.Completed; //  === false;

                    const titleMeta = imageOperation.bulkOperation?.meta.find(meta => meta.metaType === MetaType.Title);
                    const title = (titleMeta?.metaContent as TTitle).title ?? 'Untitled';

                    return (
                        <Stack
                            key={imageOperation.id}
                            direction="row"
                            spacing={0}
                            sx={{
                                borderRadius: 2,
                                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                            }}
                            onClick={() => {
                                navigate(`/dashboard/ircodes/${imageOperation.operation.Results?.Image?.imageID}`);
                            }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    flexShrink: 0,
                                    flexBasis: 310,
                                    width: 310,
                                    height: 220,
                                    borderTopLeftRadius: { xs: '8px', sm: '8px' },
                                    borderTopRightRadius: { xs: '8px', sm: 0 },
                                    borderBottomLeftRadius: { xs: 0, sm: '8px' },
                                    borderBottomRightRadius: { xs: 0, sm: 0 },
                                    // objectFit: 'cover',
                                    objectFit: 'contain',
                                    overflow: 'hidden',
                                }}
                                src={imgSrc}
                                alt=""
                            />
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{
                                    p: 2,
                                }}
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{
                                        flexGrow: 1,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nocturne Serif',
                                            fontSize: '32px',
                                            fontWeight: 600,
                                            lineHeight: '40px',
                                            letterSpacing: '-0.03em',
                                            textAlign: 'left',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {title}
                                    </Typography>
                                    <i className="fa-regular fa-arrow-right fa-2xl"></i>
                                </Stack>
                                {false && (
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                            alignItems: 'center',
                                        }}
                                    >
                                        <i className="fa-regular fa-badge-check"></i>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Nunito Sans',
                                                fontSize: '20px',
                                                fontWeight: 400,
                                                lineHeight: '36px',
                                                letterSpacing: '0.01em',
                                                textAlign: 'left',
                                            }}
                                        >
                                            Secure and verify is active
                                        </Typography>
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    );
                })}
            </Stack>
        );
    }
}
