export const nullUndefinedOrEmpty = (s: string | null | undefined): boolean => {
    if (s === null || s === undefined) {
        return true;
    }

    return s.trim().length === 0;
};

export const containsAtLeastOneUppercaseLetterRegex = /[A-Z]/;
export const containsAtLeastOneLowercaseLetterRegex = /[a-z]/;
export const containsAtLeastOneLetterRegex = /[a-zA-Z]/;
export const containsAtLeastOneDigitRegex = /[0-9]/;
export const containsAtLeastOneSpecialCharacterRegex = /[^a-zA-Z0-9]/;
export const containsOnlyLettersAndSpacesRegex = /^[a-zA-Z\s]*$/;
export const usernameRegex = /^[a-zA-Z0-9._-]{5,15}$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function validateName(name: string): boolean {
    return containsOnlyLettersAndSpacesRegex.test(name);
}

export function validateUsername(username: string): boolean {
    return usernameRegex.test(username);
}

export function validateEmail(email: string): boolean {
    return emailRegex.test(email);
}

export function camelCaseToWords(s: string) {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}

declare global {
    interface StringConstructor {
        fromError: (error: any) => string;
    }
}

String.fromError = (error: any) => {
    return String(error).replaceAll('Error: ', '');
};
