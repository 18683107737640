import { useState } from 'react';

const useExpiringState = <T>(
    expiry: number,
    defaultValue: T | undefined,
): [T | undefined, (val: T | undefined) => void] => {
    const [value, setValue] = useState(defaultValue);
    const [handle, setHandle] = useState<NodeJS.Timeout | undefined>(undefined);

    const set = (v: T | undefined) => {
        setValue(v);

        if (handle) {
            clearTimeout(handle);
            setHandle(undefined);
        }

        const h = setTimeout(() => {
            setValue(undefined);
            setHandle(h);
        }, expiry);
        setHandle(h);
    };

    return [value, set];
};

export default useExpiringState;
