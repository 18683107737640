import { Box, Button, CircularProgress, Dialog, Stack, Typography } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import UserContext, { SignInResult, SignInStatus, TUser } from '../../contexts/UserContext';
import { Color } from '../../Color';
import Sidebar from './Sidebar';
import SignIn from '../auth/SignIn';
import usePageTitle from 'src/hooks/usePageTitle';

export interface SignInDialogProps {
    open: boolean;
    transitionDuration: number;
    onClose: (open: boolean) => void;
}

function SignInDialog({ open, transitionDuration, onClose }: SignInDialogProps) {
    const handleClose = () => {
        onClose(false);
    };

    return (
        <Dialog
            fullScreen
            transitionDuration={transitionDuration}
            onClose={handleClose}
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    backgroundColor: '#00000A',
                    backgroundImage: {
                        xs: 'none',
                        sm: 'url(/images/signInBackground.svg)',
                    },
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top right',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: { xs: '100vw', sm: '500px' },
                    height: { xs: '100vh', sm: '60vh' },
                    border: { xs: 'none', sm: '1px solid #A5A5D1' },
                    borderRadius: { xs: 'none', sm: 3 },
                    overflow: 'hidden',
                }}
            >
                <SignIn
                    onComplete={(result: SignInResult) => {
                        console.log('result', result);
                        switch (result.status) {
                            case SignInStatus.Success:
                                handleClose();
                                break;
                            case SignInStatus.SignUp:
                                // Handle signup case, if necessary
                                break;
                            default:
                                handleClose();
                                break;
                        }
                    }}
                />
            </Box>
        </Dialog>
    );
}

export default function Dash() {
    const { user, signOut, userIsAnonymous } = useContext(UserContext) as TUser;

    const env = process.env.REACT_APP_ENV;

    const navigate = useNavigate();

    const [isSignInDialogOpen, setIsSignInDialogOpen] = useState(false);

    const adminType: string =
        user?.internalAdmin === 1 ? 'Super Admin'
        : user?.internalAdmin === 2 ? 'Admin'
        : 'Support';

    const handleSignOut = async () => {
        try {
            await signOut();
            document.location.href = '/dashboard';
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    useEffect(() => {
        if (user === undefined) {
            return;
        }
        if (user) {
            user.internalAdmin > 0 ? navigate('/iaw') : navigate('/dashboard');
        }
        if (userIsAnonymous) {
            setIsSignInDialogOpen(true);
        } else {
            setIsSignInDialogOpen(false);
        }
    }, [user]);

    usePageTitle('Internal Admin Dashboard');

    return (
        <>
            <Stack direction="column" sx={{ overflowX: 'hidden' }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0}>
                    <Sidebar />
                    <Box
                        sx={{
                            width: { xs: '100%', sm: 'calc(100vw - 280px)' },
                        }}
                    >
                        <Box sx={{ p: 4 }}>
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    paddingRight: 4,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    sx={{
                                        alignItems: 'flex-end',
                                        width: 'auto',
                                        color: Color.White,
                                        backgroundColor: Color.DarkModeBlack,
                                        border: '1px solid',
                                        borderRadius: 4,
                                        p: 2,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito Sans',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            lineHeight: '24px',
                                            letterSpacing: '0em',
                                        }}
                                    >
                                        {adminType}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito Sans',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            lineHeight: '24px',
                                            letterSpacing: '0em',
                                        }}
                                    >
                                        Env : {env}
                                    </Typography>
                                    <Button
                                        sx={{
                                            fontFamily: 'Nunito Sans',
                                            fontSize: '10px',
                                            fontWeight: 400,
                                            lineHeight: '16px',
                                            letterSpacing: '0.15em',
                                            textTransform: 'uppercase',
                                            color: 'red',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: Color.PrimaryLavender,
                                            },
                                        }}
                                        onClick={handleSignOut}
                                    >
                                        Signout
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                        <Outlet />
                    </Box>
                </Stack>
                <SignInDialog open={isSignInDialogOpen} transitionDuration={0} onClose={() => {}} />
                <Dialog
                    fullScreen
                    transitionDuration={user ? 1000 / 3 : 0}
                    open={user === undefined}
                    // open={true}
                    sx={{
                        '& .MuiDialog-paper': {
                            backgroundColor: Color.PrimaryDarkGrayBlue,
                            // background: #12121A;
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    }}
                >
                    <CircularProgress
                        sx={{
                            // color: 'primary.main',
                            color: 'white',
                        }}
                    />
                </Dialog>
            </Stack>
        </>
    );
}
