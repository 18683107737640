import { FC, createContext, useContext } from 'react';
import { Image, PagedResults } from './MediaContext';
import {
    CardType,
    MetaContent,
    MetaType,
    TArtistName,
    TCardType,
    TCustom,
    TDescription,
    TDisplayLocation,
    TEmail,
    TGalleryName,
    TMedium,
    TPhone,
    TPrice,
    TProvenance,
    TSize,
    TTags,
    TTitle,
    TYear,
} from '../types/MetaTypes';
import { Semaphore } from 'async-mutex';
import { nullUndefinedOrEmpty } from '../util/string';
import useApi, { Method } from 'src/hooks/useApi';
import { TLink } from '../types/Link';
import { TProductLink } from '../types/ProductLink';
import { ImageSortByField, ImageSortOrderField } from '../types/Image';
import { Quality, shrink } from 'src/util/image';
import FirebaseContext, { TFirebase } from './FirebaseContext';

export interface MetaField {
    key: string;
    metaID: number;
    metaType: MetaType;
    metaContent: MetaContent;
}

export interface MetaData {
    Meta: MetaField[];
    EditingAllowed: boolean;
}

export const EmptyMetaData: MetaData = {
    Meta: [],
    EditingAllowed: false,
};

// TODO: Can I make this global? Result<T>
export interface Result<T> {
    Completed: boolean;
    Results: T;
    Environment: string;
    GCFVersion: string;
}

export interface TMeta {
    fetch(imageId: string, filterEmpty?: boolean): Promise<MetaData>;
    save(imageId: string, data: MetaField[], onProgress?: (status: string) => void): Promise<void>;
    search(query: string, owner?: boolean): Promise<PagedResults<Image[]>>;
}

// TODO: Genericize
// export function metaFieldForMetaType(meta: MetaField[], metaType: MetaType): MetaField | undefined {
export function metaFieldForMetaType(meta: MetaField[], metaType: MetaType): MetaField | undefined {
    const metaField = meta.find(m => m.metaType === metaType);
    if (metaField === undefined && metaType === MetaType.CardType) {
        // CardType is required, General is the default
        return {
            key: crypto.randomUUID(),
            metaID: 0,
            metaType: MetaType.CardType,
            metaContent: {
                cardType: CardType.General,
            } as TCardType,
        };
    }

    return metaField;
}

// TODO: Genericize
export function metaContentForMetaType(image: Image, metaType: MetaType): MetaContent | undefined {
    const meta = image.metaArray?.find(meta => meta.metaType === metaType);
    if (meta === undefined && metaType === MetaType.CardType) {
        // CardType is required, General is the default
        return {
            cardType: CardType.General,
        } as TCardType;
    }

    return meta?.metaContent;
}

export function indexForMetaType(meta: MetaField[], metaType: MetaType): number {
    return meta.findIndex(meta => meta.metaType === metaType);
}

export function metaIncludes(metaFields: MetaField[], metaTypes: MetaType[]) {
    return metaFields.some(metaField => metaTypes.includes(metaField.metaType));
}

export const newMetaField = (metaType: MetaType, metaContent: MetaContent): MetaField => {
    return {
        key: crypto.randomUUID(),
        metaID: 0,
        metaType,
        metaContent,
    };
};

export const addOrMergeCustom = (metaField: MetaField | undefined, field: string): MetaField => {
    if (metaField) {
        (metaField.metaContent as TCustom) = {
            custom: {
                ...(metaField.metaContent as TCustom).custom,
                [field]: '',
            },
        };

        metaField.key = crypto.randomUUID();
    } else {
        metaField = newMetaField(MetaType.Custom, {
            custom: {
                [field]: '',
            },
        });
    }
    // console.log('newMeta', newMeta);

    return metaField;
};

interface Props {
    children: React.ReactNode;
}

export const MetaProvider: FC<Props> = ({ children }) => {
    const { request } = useApi();
    const { upload: firebaseUpload } = useContext(FirebaseContext) as TFirebase;

    const fetch = async (imageId: string, filterEmpty: boolean = true): Promise<MetaData> => {
        const response = await request({
            method: Method.GET,
            path: `/ImageMeta/${imageId}`,
        });

        const metaData = (response.data as Result<MetaData>).Results.Meta.filter(meta => {
            if (filterEmpty === false) {
                return true;
            }

            switch (meta.metaType) {
                case MetaType.ArtistName:
                    return !nullUndefinedOrEmpty((meta.metaContent as TArtistName).name);
                case MetaType.CardType:
                    return true;
                case MetaType.Custom:
                    return Object.keys((meta.metaContent as TCustom).custom).length > 0;
                case MetaType.Description:
                    return !nullUndefinedOrEmpty((meta.metaContent as TDescription).description);
                case MetaType.DisplayLocation:
                    return !nullUndefinedOrEmpty((meta.metaContent as TDisplayLocation).displayLocation);
                case MetaType.Email:
                    return !nullUndefinedOrEmpty((meta.metaContent as TEmail).email);
                case MetaType.GalleryName:
                    return !nullUndefinedOrEmpty((meta.metaContent as TGalleryName).galleryName);
                case MetaType.Link:
                    return (meta.metaContent as TLink).links.length > 0;
                case MetaType.Medium:
                    return !nullUndefinedOrEmpty((meta.metaContent as TMedium).medium);
                case MetaType.Phone:
                    return !nullUndefinedOrEmpty((meta.metaContent as TPhone).phone);
                case MetaType.Price:
                    return !nullUndefinedOrEmpty((meta.metaContent as TPrice).price);
                case MetaType.Provenance:
                    return !nullUndefinedOrEmpty((meta.metaContent as TProvenance).provenance);
                case MetaType.Size:
                    return !nullUndefinedOrEmpty((meta.metaContent as TSize).size);
                case MetaType.Tags:
                    return (meta.metaContent as TTags).tags.length > 0;
                case MetaType.Title:
                    return !nullUndefinedOrEmpty((meta.metaContent as TTitle).title);
                case MetaType.Year:
                    return !nullUndefinedOrEmpty((meta.metaContent as TYear).year);

                case MetaType.ArtCard:
                case MetaType.ContactInfo:
                case MetaType.ImageInfo:
                case MetaType.NftLink:
                case MetaType.SocialLinks:
                case MetaType.Unknown:
                case MetaType.Urls:
                case MetaType.UserProvidedInfo:
                    return false;

                default:
                    return true;
            }
        });
        // console.log("response", response);

        return {
            ...(response.data as Result<MetaData>).Results,
            Meta: metaData,
        };
    };

    const saveSemaphore = new Semaphore(3);
    const save = async (imageID: string, data: MetaField[], onProgress?: (status: string) => void): Promise<void> => {
        onProgress?.('Waiting to save metadata...');
        const [value, release] = await saveSemaphore.acquire();
        onProgress?.('Saving metadata...');

        const uploadProductLinkImages = async (data: MetaField[]): Promise<MetaField[]> => {
            // Upload each image only once
            // TODO: Why do i need to specify undefined when an invalid index would yield undefined!?
            const uploadedImages: Record<string, Promise<string> | undefined> = {};

            return await Promise.all(
                data.map(async metaField => {
                    try {
                        if (metaField.metaType === MetaType.ProductLink) {
                            metaField.metaContent.links = await Promise.all(
                                (metaField.metaContent as TProductLink).links.map(async productLink => {
                                    if (productLink.upload) {
                                        if (uploadedImages[productLink.upload.file.name] === undefined) {
                                            uploadedImages[productLink.upload.file.name] = new Promise(
                                                async (resolve, reject) => {
                                                    const shrunkenFile = await shrink(
                                                        productLink.upload!.file,
                                                        Quality.High,
                                                    );
                                                    const downloadUrl = await firebaseUpload(
                                                        shrunkenFile.file,
                                                        'products',
                                                        progress => {
                                                            console.log(`${productLink.upload!.file.name} ${progress}`);
                                                        },
                                                    );

                                                    resolve(downloadUrl);
                                                },
                                            );
                                        }

                                        productLink.imageUrl = await uploadedImages[productLink.upload.file.name]!;
                                        delete productLink.upload;
                                    }

                                    return productLink;
                                }),
                            );
                        }
                    } catch (error) {
                        console.warn(error);
                    }

                    return metaField;
                }),
            );
        };

        data = await uploadProductLinkImages(data);

        try {
            const metaArray: MetaField[] = data.map(m => {
                // JSON is valid JSON, the server should be able to handle this.
                const metaContent = JSON.stringify(m.metaContent);

                return {
                    key: m.key,
                    metaID: m.metaID ?? 0,
                    metaType: m.metaType,
                    metaContent,
                };
            });

            const response = await request({
                method: Method.POST,
                path: `/ImageMeta`,
                data: {
                    imageID,
                    metaArray,
                },
            });
            // console.log("response", response);
        } catch (error) {
            console.error(error);
            onProgress?.('Failed to save metadata...');
        }

        release();
    };

    const search = async (
        query: string,
        owner: boolean = true,
        page: number = 0,
        limit: number = 25,
        sortBy: ImageSortByField = 'internalID',
        sortOrder: ImageSortOrderField = 'DESC',
    ): Promise<PagedResults<Image[]>> => {
        const response = await request({
            method: Method.GET,
            path: `/Search/metaData/${query}?owner=${owner}&offset=${page * limit}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
        });

        return {
            NextOffset: response.data.NextOffset,
            Count: response.data.Count,
            Pages: response.data.Pages,
            Results: response.data.Results.Images,
        };
    };

    return (
        <MetaContext.Provider
            value={{
                fetch,
                save,
                search,
            }}
        >
            {children}
        </MetaContext.Provider>
    );
};

const MetaContext = createContext<TMeta | undefined>(undefined);

export default MetaContext;
