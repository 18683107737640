import { useContext } from 'react';
import { InputLabel, Grid, Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { MetaDataLabel, MetaDataRow, MetaDataValue } from '../../theme/units';
import { TCardType, CardType as CardTypeEnum } from '../../types/MetaTypes';
import { Select } from '../general/Select';
import { nullUndefinedOrEmpty } from '../../util/string';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';

interface Props extends MetaFieldProps {
    showEditing?: boolean;
    onEdit?: () => void;
}

const CardType = ({ metaField, showEditing, onEdit }: Props) => {
    const content = metaField?.metaContent as TCardType | undefined;

    if (!content || nullUndefinedOrEmpty(content?.cardType)) {
        return null;
    }

    return (
        <>
            <Grid item container direction="row" {...MetaDataRow}>
                <Grid item {...MetaDataLabel}>
                    <Typography
                        variant="metaDataLabel"
                        sx={{
                            p: 2,
                        }}
                    >
                        Title
                    </Typography>
                </Grid>
                <Grid item {...MetaDataValue}>
                    <Typography
                        variant="metaDataValue"
                        sx={{
                            p: 2,
                        }}
                    >
                        {content.cardType}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

const CardTypeForm = ({ metaField, onChange }: MetaFieldFormProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TCardType | undefined;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack
            direction="column"
            spacing={0.25}
            sx={{
                px: 2,
            }}
        >
            <InputLabel
                sx={{
                    fontFamily: 'Nunito Sans',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    // TODO: Color.ts
                    color: darkMode ? '#E6E6E6' : '#12121A99',
                }}
            >
                Media type
            </InputLabel>
            <Stack direction="row" spacing={1}>
                <Select
                    options={[
                        { value: CardTypeEnum.General, label: 'General' },
                        { value: CardTypeEnum.ArtCard, label: 'Art Card' },
                    ]}
                    value={content?.cardType ?? CardTypeEnum.General}
                    onChange={async value => {
                        handleChange({ name: 'cardType', value: value as string });
                    }}
                    sx={{
                        flexGrow: 1,
                    }}
                />
            </Stack>
        </Stack>
    );
};

export { CardType, CardTypeForm };
